import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { parseMonth } from '@workaholic/config/holidays';
import classNames from 'classnames';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { formatTimeDuration } from '../../../logentries/utils';

const useStyles = makeStyles((theme) => ({
    positive: {
        color: theme.palette.graph.positive,
    },

    negative: {
        color: theme.palette.graph.negative,
    },

    neutral: {},
}));

function OvertimeTooltipInfo({ point }) {
    const classes = useStyles();

    return (
        <>
            <Typography
                className={classNames({
                    [classes.negative]: point.y < 0,
                    [classes.positive]: point.y > 0,
                    [classes.neutral]: point.y === 0,
                })}
            >
                {point.y > 0 && '+'}
                {formatTimeDuration(point.y, true)}
            </Typography>
            <Typography color="textSecondary">{format(parseMonth(point.x), 'LLL')}</Typography>
        </>
    );
}

OvertimeTooltipInfo.propTypes = {
    point: PropTypes.shape({
        x: PropTypes.string.isRequired,
        y: PropTypes.number.isRequired,
    }).isRequired,
};

export default OvertimeTooltipInfo;
