import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import BarChartIcon from '@material-ui/icons/BarChart';
import EventNoteIcon from '@material-ui/icons/EventNote';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Header from '../components/layout/Header';
import Metadata from '../components/layout/Metadata';
import Playground from '../components/Playground';
import { useWhatsNew } from '../features/changelog/hooks';
import { useCompliance } from '../features/compliance/hooks';
import Logs from '../features/logs/components/Logs';
import { useOnboarding } from '../features/onboarding/hooks';
import Settings from '../features/settings/components/Settings';
import Statistics from '../features/statistics/components/Statistics';
import CrossFade from '../features/transitions/CrossFade';
import Users from '../features/users/components/Users';
import Years from '../features/years/components/Years';

import {
    logsPath,
    playgroundPath,
    settingsPath,
    statisticsPath,
    usersPath,
    yearsPath,
} from '../routes/paths';

const useStyles = makeStyles({
    content: {
        overflow: 'hidden',
    },

    nav: {
        width: '100%',

        '@media print': {
            display: 'none',
        },
    },
});

function AppPage() {
    const classes = useStyles();
    const history = useHistory();
    const { pathname } = useLocation();
    const { t } = useTranslation();

    useCompliance();
    useOnboarding();
    useWhatsNew();

    return (
        <>
            <Metadata title={t(`nav.${pathname.replace('/', '') || 'home'}`)} />

            <Grid container direction="column" className="fullHeight">
                <Grid item>
                    <Header />
                </Grid>

                <Grid item xs className={classes.content}>
                    <CrossFade
                        index={pathname}
                        className="fullHeight"
                        containerClassName="fullHeight"
                    >
                        {pathname === logsPath && <Logs />}
                        {pathname === yearsPath && <Years />}
                        {pathname === statisticsPath && <Statistics />}
                        {pathname === settingsPath && <Settings />}
                        {pathname === usersPath && <Users />}
                        {pathname === playgroundPath && <Playground />}
                    </CrossFade>
                </Grid>

                <Grid item className={classes.nav}>
                    <BottomNavigation
                        value={pathname}
                        onChange={(event, tab) => history.push(tab)}
                        showLabels
                    >
                        <BottomNavigationAction
                            label={t('nav.logs')}
                            icon={<ScheduleIcon />}
                            value={logsPath}
                        />
                        <BottomNavigationAction
                            label={t('nav.years')}
                            icon={<EventNoteIcon />}
                            value={yearsPath}
                        />
                        <BottomNavigationAction
                            label={t('nav.statistics')}
                            icon={<BarChartIcon />}
                            value={statisticsPath}
                        />
                    </BottomNavigation>
                </Grid>
            </Grid>
        </>
    );
}

AppPage.propTypes = {};

export default AppPage;
