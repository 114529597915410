import { onlyDurationNeeded } from '@workaholic/config/tags';
import * as Yup from 'yup';

export const entrySchema = Yup.object().shape({
    date: Yup.date().nullable().required('Required'),
    from: Yup.date()
        .nullable()
        .when('tags', (tags, schema) =>
            !onlyDurationNeeded(tags) ? schema.required('Required') : schema
        ),
    till: Yup.date()
        .nullable()
        .when('from', (from, schema) => (from ? schema.min(from, 'MinTime') : schema)),
    duration: Yup.number()
        .nullable()
        .when('tags', (tags, schema) =>
            onlyDurationNeeded(tags) ? schema.required('Required') : schema
        ),
    tags: Yup.array().of(Yup.string()).ensure(),
    premium: Yup.number().min(0).required('Required'),
});
