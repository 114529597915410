import i18n from 'i18next';

// import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
import basicDE from './assets/locales/de/basic.json';

// the translations
const resources = {
    de: {
        basic: basicDE,
    },
};

i18n
    // TODO: use backend + suspense
    // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-xhr-backend
    // .use(Backend)

    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources, // we init with resources for now

        fallbackLng: 'de',
        debug: process.env.NODE_ENV !== 'production',

        // have a common namespace used around the full app
        ns: ['basic'],
        defaultNS: 'basic',

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });

export default i18n;
