import Grid from '@material-ui/core/Grid';
import Portal from '@material-ui/core/Portal';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../../form/components/Form';
import SubmitButton from '../../form/components/SubmitButton';
import Switch from '../../form/components/Switch';
import TextInput from '../../form/components/TextInput';
import { IdPropType } from '../../proptypes';
import { patchUser } from '../actions';
import { userSchema } from '../schema';
import { selectUserById } from '../selectors';

function UserForm({ userId, onDone, submitContainer }) {
    const { t } = useTranslation();
    const user = useSelector((state) => selectUserById(state, userId));
    const dispatch = useDispatch();

    return (
        <Form
            initialValues={user}
            validationSchema={userSchema}
            onSubmit={(values) => dispatch(patchUser(user._id, values)).then(onDone)}
        >
            <Grid spacing={1} container>
                <Grid item xs={12}>
                    <TextInput label={t('form.User.email')} name="email" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Switch label={t('form.User.active')} name="active" />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Switch label={t('form.User.vip')} name="vip" />
                </Grid>
            </Grid>

            <Portal container={submitContainer}>
                <SubmitButton outsideForm />
            </Portal>
        </Form>
    );
}

UserForm.propTypes = {
    userId: IdPropType,
    onDone: PropTypes.func.isRequired,
    submitContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
};

UserForm.defaultProps = {
    userId: null,
    submitContainer: null,
};

export default UserForm;
