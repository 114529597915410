import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDialog } from '../dialogs/components/DialogControl';
import { useQuery } from '../router/hooks';
import { selectSettings } from '../settings/selectors';

export const useOnboarding = () => {
    const settings = useSelector(selectSettings);
    const [opened, setOpened] = useState(false);
    const { openOnboardingDialog } = useDialog();
    const query = useQuery();

    const forceShow = query && query.get('onboarding');

    useEffect(() => {
        if (!opened && settings && (settings.showOnboarding || forceShow)) {
            openOnboardingDialog(null, true);
            setOpened(true);
        }
    }, [settings, openOnboardingDialog, forceShow, opened, setOpened]);
};
