import { Box } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        paddingTop: theme.spacing(2),

        '@media print': {
            paddingTop: 0,
        },
    },

    header: {
        position: 'relative',
        top: 0,
        transition: theme.transitions.create(['top']),
    },

    headerSlim: {
        top: -20,
    },

    summary: {
        position: 'relative',
        top: 0,
        transition: theme.transitions.create(['top']),
    },

    summaryVisible: {
        top: -20,
    },

    arrow: {
        fontSize: 50,
        marginLeft: -theme.spacing(2),
        marginRight: -theme.spacing(2),
    },

    secondary: {
        position: 'relative',
        top: 0,
        transition: theme.transitions.create(['font-size', 'top']),
    },

    secondaryProminent: {
        top: -19,
        fontSize: '1.6em',
    },

    hideOnPrint: {
        '@media print': {
            display: 'none',
        },
    },
}));

function SummaryHeader({ primary, secondary, children, onNext, onPrevious, expanded, onExpand }) {
    const classes = useStyles();

    return (
        <Container maxWidth="md" className={classes.root}>
            <Grid
                container
                alignItems="center"
                className={classNames({
                    [classes.header]: true,
                    [classes.headerSlim]: expanded,
                })}
            >
                <Grid item className={classes.hideOnPrint}>
                    <IconButton onClick={onPrevious} data-cy="SummaryHeader.previous">
                        <ArrowLeftIcon className={classes.arrow} />
                    </IconButton>
                </Grid>
                <Grid xs item onClick={() => onExpand(!expanded)} data-cy="SummaryHeader.expand">
                    <Fade in={!expanded}>
                        <Typography component="div" variant="h4">
                            {primary}
                        </Typography>
                    </Fade>
                    <Typography
                        color="textSecondary"
                        className={classNames({
                            [classes.secondary]: true,
                            [classes.secondaryProminent]: expanded,
                        })}
                    >
                        {secondary}
                    </Typography>
                </Grid>
                <Grid item className={classes.hideOnPrint}>
                    <IconButton onClick={onNext} data-cy="SummaryHeader.next">
                        <ArrowRightIcon className={classes.arrow} />
                    </IconButton>
                </Grid>
            </Grid>

            <Box
                className={classNames({
                    [classes.summary]: true,
                    [classes.summaryVisible]: expanded,
                })}
            >
                <Collapse in={expanded}>{children}</Collapse>
            </Box>
        </Container>
    );
}

SummaryHeader.propTypes = {
    primary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    onNext: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
    expanded: PropTypes.bool.isRequired,
    onExpand: PropTypes.func,
    children: PropTypes.node,
};

SummaryHeader.defaultProps = {
    onExpand: () => null,
    children: null,
};

export default SummaryHeader;
