import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { useField } from 'formik';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFieldError } from '../hooks';

const useStyles = makeStyles({
    input: {
        margin: 0,
        width: 156,
    },

    fullWidth: {
        width: '100%',
    },

    icon: {
        paddingRight: 0,
    },
});

function DateInput({ name, label, onChange, fullWidth, ...other }) {
    const classes = useStyles();
    const [field, meta, helpers] = useField(name);
    const { isError, translatedError } = useFieldError(meta);
    const { t } = useTranslation();
    const theme = useTheme();
    const variant = useMediaQuery(theme.breakpoints.down('sm')) ? 'dialog' : 'inline';

    return (
        <DatePicker
            {...field}
            name={name}
            onChange={(date) => {
                // TODO: figure out the best way to handle time zone stuff
                //       just use the time zoned date for now
                helpers.setValue(date);
                if (onChange) {
                    onChange(date);
                }
            }}
            onError={(err) => {
                if (err !== meta.error && err) {
                    helpers.setError(err);
                }
            }}
            format={t('date.DateFormat')}
            margin="dense"
            label={label}
            invalidDateMessage={t('errors.InvalidDate')}
            minDateMessage={t('errors.MinDate')}
            maxDateMessage={t('errors.MaxDate')}
            className={`${classes.input} ${fullWidth ? classes.fullWidth : ''}`}
            error={isError}
            helperText={isError && translatedError}
            InputProps={{
                className: classes.icon,
            }}
            variant={variant}
            {...other}
        />
    );
}

DateInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    fullWidth: PropTypes.bool,
};

DateInput.defaultProps = {
    label: null,
    onChange: null,
    fullWidth: false,
};

export default DateInput;
