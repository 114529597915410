import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDialog } from '../../dialogs/components/DialogControl';
import { selectUser } from '../selectors';
import LogoutButton from './LogoutButton';

function AuthForm() {
    const { t } = useTranslation();
    const { openChangeEmailDialog, openChangePasswordDialog } = useDialog();
    const user = useSelector(selectUser);

    return (
        <>
            <Box mb={1}>
                <Typography variant="caption" color="textSecondary">
                    {t('auth.loggedInAs')}
                </Typography>
                <Typography>{user.email}</Typography>
            </Box>

            <Grid container spacing={1} justifyContent="space-between">
                <Grid item>
                    <Button
                        startIcon={<EmailIcon />}
                        onClick={() => openChangeEmailDialog({ userId: user._id })}
                        size="small"
                        color="primary"
                        data-cy="change-email"
                    >
                        {t('auth.email.change')}
                    </Button>
                </Grid>
                <Grid item>
                    <Box>
                        <LogoutButton />
                    </Box>
                </Grid>
            </Grid>

            <Button
                startIcon={<VpnKeyIcon />}
                onClick={() => openChangePasswordDialog({ userId: user._id })}
                size="small"
                color="primary"
            >
                {t('auth.password.change')}
            </Button>
        </>
    );
}

AuthForm.propTypes = {};

export default AuthForm;
