/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
    createSuccess as createLogsSuccess,
    findSuccess as findLogsSuccess,
    patchSuccess as patchLogsSuccess,
} from '../logs/logsSlice';

const updateEntries = (state, entries) => {
    entries.forEach((entry) => {
        if (!state.byId[entry._id]) {
            state.allIds.push(entry._id);
        }
        state.byId[entry._id] = entry;
        state.idsLoading[entry._id] = false;
    });
};

const logEntriesSlice = createSlice({
    name: 'logentries',
    initialState: {
        byId: {},
        allIds: [],
        isLoading: false,
        idsLoading: {},
    },
    reducers: {},
    extraReducers: {
        [findLogsSuccess]: (state, action) => {
            action.payload.data.forEach((log) => {
                updateEntries(state, log.entries);
            });
        },
        [createLogsSuccess]: (state, action) => {
            updateEntries(state, action.payload.entries);
        },
        [patchLogsSuccess]: (state, action) => {
            updateEntries(state, action.payload.entries);
        },
    },
});

export default logEntriesSlice.reducer;
