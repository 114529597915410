import Divider from '@material-ui/core/Divider';
import { Fragment } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../dialogs/components/DialogControl';
import { LOG_ENTRY_DIALOG } from '../../dialogs/dialogs';

import { IdListPropType } from '../../proptypes';
import LogEntry from './LogEntry';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(6),
    },

    empty: {
        textAlign: 'center',
    },
}));

function LogEntries({ ids }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openDialog } = useDialog();

    const handleClick = (event, id) => openDialog(LOG_ENTRY_DIALOG, { id });

    return (
        <List className={classes.root} data-cy="LogEntries">
            {ids.map((id) => (
                <Fragment key={id}>
                    <LogEntry id={id} onClick={handleClick} />
                    <Divider light />
                </Fragment>
            ))}
            {ids.length === 0 && (
                <ListItem button onClick={handleClick}>
                    <ListItemText
                        primary={
                            <Typography
                                variant="body2"
                                color="textSecondary"
                                className={classes.empty}
                            >
                                {t('Log.empty')}
                            </Typography>
                        }
                    />
                </ListItem>
            )}
        </List>
    );
}

LogEntries.propTypes = {
    ids: IdListPropType.isRequired,
};

export default LogEntries;
