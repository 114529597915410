import Box from '@material-ui/core/Box';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ResponsiveRadar } from '@nivo/radar';

import { getWorked } from '@workaholic/config/logs';
import { eachDayOfInterval, endOfWeek, format, parseISO, startOfWeek } from 'date-fns';
import * as PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectLogEntriesByIds } from '../../../logentries/selectors';
import { formatTimeDuration } from '../../../logentries/utils';
import { LogPropType } from '../../../logs/proptypes';

const useStyles = makeStyles({
    root: {
        height: 200,
    },
});

function Workdays({ logs }) {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const entries = useSelector(selectLogEntriesByIds);

    const [data, setData] = useState([]);

    const description = t('statistics.workdays.average');

    useEffect(() => {
        const now = new Date();
        const raw = logs.reduce(
            (outerCarry, log) =>
                log.entries.reduce((carry, entryId) => {
                    const entry = entries[entryId];
                    const day = format(parseISO(entry.from), 'EEEEEE');
                    // eslint-disable-next-line no-param-reassign
                    carry[day].total += getWorked(entry);
                    // eslint-disable-next-line no-param-reassign
                    carry[day].count += 1;
                    return carry;
                }, outerCarry),
            eachDayOfInterval({ start: startOfWeek(now), end: endOfWeek(now) }).reduce(
                (carry, day) => {
                    // eslint-disable-next-line no-param-reassign
                    carry[format(day, 'EEEEEE')] = { total: 0, count: 0 };
                    return carry;
                },
                {}
            )
        );

        setData(
            Object.keys(raw).reduce((carry, day) => {
                carry.push({ weekday: day, [description]: 0 });
                return carry;
            }, [])
        );

        setTimeout(() => {
            setData(
                Object.entries(raw).reduce((carry, [day, worked]) => {
                    carry.push({ weekday: day, [description]: worked.total / worked.count || 0 });
                    return carry;
                }, [])
            );
        }, 100);
    }, [logs, entries, setData, description]);

    return (
        <Box className={classes.root} data-cy="Workdays">
            <ResponsiveRadar
                margin={{
                    top: theme.spacing(3),
                    bottom: theme.spacing(3),
                    left: theme.spacing(3),
                    right: theme.spacing(3),
                }}
                keys={[description]}
                data={data}
                indexBy="weekday"
                enableDots={false}
                colors={[theme.palette.graph.positive]}
                curve="cardinalClosed"
                tooltipFormat={(value) => formatTimeDuration(value, true)}
            />
        </Box>
    );
}

Workdays.propTypes = {
    logs: PropTypes.arrayOf(LogPropType).isRequired,
};

export default Workdays;
