import { useTranslation } from 'react-i18next';
import { useDialog } from '../../dialogs/components/DialogControl';
import { LOG_ENTRY_DIALOG } from '../../dialogs/dialogs';
import Button from '../../form/components/Button';
import { IdPropType } from '../../proptypes';

function StopTrackingButton({ entryId }) {
    const { t } = useTranslation();

    const { openDialog } = useDialog();
    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        openDialog(LOG_ENTRY_DIALOG, { id: entryId, tillNow: true });
    };

    return <Button label={t('dialogs.LogEntry.tracking.stop')} onClick={handleClick} />;
}

StopTrackingButton.propTypes = {
    entryId: IdPropType.isRequired,
};

StopTrackingButton.defaultProps = {};

export default StopTrackingButton;
