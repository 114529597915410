import { createTheme } from '@material-ui/core';
import secondary from '@material-ui/core/colors/blueGrey';
import primary from '@material-ui/core/colors/pink';
import { EDUCATION, HOLIDAY, PREMIUM, SICK, VACATION, WEEKEND } from '@workaholic/config/tags';

const theme = createTheme({
    palette: {
        // type: 'dark',
        primary,
        secondary,
        graph: {
            neutral: '#9e9e9e',
            positive: 'rgb(97, 205, 187)',
            negative: 'rgb(244, 117, 96)',
        },
        tags: {
            [WEEKEND]: {
                light: '#4db6ac',
                main: '#26a69a',
                dark: '#009688',
            },
            [VACATION]: {
                light: '#9575cd',
                main: '#7e57c2',
                dark: '#673ab7',
            },
            [HOLIDAY]: {
                light: '#ffb74d',
                main: '#ff9800',
                dark: '#fb8c00',
            },
            [SICK]: {
                light: '#f06292',
                main: '#ec407a',
                dark: '#e91e63',
            },
            [EDUCATION]: {
                light: '#42a5f5',
                main: '#2196f3',
                dark: '#1e88e5',
            },
            [PREMIUM]: {
                light: '#6e6e6e',
                main: '#6e6e6e',
                dark: '#6e6e6e',
            },
            default: {
                light: '#9e9e9e',
                main: '#9e9e9e',
                dark: '#9e9e9e',
            }, // must be last
        },
    },

    typography: {
        h2: {
            fontSize: '1.8rem',
        },

        h3: {
            fontSize: '1.6rem',
        },

        h5: {
            fontSize: '1.4em',
        },
    },

    overrides: {
        MuiSelect: {
            select: {
                minWidth: 60,
            },
        },
        MuiCardActions: {
            root: {
                paddingLeft: 16,
            },
        },
        MuiExpansionPanelActions: {
            root: {
                paddingLeft: 16,
            },
        },
    },
});

export default theme;
