import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';

import Metadata from '../components/layout/Metadata';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));

function PermissionDeniedPage() {
    const classes = useStyles();

    return (
        <>
            <Metadata title="404" />

            <Header />

            <Container maxWidth="md" className={classes.root} data-cy="PermissionDeniedPage">
                <BlockIcon fontSize="large" />
                <h3>Permission denied</h3>
            </Container>

            <Footer />
        </>
    );
}

export default PermissionDeniedPage;
