export const selectYearsByYear = (state) => state.years.byYear;

export const selectAllYearYears = (state) => state.years.allYears;

export const selectYearByYear = (state, year) => selectYearsByYear(state)[year];

export const selectIsLoading = (state) => state.years.isLoading;

export const selectYearsLoading = (state) => state.years.yearsLoading;

export const selectYearLoading = (state, year) =>
    selectIsLoading(state) || selectYearsLoading(state)[year];

export const selectYearInitialized = (state, year) => !!selectYearByYear(state, year);
