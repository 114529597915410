import Button from '@material-ui/core/Button';
import MobileStepper from '@material-ui/core/MobileStepper';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles({
    stepper: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
    },

    controls: {
        width: 90,
    },
});

function OnboardingFormControl({ maxSteps, step, onStep, onDone, saveAfter }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { errors, isSubmitting, submitForm } = useFormikContext();

    const handleNext = () => {
        if (step === saveAfter) {
            submitForm().then(() => {
                onStep(step + 1);
            });
        } else if (step === maxSteps - 1) {
            onDone();
        } else {
            onStep(step + 1);
        }
    };

    const handleBack = () => {
        onStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <MobileStepper
            className={classes.stepper}
            variant="dots"
            position="static"
            steps={maxSteps}
            activeStep={step}
            nextButton={
                <Button
                    size="small"
                    onClick={handleNext}
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    className={classes.controls}
                    data-cy="OnboardingFormControl.next"
                >
                    {step === maxSteps - 1
                        ? t('dialogs.Onboarding.submit')
                        : t('dialogs.Onboarding.next')}
                    <KeyboardArrowRight />
                </Button>
            }
            backButton={
                <Button
                    size="small"
                    onClick={handleBack}
                    disabled={isSubmitting || step === 0}
                    className={classes.controls}
                    data-cy="OnboardingFormControl.back"
                >
                    <KeyboardArrowLeft />
                    {t('dialogs.Onboarding.back')}
                </Button>
            }
        />
    );
}

OnboardingFormControl.propTypes = {
    step: PropTypes.number.isRequired,
    maxSteps: PropTypes.number.isRequired,
    onStep: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    saveAfter: PropTypes.number.isRequired,
};

export default OnboardingFormControl;
