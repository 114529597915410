import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import OnboardingStep from './OnboardingStep';

const useStyles = makeStyles({
    copy: {
        maxWidth: 300,
        textAlign: 'center',
    },
});

function FinishStep() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <OnboardingStep
            primary={
                <Typography variant="h4" component="span">
                    {t('dialogs.Onboarding.steps.Finish.title')}
                </Typography>
            }
            data-cy="FinishStep"
        >
            <Typography variant="body2" color="textSecondary" className={classes.copy}>
                {t('dialogs.Onboarding.steps.Finish.copy')}
            </Typography>
        </OnboardingStep>
    );
}

FinishStep.propTypes = {};

export default FinishStep;
