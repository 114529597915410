/* eslint-disable react/jsx-props-no-spreading */
import { FormControlLabel, makeStyles } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiSwitch from '@material-ui/core/Switch';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import { useFieldError } from '../hooks';

const useStyles = makeStyles((theme) => ({
    label: {
        ...theme.typography.body1,
        lineHeight: '1em',
        transform: 'translate(0, 1.5px) scale(0.75)',
        transformOrigin: 'top left',
    },
    helperText: {
        marginTop: 2,
    },
}));

function Switch({ name, label, onChange, ...other }) {
    const classes = useStyles();
    const [field, meta, helpers] = useField(name);
    const { isError, translatedError } = useFieldError(meta);

    return (
        <>
            <FormControlLabel
                label={label}
                control={
                    <MuiSwitch
                        {...field}
                        name={name}
                        value={name}
                        onChange={() => {
                            const newValue = !field.value;
                            helpers.setValue(newValue);

                            if (onChange) {
                                onChange(name, newValue);
                            }
                        }}
                        checked={!!field.value}
                        color="primary"
                        {...other}
                    />
                }
            />
            {isError && (
                <FormHelperText className={classes.helperText} error>
                    {translatedError}
                </FormHelperText>
            )}
        </>
    );
}

Switch.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
};

Switch.defaultProps = {
    label: null,
    onChange: null,
};

export default Switch;
