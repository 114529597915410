/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const LoadingSlice = createSlice({
    name: 'loading',
    initialState: {
        app: { isLoading: false, error: null },
    },
    reducers: {
        loading: {
            prepare: (isLoading, key) => ({
                meta: { key },
                payload: isLoading,
            }),
            reducer: (state, action) => {
                state[action.meta.key] = {
                    isLoading: action.payload,
                    error: null,
                };
            },
        },
        loadingError: {
            prepare: (error, key) => ({
                meta: { key },
                payload: error,
                error: true,
            }),
            reducer: (state, action) => {
                state[action.meta.key] = {
                    isLoading: false,
                    error: action.payload,
                };
            },
        },
    },
});

export const { loading, loadingError } = LoadingSlice.actions;

export default LoadingSlice.reducer;
