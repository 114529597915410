export const PASSWORDS_SERVICE_ID = 'passwords';

export const MAILS_SERVICE_ID = 'mails';

export const USERS_SERVICE_ID = 'users';

export const SETTINGS_SERVICE_ID = 'settings';

export const LOGS_SERVICE_ID = 'logs';

export const LOG_ENTRIES_SERVICE_ID = 'logentries';

export const YEARS_SERVICE_ID = 'years';

export const HOLIDAYS_SERVICE_ID = 'holidays';
export const HOLIDAYS_IMPORTER_SERVICE_ID = 'holidaysimporter';
