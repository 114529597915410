import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import OnboardingStep from './OnboardingStep';

function WelcomeStep() {
    const { t } = useTranslation();

    return (
        <OnboardingStep
            primary={
                <Typography variant="h4" component="span">
                    {t('dialogs.Onboarding.steps.Welcome.title')}
                </Typography>
            }
            data-cy="WelcomeStep"
        >
            <Typography>{t('dialogs.Onboarding.steps.Welcome.copy')}</Typography>
        </OnboardingStep>
    );
}

WelcomeStep.propTypes = {};

export default WelcomeStep;
