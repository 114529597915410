import MuiDialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    close: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
    },
}));

function Dialog({ onClose, children, disableClose, ...props }) {
    const classes = useStyles();

    return (
        <MuiDialog
            onClose={onClose}
            disableBackdropClick={disableClose}
            disableEscapeKeyDown={disableClose}
            {...props}
        >
            {!disableClose && (
                <IconButton onClick={onClose} className={classes.close}>
                    <CloseIcon />
                </IconButton>
            )}
            {children}
        </MuiDialog>
    );
}

Dialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    disableClose: PropTypes.bool,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
};

Dialog.defaultProps = {
    disableClose: false,
    fullWidth: false,
    maxWidth: 'sm',
};

export default Dialog;
