import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        marginTop: -4,
    },

    header: {
        position: 'relative',
    },

    summary: {
        position: 'relative',
        top: 0,
        transition: theme.transitions.create(['top']),
    },

    summaryVisible: {
        top: -20,
    },

    arrow: {
        fontSize: 50,
        marginLeft: -theme.spacing(2),
        marginRight: -theme.spacing(2),
    },

    secondary: {
        fontSize: '1.6em',
    },
}));

function SummaryStaticHeader({ title, onNext, onPrevious }) {
    const classes = useStyles();

    return (
        <Container maxWidth="md" className={classes.root}>
            <Grid container alignItems="center" className={classes.header}>
                <Grid item>
                    <IconButton onClick={onPrevious}>
                        <ArrowLeftIcon className={classes.arrow} />
                    </IconButton>
                </Grid>
                <Grid xs item>
                    <Typography color="textSecondary" className={classes.secondary}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <IconButton onClick={onNext}>
                        <ArrowRightIcon className={classes.arrow} />
                    </IconButton>
                </Grid>
            </Grid>
        </Container>
    );
}

SummaryStaticHeader.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    onNext: PropTypes.func.isRequired,
    onPrevious: PropTypes.func.isRequired,
};

export default SummaryStaticHeader;
