import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

import { formatTimeDuration } from '../../features/logentries/utils';

const useStyles = makeStyles((theme) => ({
    positive: {
        color: theme.palette.success.main,
    },

    negative: {
        color: theme.palette.error.main,
    },

    extra: {
        paddingLeft: 2,
        fontSize: '0.85rem',
    },

    dense: {
        fontSize: '0.85rem',
    },

    print: {
        '@media print': {
            fontSize: '0.85rem',
            lineHeight: '1rem',
        },
    },
}));

function SummaryItem({ label, value, showEmpty, duration, positive, negative, extra, dense }) {
    const classes = useStyles();
    const formatted = duration ? formatTimeDuration(value, true) : value;

    return (
        <>
            <Typography variant="caption" color="textSecondary">
                {label}
            </Typography>
            <Typography
                color={value || showEmpty ? 'textPrimary' : 'textSecondary'}
                className={classNames(classes.print, {
                    [classes.positive]: value && positive,
                    [classes.negative]: value && negative,
                    [classes.dense]: dense && formatted && `${formatted}`.length > 5,
                })}
            >
                {!!(positive && value) && '+'}
                {value || showEmpty ? formatted : '-'}
                {extra && (
                    <Typography
                        component="span"
                        className={classNames({
                            [classes.extra]: true,
                            [classes.positive]: extra > 0,
                            [classes.negative]: value < 0,
                        })}
                    >
                        {extra > 0 && '+'}
                        {extra}
                    </Typography>
                )}
            </Typography>
        </>
    );
}

SummaryItem.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showEmpty: PropTypes.bool,
    duration: PropTypes.bool,
    positive: PropTypes.bool,
    negative: PropTypes.bool,
    dense: PropTypes.bool,
    extra: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SummaryItem.defaultProps = {
    value: null,
    showEmpty: false,
    duration: false,
    positive: false,
    negative: false,
    dense: false,
    extra: null,
};

export default SummaryItem;
