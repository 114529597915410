import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import { onlyDurationNeeded } from '@workaholic/config/tags';
import classNames from 'classnames';
import { parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DateIcon from '../../../components/icons/DateIcon';
import TimeIcon from '../../../components/icons/TimeIcon';
import { IdPropType } from '../../proptypes';
import { selectLogEntryById } from '../selectors';
import { formatTimeDifference, formatTimeDuration } from '../utils';
import StopTrackingButton from './StopTrackingButton';
import Timer from './Timer';

const useStyles = makeStyles({
    total: {
        textAlign: 'right',
    },

    tracking: {
        opacity: 0.35,
    },

    root: {
        '@media print': {
            paddingTop: 0,
            paddingBottom: 0,
        },
    },

    text: {
        '@media print': {
            marginTop: 0,
            marginBottom: 0,
        },
    },
});

function LogEntry({ id, onClick }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const entry = useSelector((state) => selectLogEntryById(state, id));
    const fromDate = parseISO(entry.from);
    const tillDate = entry.till ? parseISO(entry.till) : null;

    return (
        <ListItem
            button
            onClick={(event) => onClick(event, entry._id)}
            id={`log-entry-${entry._id}`}
            className={classes.root}
        >
            <ListItemIcon>
                <DateIcon date={fromDate} tags={entry.tags} />
            </ListItemIcon>
            <ListItemText
                className={classes.text}
                primary={
                    !onlyDurationNeeded(entry.tags) && (
                        <>
                            <TimeIcon time={fromDate} /> -{' '}
                            {tillDate ? (
                                <TimeIcon time={tillDate} />
                            ) : (
                                <StopTrackingButton entryId={entry._id} />
                            )}
                        </>
                    )
                }
            />
            <Box className={classNames(classes.total, { [classes.tracking]: !tillDate })}>
                <Typography>
                    {tillDate ? (
                        formatTimeDifference(fromDate, tillDate, entry.pause)
                    ) : (
                        <Timer fromDate={fromDate} pause={entry.pause} />
                    )}
                </Typography>
                {entry.pause > 0 && (
                    <Tooltip title={t('Log.pause')}>
                        <Typography component="span" variant="body2" color="textSecondary">
                            {formatTimeDuration(entry.pause, true)}
                        </Typography>
                    </Tooltip>
                )}
            </Box>
        </ListItem>
    );
}

LogEntry.propTypes = {
    id: IdPropType.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default LogEntry;
