import { useMediaQuery, useTheme } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '../../dialogs/components/Dialog';

import { useCallbackRef } from '../../hooks';
import YearForm from './YearForm';

function YearDialog({ open, onClose, onExited, year }) {
    const { t } = useTranslation();
    const [current, ref] = useCallbackRef();
    const theme = useTheme();
    const fullWidth = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Dialog open={open} onClose={onClose} onExited={onExited} fullWidth={fullWidth}>
            <DialogTitle>{t('dialogs.Year.title')}</DialogTitle>
            <DialogContent>
                {year && <YearForm submitContainer={current} year={year} onDone={onClose} />}
            </DialogContent>
            <DialogActions>
                <div ref={ref} />
            </DialogActions>
        </Dialog>
    );
}

YearDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
    year: PropTypes.string,
};

YearDialog.defaultProps = {
    year: null,
};

export default YearDialog;
