import { errorAction, pendingAction, successAction } from './actions';
import serviceConfig from './endpoints';
import { parseError } from './utils';

export const callService =
    (services, endpoint, action) =>
    (...params) => {
        services.dispatch(pendingAction(endpoint, action, params));

        const apiService = services.api.service(endpoint);
        return apiService[action](...params)
            .then((response) => {
                if (action === 'find' || action === 'get') {
                    return services.dispatch(successAction(endpoint, action, params, response));
                }
                return response;
            })
            .catch((error) => {
                throw parseError(error);
            })
            .catch((error) => {
                services.dispatch(errorAction(endpoint, action, params, error));
                throw error;
            });
    };

const createService = (services, endpoint) =>
    ['find', 'get', 'create', 'update', 'patch', 'remove'].reduce((service, action) => {
        // eslint-disable-next-line no-param-reassign
        service[action] = callService(services, endpoint, action);
        return service;
    }, {});

const setupListeners = (services, endpoint, listeners) => {
    [
        ['created', 'create'],
        ['updated', 'update'],
        ['patched', 'patch'],
        ['removed', 'remove'],
    ].forEach(([event, action]) =>
        services.api.service(endpoint).on(event, (entity) => {
            services.dispatch(successAction(endpoint, action, { event: true }, entity));
            if (listeners && listeners[event]) {
                listeners[event](services, entity);
            }
        })
    );
};

export const attachServices = (services) =>
    serviceConfig.reduce((_services, { name, endpoint, listeners }) => {
        // eslint-disable-next-line no-param-reassign
        _services[name] = createService(_services, endpoint);
        setupListeners(_services, endpoint, listeners);
        return _services;
    }, services);
