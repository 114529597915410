import Portal from '@material-ui/core/Portal';

import { onlyDurationNeeded } from '@workaholic/config/tags';
import { differenceInMinutes, formatISO, parseISO } from 'date-fns';
import * as PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../../form/components/Form';
import SubmitButton from '../../form/components/SubmitButton';
import { IdPropType } from '../../proptypes';
import { selectSettings } from '../../settings/selectors';
import { createLogEntry, patchLogEntry } from '../actions';
import { entrySchema } from '../schema';
import { selectLogEntryById } from '../selectors';
import { joinDateWithDuration, joinDateWithTime, scrollToEntry } from '../utils';
import LogEntryFormBody from './LogEntryFormBody';

const getTill = (entry, tillNow) => {
    if (tillNow) {
        return new Date();
    }

    return entry.till ? parseISO(entry.till) : null;
};

function LogEntryForm({ id, tillNow, submitContainer, onDone }) {
    const entry = useSelector((state) => selectLogEntryById(state, id));
    const dispatch = useDispatch();
    const settings = useSelector(selectSettings);

    const initialValues = useMemo(() => {
        if (entry) {
            const from = parseISO(entry.from);
            const till = getTill(entry, tillNow);
            const duration = till ? differenceInMinutes(till, from) : 0;

            return {
                date: parseISO(entry.from),
                from,
                till,
                duration,
                pause: entry.pause,
                tags: entry.tags,
                premium: entry.premium || 0,
            };
        }

        const date = new Date();

        return {
            date,
            from: null,
            till: null,
            duration: settings.workingHoursPerDay * 60,
            pause: null,
            tags: [],
            premium: 0,
        };
    }, [entry, settings, tillNow]);

    const handleSubmit = (values) => {
        const [from, till] = onlyDurationNeeded(values.tags)
            ? [
                  joinDateWithDuration(values.date, 0),
                  joinDateWithDuration(values.date, values.duration),
              ]
            : [
                  joinDateWithTime(values.date, values.from),
                  joinDateWithTime(values.date, values.till),
              ];

        const preppedValues = {
            from: formatISO(from),
            till: till ? formatISO(till) : null,
            pause: values.pause || 0,
            tags: values.tags,
            premium: values.premium,
        };

        dispatch(
            entry ? patchLogEntry(entry._id, preppedValues) : createLogEntry(preppedValues)
        ).then((newEntry) => {
            onDone();

            if (!entry) {
                scrollToEntry(newEntry);
            }
        });
    };

    return (
        <Form initialValues={initialValues} validationSchema={entrySchema} onSubmit={handleSubmit}>
            <LogEntryFormBody entry={entry} settings={settings} />

            <Portal container={submitContainer}>
                <SubmitButton outsideForm ignoreDirty={tillNow} data-cy="LogEntryFormSubmit" />
            </Portal>
        </Form>
    );
}

LogEntryForm.propTypes = {
    id: IdPropType,
    tillNow: PropTypes.bool,
    submitContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    onDone: PropTypes.func.isRequired,
};

LogEntryForm.defaultProps = {
    id: null,
    tillNow: false,
    submitContainer: null,
};

export default LogEntryForm;
