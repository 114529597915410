import Grid from '@material-ui/core/Grid';
import Portal from '@material-ui/core/Portal';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import FieldError from '../../../form/components/FieldError';
import Form from '../../../form/components/Form';
import SubmitButton from '../../../form/components/SubmitButton';
import TextInput from '../../../form/components/TextInput';
import { useDismissibleSnackbar } from '../../../snackbar/hooks';
import { setPassword } from '../../actions';
import { setPasswordSchema } from '../../schema';

function SetPasswordForm({ resetPasswordToken, submitContainer, onDone }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useDismissibleSnackbar();

    const handleSubmit = (values) =>
        dispatch(setPassword(values)).then(() => {
            onDone();
            enqueueSnackbar(t('form.SetPassword.successful'));
        });

    return (
        <Form
            initialValues={{
                resetPasswordToken,
                password: '',
            }}
            validationSchema={setPasswordSchema}
            onSubmit={handleSubmit}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextInput
                        label={t('form.SetPassword.password')}
                        name="password"
                        type="password"
                        fullWidth
                    />
                    <FieldError name="resetPasswordToken" />
                </Grid>
            </Grid>

            <Portal container={submitContainer}>
                <SubmitButton outsideForm />
            </Portal>
        </Form>
    );
}

SetPasswordForm.propTypes = {
    submitContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    onDone: PropTypes.func.isRequired,
    resetPasswordToken: PropTypes.string,
};

SetPasswordForm.defaultProps = {
    submitContainer: null,
    resetPasswordToken: null,
};

export default SetPasswordForm;
