import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { format, parse } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import packageJson from '../../../../package.json';
import Dialog from '../../dialogs/components/Dialog';
import { patchSettings } from '../../settings/actions';
import { selectSettings } from '../../settings/selectors';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: theme.palette.background.paper,
    },

    subheader: {
        color: theme.palette.text.disabled,
        marginTop: -theme.spacing(2),
        ...theme.typography.caption,
    },

    content: {
        paddingTop: 0,
    },

    outerList: {
        paddingTop: 0,
        paddingBottom: 0,
    },

    outerItem: {
        padding: 0,
    },

    innerList: {
        paddingTop: 0,
    },

    action: {
        textAlign: 'right',
    },
}));

const typeIcons = {
    '[BUG]': '🐞',
    '[QOL]': '🚀',
    '[NEW]': '✨',
};

function WhatsNewDialog({ open, onClose, onExited }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const settings = useSelector(selectSettings);
    const dispatch = useDispatch();

    const changes = t('meta.changelog', { returnObjects: true });

    const handleAccept = () => {
        onClose();
        if (settings.versionRead !== packageJson.version) {
            dispatch(patchSettings(settings._id, { versionRead: packageJson.version }));
        }
    };

    const handleToggle = () => {
        onClose();
        dispatch(
            patchSettings(settings._id, {
                versionRead: packageJson.version,
                disableVersionHint: !settings.disableVersionHint,
            })
        );
    };

    return (
        <Dialog
            open={open}
            onClose={handleAccept}
            onExited={onExited}
            fullScreen={fullScreen}
            data-cy="WhatsNewDialog"
        >
            <DialogTitle>{t('meta.whatsNew')}</DialogTitle>

            <DialogContent className={classes.content}>
                <List className={classes.outerList}>
                    {changes.map((version, index) => (
                        <React.Fragment key={version.version}>
                            <ListItem className={classes.outerItem}>
                                <List className={classes.innerList} dense>
                                    <ListSubheader className={classes.header}>
                                        {version.version}
                                    </ListSubheader>
                                    {version.date && (
                                        <ListSubheader className={classes.subheader}>
                                            {format(
                                                parse(version.date, 'yyyy-MM-dd', new Date()),
                                                'dd. MMM yyyy'
                                            )}
                                        </ListSubheader>
                                    )}
                                    {version.changes.map((change) => (
                                        <ListItem key={change} className={classes.innerItem}>
                                            <Grid container spacing={1}>
                                                <Grid item>
                                                    {typeIcons[change.substring(0, 5)]}
                                                </Grid>
                                                <Grid item xs>
                                                    <Typography variant="body2">
                                                        {change.substring(6)}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </ListItem>
                                    ))}
                                </List>
                            </ListItem>
                            {index < changes.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                </List>
            </DialogContent>

            <DialogActions>
                <Grid container alignItems="center">
                    <Grid item xs={6}>
                        <Button size="small" onClick={handleToggle} data-cy="WhatsNewDialog.toggle">
                            {settings && settings.disableVersionHint
                                ? t('dialogs.WhatsNew.enableHint')
                                : t('dialogs.WhatsNew.disableHint')}
                        </Button>
                    </Grid>
                    <Grid item xs={6} className={classes.action}>
                        <Button
                            size="small"
                            onClick={handleAccept}
                            color="primary"
                            data-cy="WhatsNewDialog.close"
                        >
                            {t('dialogs.WhatsNew.close')}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}

WhatsNewDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
};

export default WhatsNewDialog;
