import { getMonthFromDate, isHoliday } from '@workaholic/config/holidays';
import { HOLIDAY, WEEKEND } from '@workaholic/config/tags';
import { getISODay } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { findHolidaysForMonth } from '../../holidays/actions';
import { selectHolidayByDate, selectHolidayByMonth } from '../../holidays/selectors';
import { useFieldFast } from '../hooks';
import TagInput from './TagInput';

const getProposedTags = (date, holiday, countyCode, saturdayIsWeekend) => {
    const tags = [];
    if (isHoliday(holiday, countyCode)) {
        tags.push(HOLIDAY);
    } else if ((saturdayIsWeekend && getISODay(date) === 6) || getISODay(date) === 7) {
        tags.push(WEEKEND);
    }
    return tags;
};

function DateTagInput({
    name,
    date,
    setProposed,
    saturdayIsWeekend,
    countyCode,
    variant,
    colorized,
}) {
    const month = getMonthFromDate(date);
    const holidays = useSelector((state) => selectHolidayByMonth(state, month));
    const holiday = useSelector((state) => selectHolidayByDate(state, date));
    const [, , helpers] = useFieldFast(name);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!holidays) {
            dispatch(findHolidaysForMonth(month));
        }
    }, [holidays, month, dispatch]);

    useEffect(() => {
        if (setProposed) {
            helpers.setValue(getProposedTags(date, holiday, countyCode, saturdayIsWeekend));
        }
    }, [helpers, setProposed, date, holiday, countyCode, saturdayIsWeekend]);

    return <TagInput name={name} variant={variant} colorized={colorized} />;
}

DateTagInput.propTypes = {
    name: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    setProposed: PropTypes.bool,
    saturdayIsWeekend: PropTypes.bool,
    countyCode: PropTypes.string,
    variant: PropTypes.string,
    colorized: PropTypes.bool,
};

DateTagInput.defaultProps = {
    setProposed: false,
    saturdayIsWeekend: true,
    countyCode: null,
    variant: null,
    colorized: false,
};

export default DateTagInput;
