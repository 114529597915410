import PropTypes from 'prop-types';

export const SettingsPropType = PropTypes.shape({
    workingHoursPerDay: PropTypes.number,
    initialOvertime: PropTypes.number,
    initialLeavePaid: PropTypes.number,
    showPayout: PropTypes.bool,
    saturdayIsWeekend: PropTypes.bool,
    showOnboarding: PropTypes.bool,
    versionRead: PropTypes.string,
    disableVersionHint: PropTypes.bool,
});
