import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '../../../dialogs/components/Dialog';
import { useCallbackRef } from '../../../hooks';
import { IdPropType } from '../../../proptypes';
import ChangePasswordForm from './ChangePasswordForm';

function ChangePasswordDialog({ open, onClose, onExited, userId }) {
    const { t } = useTranslation();
    const [current, ref] = useCallbackRef();

    return (
        <Dialog open={open} onClose={onClose} onExited={onExited}>
            <DialogTitle>{t('dialogs.ChangePassword.title')}</DialogTitle>
            <DialogContent>
                {userId && (
                    <ChangePasswordForm
                        userId={userId}
                        submitContainer={current}
                        onDone={onClose}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <div ref={ref} />
            </DialogActions>
        </Dialog>
    );
}

ChangePasswordDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
    userId: IdPropType,
};

ChangePasswordDialog.defaultProps = {
    userId: null,
};

export default ChangePasswordDialog;
