export const selectLogsByMonth = (state) => state.logs.byMonth;

export const selectAllLogMonths = (state) => state.logs.allMonths;

export const selectLogByMonth = (state, month) => selectLogsByMonth(state)[month];

export const selectIsLoading = (state) => state.logs.isLoading;

export const selectMonthsLoading = (state) => state.logs.monthsLoading;

export const selectMonthLoading = (state, month) =>
    selectIsLoading(state) || selectMonthsLoading(state)[month];

export const selectMonthInitialized = (state, month) => !!selectLogByMonth(state, month);
