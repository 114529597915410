import { formatISO } from 'date-fns';

export const selectHolidaysByDate = (state) => state.holidays.byDate;

export const selectHolidayByDateString = (state, date) =>
    selectHolidaysByDate(state)[date.substring(0, 'yyyy-MM-dd'.length)];
export const selectHolidayByDate = (state, date) =>
    selectHolidayByDateString(state, formatISO(date));

export const selectHolidaysByMonth = (state) => state.holidays.byMonth;

export const selectHolidayByMonth = (state, month) => {
    const dates = selectHolidaysByMonth(state)[month];
    return dates ? dates.map((date) => selectHolidayByDateString(state, date)) : undefined;
};
