import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { useDebounce } from '../../hooks';
import LoadingFade from './LoadingFade';

const useStyles = makeStyles((theme) => ({
    danger: {
        color: theme.palette.error.main,
    },
    success: {
        color: theme.palette.success.main,
    },

    loading: {
        position: 'absolute',
        top: 7,
    },
}));

const LoadingButton = forwardRef(
    ({ onClick, disabled, isLoading, children, danger, success, innerRef, ...other }, ref) => {
        const classes = useStyles();

        const [dimensions, setDimensions] = useState(null);
        const delayedDisabled = useDebounce(disabled, disabled ? 500 : 0); // enable instantly

        useEffect(() => {
            if (isLoading && !dimensions) {
                const dim = ref.current.getBoundingClientRect();
                setDimensions({ width: dim.width, height: dim.height });
            }
        }, [ref, isLoading, dimensions]);

        return (
            <Button
                {...other}
                disabled={delayedDisabled}
                ref={ref}
                style={isLoading && dimensions ? dimensions : {}}
                classes={{
                    text: (danger && classes.danger) || (success && classes.success) || null,
                }}
                onClick={(e) => {
                    if (isLoading) {
                        e.preventDefault();
                    } else if (onClick) {
                        onClick(e);
                    }
                }}
            >
                <LoadingFade
                    loading={isLoading}
                    progress={
                        <Box className={classes.loading}>
                            {dimensions && (
                                <CircularProgress
                                    size={Math.min(dimensions.width, dimensions.height) - 14}
                                />
                            )}
                        </Box>
                    }
                >
                    <Box>{children}</Box>
                </LoadingFade>
            </Button>
        );
    }
);

LoadingButton.propTypes = {
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    children: PropTypes.node.isRequired,
    danger: PropTypes.bool,
    success: PropTypes.bool,
    onClick: PropTypes.func,
    innerRef: PropTypes.shape({}),
};

LoadingButton.defaultProps = {
    disabled: false,
    isLoading: false,
    danger: false,
    success: false,
    onClick: null,
    innerRef: null,
};

export default LoadingButton;
