/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { compareByDate } from './utils';

const getNormalizedLog = (log) => ({
    ...log,
    entries: log.entries
        .sort(compareByDate)
        .reverse()
        .map((entry) => entry._id),
});

const logsSlice = createSlice({
    name: 'logs',
    initialState: {
        byMonth: {},
        allMonths: [],
        isLoading: false,
        monthsLoading: {},
    },
    reducers: {
        findPending: (state) => {
            state.isLoading = true;
        },
        findSuccess: (state, action) => {
            state.isLoading = false;

            action.payload.data.forEach((log) => {
                if (!state.byMonth[log.month]) {
                    state.allMonths.push(log.month);
                }
                state.byMonth[log.month] = getNormalizedLog(log);
                state.monthsLoading[log.month] = false;
            });
        },

        getPending: {
            prepare: (month) => ({
                meta: { params: { month } },
            }),
            reducer: (state, action) => {
                state.monthsLoading[action.meta.params.month] = true;
            },
        },

        getSuccess: (state, action) => {
            if (!state.byMonth[action.payload.month]) {
                state.allMonths.push(action.payload.month);
            }
            state.byMonth[action.payload.month] = getNormalizedLog(action.payload);
            state.monthsLoading[action.payload.month] = false;
        },

        createSuccess: (state, action) => {
            state.byMonth[action.payload.month] = getNormalizedLog(action.payload);
            state.allMonths.push(action.payload.month);
        },

        patchSuccess: (state, action) => {
            state.byMonth[action.payload.month] = getNormalizedLog(action.payload);
        },

        removeSuccess: (state, action) => {
            const index = state.allMonths.findIndex((id) => id === action.payload.month);
            if (index) {
                delete state.byMonth[action.payload.month];
                delete state.monthsLoading[action.payload.month];
                state.allMonths.splice(index, 1);
            }
        },
    },
});

export const { findSuccess, getPending, getSuccess, createSuccess, patchSuccess } =
    logsSlice.actions;

export default logsSlice.reducer;
