import { differenceInMinutes, getHours, getMinutes, set } from 'date-fns';

export const parseDuration = (duration) =>
    new Date(0, 0, 0, Math.floor(duration / 60), duration % 60, 0);

export const durationToMinutes = (date) => (date ? getHours(date) * 60 + getMinutes(date) : 0);

export const formatMinutes = (min, hideSign = false) => {
    const abs = Math.round(Math.abs(min));
    return `${min < 0 && !hideSign ? '-' : ''}${abs < 10 ? '0' : ''}${abs}`;
};

export const formatTimeDuration = (durationInMinutes = 0, short = false) => {
    const hours = Math.trunc(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;

    return short && minutes === 0 ? `${hours}h` : `${hours}h ${formatMinutes(minutes, true)}m`;
};

export const formatTimeDifference = (fromDate, tillDate, subtract = 0) => {
    if (!fromDate || !tillDate) {
        return '';
    }

    const difference = differenceInMinutes(tillDate, fromDate);

    return subtract > difference
        ? formatTimeDuration(0)
        : formatTimeDuration(difference - subtract);
};

export const joinDateWithTime = (date, time) =>
    date && time ? set(date, { hours: getHours(time), minutes: getMinutes(time) }) : null;

export const joinDateWithDuration = (date, duration) =>
    set(date, { hours: Math.floor(duration / 60), minutes: duration % 60 });

export const scrollToEntry = (entry) => {
    /* good old vanilla js - somewhat hacky but it gets the job done ¯\_(ツ)_/¯ */
    const ref = document.getElementById(`log-entry-${entry._id}`);
    if (ref) {
        ref.scrollIntoView({ behavior: 'smooth' });
    }
};
