import Box from '@material-ui/core/Box';

import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        height: 100,
        width: 20,
        position: 'absolute',
        right: 0,
        top: -50,
        transform: 'rotate(-45deg)',
    },

    primary: {
        backgroundColor: theme.palette.primary.light,
    },

    secondary: {
        backgroundColor: theme.palette.secondary.light,
    },

    success: {
        backgroundColor: theme.palette.success.light,
    },

    error: {
        backgroundColor: theme.palette.error.light,
    },

    warning: {
        backgroundColor: theme.palette.warning.light,
    },

    disabled: {
        backgroundColor: theme.palette.action.disabled,
    },
}));

function CardRibbon({ color, disabled, style, className }) {
    const classes = useStyles();

    return (
        <Box
            className={classNames({
                [classes.root]: true,
                [classes.disabled]: disabled,
                [classes[color]]: true,
                [className]: !!className,
            })}
            style={style}
        />
    );
}

CardRibbon.propTypes = {
    color: PropTypes.string,
    disabled: PropTypes.bool,
    style: PropTypes.shape({}),
    className: PropTypes.string,
};

CardRibbon.defaultProps = {
    color: 'primary',
    disabled: false,
    style: null,
    className: null,
};

export default CardRibbon;
