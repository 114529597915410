import { makeStyles } from '@material-ui/core';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    containerAnchorOriginBottomLeft: {
        marginBottom: 48, // above bottom navigation
    },

    base: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    variantSuccess: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    variantError: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
    },
    variantWarning: {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText,
    },
    variantInfo: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
    },
}));

function SnackbarProvider({ children, ...other }) {
    const classes = useStyles();

    return (
        <NotistackSnackbarProvider maxSnack={2} classes={classes} {...other}>
            {children}
        </NotistackSnackbarProvider>
    );
}

SnackbarProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SnackbarProvider;
