import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
    root: {
        height: '100%',
        overflow: 'auto',
    },
}));

function Playground() {
    const classes = useStyles();

    return (
        <Box className={classes.root} mt={6}>
            <Typography variant="h4">Playground</Typography>
        </Box>
    );
}

Playground.propTypes = {};

export default Playground;
