/*
 * extra action creators
 */

export const findUsers = () => (dispatch, getState, services) => services.users.find();

export const createUser = (user) => (dispatch, getState, services) => services.users.create(user);

export const patchUser = (id, user) => (dispatch, getState, services) =>
    services.users.patch(id, user);

export const removeUser = (id) => (dispatch, getState, services) => services.users.remove(id);
