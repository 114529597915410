import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from 'react-i18next';
import { getTranslatedError } from '../../form/tools';
import { ErrorPropType } from '../proptypes';

function ErrorMessage({ error }) {
    const { t } = useTranslation();
    return (
        error && (
            <FormHelperText error data-cy="error">
                {getTranslatedError(error.errorType || error.message, t)}
            </FormHelperText>
        )
    );
}

ErrorMessage.propTypes = {
    error: ErrorPropType,
};

ErrorMessage.defaultProps = {
    error: null,
};

export default ErrorMessage;
