import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import * as PropTypes from 'prop-types';
import OvertimeTooltipInfo from './OvertimeTooltipInfo';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.paper,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        borderRadius: theme.shape.borderRadius,
        textAlign: 'center',
    },

    arrow: {
        color: theme.palette.grey[300],
    },
}));

function OvertimeTooltip({ slice }) {
    const classes = useStyles();
    const point = slice.points[0];

    return (
        <div className={classes.root}>
            {point.data.step ? (
                <Grid container spacing={1} alignItems="center">
                    <Grid item>
                        <OvertimeTooltipInfo point={point.data.previous} />
                    </Grid>
                    <Grid item className={classes.arrow}>
                        <ArrowForwardIcon />
                    </Grid>
                    <Grid item>
                        <OvertimeTooltipInfo point={point.data.next} />
                    </Grid>
                </Grid>
            ) : (
                <OvertimeTooltipInfo point={point.data} />
            )}
        </div>
    );
}

OvertimeTooltip.propTypes = {
    slice: PropTypes.shape({
        points: PropTypes.arrayOf(
            PropTypes.shape({
                data: PropTypes.shape({
                    step: PropTypes.bool,
                    x: PropTypes.string.isRequired,
                    y: PropTypes.number.isRequired,
                    next: PropTypes.shape({
                        x: PropTypes.string.isRequired,
                        y: PropTypes.number.isRequired,
                    }),
                    previous: PropTypes.shape({
                        x: PropTypes.string.isRequired,
                        y: PropTypes.number.isRequired,
                    }),
                }),
            })
        ),
    }).isRequired,
};

export default OvertimeTooltip;
