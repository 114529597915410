import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ComplianceFormFields from '../../compliance/components/ComplianceFormFields';
import TextInput from '../../form/components/TextInput';

function LoginFormFields({ register }) {
    const { t } = useTranslation();

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextInput label={t('form.Login.email')} name="email" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        label={t('form.Login.password')}
                        name="password"
                        type="password"
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Collapse in={register}>
                <Box mt={1}>
                    <ComplianceFormFields />
                </Box>
            </Collapse>
        </>
    );
}

LoginFormFields.propTypes = {
    register: PropTypes.bool,
};

LoginFormFields.defaultProps = {
    register: false,
};

export default LoginFormFields;
