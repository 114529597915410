import Grid from '@material-ui/core/Grid';
import Portal from '@material-ui/core/Portal';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Form from '../../../form/components/Form';
import SubmitButton from '../../../form/components/SubmitButton';
import TextInput from '../../../form/components/TextInput';
import { useDismissibleSnackbar } from '../../../snackbar/hooks';
import { forgotPassword } from '../../actions';
import { forgotPasswordSchema } from '../../schema';

function ForgotPasswordForm({ submitContainer, onDone }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useDismissibleSnackbar();

    const handleSubmit = (values) =>
        dispatch(forgotPassword(values)).then(() => {
            onDone();
            enqueueSnackbar(t('form.ForgotPassword.successful'));
        });

    return (
        <Form
            initialValues={{ email: '' }}
            validationSchema={forgotPasswordSchema}
            onSubmit={handleSubmit}
        >
            <Grid container spacing={1}>
                <Grid item>
                    <Typography color="textSecondary">{t('form.ForgotPassword.info')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextInput label={t('form.Login.email')} name="email" fullWidth />
                </Grid>
            </Grid>

            <Portal container={submitContainer}>
                <SubmitButton outsideForm label={t('form.ForgotPassword.submit')} />
            </Portal>
        </Form>
    );
}

ForgotPasswordForm.propTypes = {
    submitContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    onDone: PropTypes.func.isRequired,
};

ForgotPasswordForm.defaultProps = {
    submitContainer: null,
};

export default ForgotPasswordForm;
