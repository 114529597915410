import { makeStyles } from '@material-ui/core/styles';
import { formatISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../../form/components/Form';
import { patchSettings } from '../../settings/actions';
import { onboardingSettingsSchema } from '../../settings/schema';
import { selectSettings } from '../../settings/selectors';
import CrossFade from '../../transitions/CrossFade';
import OnboardingFormControl from './OnboardingFormControl';
import FinishStep from './steps/FinishStep';
import OvertimeStep from './steps/OvertimeStep';
import PremiumStep from './steps/PremiumStep';
import VacationStep from './steps/VacationStep';
import WelcomeStep from './steps/WelcomeStep';
import WorkdayStep from './steps/WorkdayStep';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        padding: theme.spacing(2),
        paddingBottom: theme.spacing(8), // Mobile Stepper height
        position: 'relative',
    },
}));

const steps = [WelcomeStep, WorkdayStep, PremiumStep, OvertimeStep, VacationStep, FinishStep];

function OnboardingForm({ onDone }) {
    const classes = useStyles();
    const [step, setStep] = useState(0);
    const settings = useSelector(selectSettings);
    const dispatch = useDispatch();

    const Step = steps[step];

    const initialValues = {
        workingHoursPerDay: 8,
        premiumFrom: null,
        premiumTill: null,
        initialOvertime: 0,
        leaveDays: 30,
        initialLeavePaid: 0,
        showPayout: true,
        saturdayIsWeekend: true,
    };

    const handleSubmit = (values, { setSubmitting }) => {
        const preppedValues = {
            ...values,
            showOnboarding: false,
            premiumFrom: values.premiumFrom ? formatISO(values.premiumFrom) : null,
            premiumTill: values.premiumTill ? formatISO(values.premiumTill) : null,
        };

        return dispatch(patchSettings(settings._id, preppedValues)).then((response) => {
            setSubmitting(false);
            return response;
        });
    };

    return (
        <Form
            initialValues={initialValues}
            validationSchema={onboardingSettingsSchema}
            onSubmit={handleSubmit}
            className={classes.root}
        >
            <CrossFade index={step} className="fullHeight" containerClassName="fullHeight">
                <Step />
            </CrossFade>

            <OnboardingFormControl
                step={step}
                maxSteps={steps.length}
                onStep={setStep}
                onDone={onDone}
                saveAfter={steps.length - 2} // save on the second to last step
            />
        </Form>
    );
}

OnboardingForm.propTypes = {
    onDone: PropTypes.func.isRequired,
};

export default OnboardingForm;
