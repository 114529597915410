import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../loader/components/LoadingButton';

function Button({ label, disabled, innerRef, onClick, ...other }) {
    const [isSubmitting, setSubmitting] = useState(false);
    const fallbackRef = useRef(null);

    const { t } = useTranslation();

    const handleClick = (event) => {
        setSubmitting(true);
        Promise.resolve(onClick(event)).then(() => {
            setSubmitting(false);
        });
    };

    return (
        <LoadingButton
            type="submit"
            size="small"
            color="primary"
            disabled={disabled}
            isLoading={isSubmitting && !disabled}
            ref={innerRef || fallbackRef}
            onClick={handleClick}
            {...other}
        >
            {label || t('form.submit.defaultLabel')}
        </LoadingButton>
    );
}

Button.propTypes = {
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    innerRef: PropTypes.shape({}),
    disabled: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
    label: null,
    innerRef: null,
    disabled: false,
};

export default Button;
