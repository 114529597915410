import { differenceInMinutes, parseISO } from 'date-fns';

import { getBusinessDays } from '../holidays';
import { TAGS } from '../tags';

export const getEmptySummary = (ethereal = undefined) => ({
    ethereal,
    outstanding: 0, // TODO: get previous
    worked: 0,
    total: 0,
    overtime: 0,
    pause: 0,
    scheduled: 0,
    remaining: 0,
    normal: 0,
    premium: 0,
    ...TAGS.reduce((carry, tag) => {
        // eslint-disable-next-line no-param-reassign
        carry[tag] = 0;
        return carry;
    }, {}),
});

export const getEmptyLog = (month, holidays, countyCode, workingHoursPerDay) => ({
    month,
    entries: [],
    summary: getEmptySummary(true),
    workingHoursPerDay,
    businessDays: getBusinessDays(month, holidays, countyCode),
    paidOut: 0,
    ethereal: true,
});

export const getWorked = (entry) => {
    if (!entry.till) {
        return 0;
    }

    const dateFrom = parseISO(entry.from);
    const dateTill = parseISO(entry.till);
    return differenceInMinutes(dateTill, dateFrom) - entry.pause;
};
