import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../form/components/Checkbox';
import DurationInput from '../../../form/components/DurationInput';
import OnboardingStep from './OnboardingStep';

function OvertimeStep() {
    const { t } = useTranslation();

    return (
        <OnboardingStep
            icon={<DoubleArrowIcon style={{ fontSize: 100 }} />}
            title={t('dialogs.Onboarding.steps.Overtime.title')}
            primary={t('dialogs.Onboarding.steps.Overtime.copy')}
            secondary={t('dialogs.Onboarding.steps.Overtime.hint')}
            data-cy="OvertimeStep"
        >
            <DurationInput
                label={t('form.Settings.initialOvertime')}
                name="initialOvertime"
                fullWidth
                minutesStep={5}
            />

            <Checkbox label={t('dialogs.Onboarding.steps.Overtime.payout')} name="showPayout" />
        </OnboardingStep>
    );
}

OvertimeStep.propTypes = {};

export default OvertimeStep;
