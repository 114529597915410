import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import { TimePicker } from '@material-ui/pickers';
import { getMinutes, setMinutes } from 'date-fns';
import { useField } from 'formik';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { parseDuration } from '../../logentries/utils';
import { useFieldError } from '../hooks';

const useStyles = makeStyles({
    input: {
        margin: 0,
        width: 156,
    },

    fullWidth: {
        width: '100%',
    },

    icon: {
        paddingRight: 0,
    },
});

function TimeInput({
    name,
    label,
    onChange,
    fullWidth,
    duration,
    clearable,
    minutesStep,
    ...other
}) {
    const classes = useStyles();
    const [field, meta, helpers] = useField(name);
    const { isError, translatedError } = useFieldError(meta);
    const { t } = useTranslation();
    const theme = useTheme();
    const variant = useMediaQuery(theme.breakpoints.down('sm')) ? 'dialog' : 'inline';

    const focusedDate = duration
        ? parseDuration(0)
        : setMinutes(new Date(), Math.floor(getMinutes(new Date()) / 15) * 15);

    const dialogLabels =
        variant === 'dialog'
            ? {
                  clearLabel: t('form.DateInput.clear'),
                  cancelLabel: t('form.DateInput.cancel'),
              }
            : {};

    return (
        <TimePicker
            {...field}
            name={name}
            onChange={(date) => {
                // TODO: figure out the best way to handle time zone stuff
                //       just use the time zoned date for now
                helpers.setValue(date);
                if (onChange) {
                    onChange(date);
                }
            }}
            onError={(err) => {
                if (err !== meta.error && err) {
                    helpers.setError(err);
                }
            }}
            initialFocusedDate={focusedDate}
            format={t('date.TimeFormat')}
            ampm={false}
            margin="dense"
            label={label}
            invalidDateMessage={t('errors.InvalidDate')}
            minDateMessage={t('errors.MinDate')}
            maxDateMessage={t('errors.MaxDate')}
            className={`${classes.input} ${fullWidth ? classes.fullWidth : ''}`}
            error={isError}
            helperText={isError && translatedError}
            InputProps={{
                className: classes.icon,
            }}
            variant={variant}
            clearable={(clearable && variant === 'dialog') || undefined}
            minutesStep={minutesStep}
            {...dialogLabels}
            {...other}
        />
    );
}

TimeInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    fullWidth: PropTypes.bool,
    duration: PropTypes.bool,
    clearable: PropTypes.bool,
    minutesStep: PropTypes.number,
};

TimeInput.defaultProps = {
    label: null,
    onChange: null,
    fullWidth: false,
    duration: false,
    clearable: false,
    minutesStep: null,
};

export default TimeInput;
