import { node } from 'prop-types';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { login } from '../features/auth/actions';

const AppInitializer = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(login());
    }, [dispatch]);

    return children;
};

AppInitializer.propTypes = {
    children: node.isRequired,
};

export default AppInitializer;
