import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import LoadingButton from '../../loader/components/LoadingButton';

function SubmitButton({ label, innerRef, outsideForm, ignoreDirty, disabled, ...other }) {
    const { dirty, isSubmitting, submitForm } = useFormikContext();
    const fallbackRef = useRef(null);

    const { t } = useTranslation();

    const handleClick = outsideForm ? submitForm : undefined;

    return (
        <LoadingButton
            type="submit"
            size="small"
            color="primary"
            disabled={(!dirty && !ignoreDirty) || disabled}
            isLoading={isSubmitting && !disabled}
            ref={innerRef || fallbackRef}
            onClick={handleClick}
            {...other}
        >
            {label || t('form.submit.defaultLabel')}
        </LoadingButton>
    );
}

SubmitButton.propTypes = {
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    innerRef: PropTypes.shape({}),
    outsideForm: PropTypes.bool,
    ignoreDirty: PropTypes.bool,
    disabled: PropTypes.bool,
};

SubmitButton.defaultProps = {
    label: null,
    innerRef: null,
    outsideForm: false,
    ignoreDirty: false,
    disabled: false,
};

export default SubmitButton;
