import { attachAuthentication } from './authentication';
import { configureRest } from './rest';
import { attachServices } from './services';
import { configureSocket } from './sockets';

const {
    REACT_APP_APITYPE: type,
    REACT_APP_APIPROTOCOL: protocol,
    REACT_APP_APIDOMAIN: domain,
    REACT_APP_APIPORT: port,
} = process.env;

export default function configureServices() {
    let api = null;
    switch (type.toLowerCase()) {
        case 'rest': {
            api = configureRest(protocol, domain, port);
            break;
        }
        case 'socket': {
            api = configureSocket(protocol, domain, port);
            break;
        }
        default:
            throw new Error(`Unknown api type: ${type}`);
    }

    const services = {
        api,
        dispatch: () => {
            throw Error('Call services.setDispatch() before using any service');
        },
        setDispatch: (dispatch) => {
            services.dispatch = dispatch;
        },
    };

    attachAuthentication(services);

    attachServices(services);

    return services;
}
