import ChangeEmailDialog from '../auth/components/password/ChangeEmailDialog';
import ChangePasswordDialog from '../auth/components/password/ChangePasswordDialog';
import ForgotPasswordDialog from '../auth/components/password/ForgotPasswordDialog';
import SetPasswordDialog from '../auth/components/password/SetPasswordDialog';
import WhatsNewDialog from '../changelog/components/WhatsNewDialog';
import ComplianceDialog from '../compliance/components/ComplianceDialog';
import LogEntryDialog from '../logentries/components/LogEntryDialog';
import LogDialog from '../logs/components/LogDialog';
import OnboardingDialog from '../onboarding/components/OnboardingDialog';
import UserDialog from '../users/components/UserDialog';
import YearDialog from '../years/components/YearDialog';

export const LOG_DIALOG = 'LOG_DIALOG';
export const LOG_ENTRY_DIALOG = 'LOG_ENTRY_DIALOG';
export const YEAR_DIALOG = 'YEAR_DIALOG';

export const USER_DIALOG = 'USER_DIALOG';

export const ONBOARDING_DIALOG = 'ONBOARDING_DIALOG';

export const COMPLIANCE_DIALOG = 'COMPLIANCE_DIALOG';

export const WHATS_NEW_DIALOG = 'WHATS_NEW_DIALOG';

export const CHANGE_EMAIL_DIALOG = 'CHANGE_EMAIL_DIALOG';
export const CHANGE_PASSWORD_DIALOG = 'CHANGE_PASSWORD_DIALOG';
export const FORGOT_PASSWORD_DIALOG = 'FORGOT_PASSWORD_DIALOG';
export const SET_PASSWORD_DIALOG = 'SET_PASSWORD_DIALOG';

export const DIALOG_MAPPING = {
    [CHANGE_EMAIL_DIALOG]: ChangeEmailDialog,
    [CHANGE_PASSWORD_DIALOG]: ChangePasswordDialog,
    [COMPLIANCE_DIALOG]: ComplianceDialog,
    [FORGOT_PASSWORD_DIALOG]: ForgotPasswordDialog,
    [LOG_DIALOG]: LogDialog,
    [LOG_ENTRY_DIALOG]: LogEntryDialog,
    [ONBOARDING_DIALOG]: OnboardingDialog,
    [SET_PASSWORD_DIALOG]: SetPasswordDialog,
    [USER_DIALOG]: UserDialog,
    [WHATS_NEW_DIALOG]: WhatsNewDialog,
    [YEAR_DIALOG]: YearDialog,
};
