import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { formatTimeDuration } from '../../logentries/utils';
import { selectSettings } from '../../settings/selectors';
import { SummaryPropType } from '../proptypes';

const useStyles = makeStyles((theme) => ({
    loading: {
        color: theme.palette.grey[200],
    },

    overtime: {
        color: theme.palette.success.main,
    },

    pastOutstanding: {
        color: theme.palette.error.main,
    },
}));

const formatLabel = (duration, prependPlus = false) =>
    `${prependPlus ? '+' : ''}${formatTimeDuration(duration, true)}`;

const getStatus = (isPositive, isNegative) =>
    (isPositive && 'positive') || (isNegative && 'negative');

const getLabel = (summary, logHeader) => {
    if (!summary) {
        return ['0h'];
    }

    switch (logHeader) {
        case 'worked':
            return [
                formatLabel(summary.worked),
                getStatus(summary.worked >= summary.scheduled, summary.worked < summary.scheduled),
            ];
        case 'scheduled':
            return [formatLabel(summary.scheduled)];
        case 'total':
            return [
                formatLabel(Math.abs(summary.total), summary.total < 0),
                getStatus(summary.total <= 0, summary.total > 0),
            ];
        case 'remaining':
        default:
            return [
                formatLabel(Math.max(summary.remaining, summary.overtime) || 0, !!summary.overtime),
                getStatus(summary.overtime, summary.remaining),
            ];
    }
};

function LogTitle({ summary, isPast }) {
    const classes = useStyles();
    const settings = useSelector(selectSettings);

    const [label, status] = getLabel(summary, settings.logHeader);

    return (
        <Typography
            component="div"
            variant="h4"
            className={classNames({
                [classes.loading]: !summary || summary.ethereal,
                [classes.overtime]: summary && !summary.ethereal && status === 'positive',
                [classes.pastOutstanding]:
                    summary && !summary.ethereal && isPast && status === 'negative',
            })}
        >
            {label}
        </Typography>
    );
}

LogTitle.propTypes = {
    summary: SummaryPropType,
    isPast: PropTypes.bool.isRequired,
};

LogTitle.defaultProps = {
    summary: null,
};

export default LogTitle;
