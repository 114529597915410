import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useDialog } from '../../dialogs/components/DialogControl';
import { USER_DIALOG } from '../../dialogs/dialogs';
import { IdPropType } from '../../proptypes';
import { selectUserById } from '../selectors';
import CardRibbon from './CardRibbon';
import UserAvatar from './UserAvatar';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },

    avatar: {
        color: theme.palette.text.secondary,
    },

    inactive: {
        color: theme.palette.text.disabled,
    },
}));

function UserCard({ userId }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { openDialog } = useDialog();
    const user = useSelector((state) => selectUserById(state, userId));

    const handleClick = () => openDialog(USER_DIALOG, { userId });

    return (
        <Card className={classes.root}>
            <CardRibbon disabled={!user.active} color={user.vip ? 'primary' : 'success'} />
            <CardHeader
                avatar={
                    <Box
                        className={classNames({
                            [classes.avatar]: true,
                            [classes.inactive]: !user.active,
                        })}
                    >
                        <UserAvatar user={user} />
                    </Box>
                }
                title={user.email}
                subheader={format(parseISO(user.createdAt), 'dd.MM.yyyy')}
            />
            <CardActions>
                <Button size="small" color="primary" onClick={handleClick}>
                    {t('dialogs.User.open')}
                </Button>
            </CardActions>
        </Card>
    );
}

UserCard.propTypes = {
    userId: IdPropType.isRequired,
};

export default UserCard;
