import Grid from '@material-ui/core/Grid';
import Portal from '@material-ui/core/Portal';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Form from '../../../form/components/Form';
import SubmitButton from '../../../form/components/SubmitButton';
import TextInput from '../../../form/components/TextInput';
import { IdPropType } from '../../../proptypes';
import { useDismissibleSnackbar } from '../../../snackbar/hooks';
import { patchUser } from '../../../users/actions';
import { changePasswordSchema } from '../../schema';

function ChangePasswordForm({ userId, submitContainer, onDone }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useDismissibleSnackbar();

    const handleSubmit = (values) =>
        dispatch(patchUser(userId, values)).then(() => {
            onDone();
            enqueueSnackbar(t('form.ChangePassword.successful'));
        });

    return (
        <Form
            initialValues={{
                old: '',
                password: '',
            }}
            validationSchema={changePasswordSchema}
            onSubmit={handleSubmit}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextInput
                        label={t('form.ChangePassword.old')}
                        name="old"
                        type="password"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        label={t('form.ChangePassword.password')}
                        name="password"
                        type="password"
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Portal container={submitContainer}>
                <SubmitButton outsideForm />
            </Portal>
        </Form>
    );
}

ChangePasswordForm.propTypes = {
    submitContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    onDone: PropTypes.func.isRequired,
    userId: IdPropType.isRequired,
};

ChangePasswordForm.defaultProps = {
    submitContainer: null,
};

export default ChangePasswordForm;
