import { Grid } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import { Trans } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { gdprPath, tosPath } from '../../../routes/paths';
import Checkbox from '../../form/components/Checkbox';

function ComplianceFormFields() {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Checkbox
                    name="tos"
                    label={
                        <Trans
                            i18nKey="form.Compliance.tos"
                            components={[
                                <Link
                                    component={RouterLink}
                                    to={tosPath}
                                    target="_blank"
                                    rel="noopener"
                                />,
                            ]}
                        />
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <Checkbox
                    name="gdpr"
                    label={
                        <Trans
                            i18nKey="form.Compliance.gdpr"
                            components={[
                                <Link
                                    component={RouterLink}
                                    to={gdprPath}
                                    target="_blank"
                                    rel="noopener"
                                />,
                            ]}
                        />
                    }
                />
            </Grid>
        </Grid>
    );
}

ComplianceFormFields.propTypes = {};

export default ComplianceFormFields;
