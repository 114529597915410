import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

import { useDialog } from '../../dialogs/components/DialogControl';
import { LOG_ENTRY_DIALOG } from '../../dialogs/dialogs';

function AddLogEntryFab({ ...props }) {
    const { openDialog } = useDialog();

    return (
        <Fab
            color="primary"
            aria-label="add"
            onClick={() => openDialog(LOG_ENTRY_DIALOG)}
            data-cy="AddLogEntryFab"
            {...props}
        >
            <AddIcon />
        </Fab>
    );
}

AddLogEntryFab.propTypes = {};

export default AddLogEntryFab;
