import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Zoom from '@material-ui/core/Zoom';
import PropTypes from 'prop-types';
import { createContext, useContext, useMemo, useState } from 'react';
import AddLogEntryFab from '../../logentries/components/AddLogEntryFab';

import { ADD_LOG_ENTRY_FAB } from '../types';

const FabContext = createContext(null);

export const useFabs = () => useContext(FabContext);

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        right: theme.spacing(2),
        bottom: theme.spacing(2) + 56, // height of BottomNavigation

        '@media print': {
            display: 'none',
        },
    },
}));

function FabControl({ children }) {
    const classes = useStyles();
    const [fab, setFab] = useState(null);
    const theme = useTheme();

    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const fabProps = useMemo(
        () => ({
            showFab: setFab,
        }),
        [setFab]
    );

    return (
        <FabContext.Provider value={fabProps}>
            {children}
            <Box className={classes.root}>
                <Zoom
                    key={ADD_LOG_ENTRY_FAB}
                    in={fab === ADD_LOG_ENTRY_FAB}
                    timeout={transitionDuration}
                    style={{
                        transitionDelay: `${
                            fab === ADD_LOG_ENTRY_FAB ? transitionDuration.exit : 0
                        }ms`,
                    }}
                    unmountOnExit
                >
                    <AddLogEntryFab />
                </Zoom>
            </Box>
        </FabContext.Provider>
    );
}

FabControl.propTypes = {
    children: PropTypes.node.isRequired,
};

export default FabControl;
