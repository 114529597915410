import Fade from '@material-ui/core/Fade';
import * as PropTypes from 'prop-types';

function LoadingFade({ loading, progress, children }) {
    return (
        <>
            <Fade
                in={!loading}
                style={{
                    transitionDelay: loading ? '300ms' : '0ms',
                }}
                timeout={{
                    enter: 300,
                    exit: 800,
                }}
            >
                {children}
            </Fade>
            <Fade
                in={loading}
                style={{
                    transitionDelay: loading ? '800ms' : '0ms',
                }}
                timeout={{
                    enter: 1000,
                }}
                unmountOnExit
            >
                {progress}
            </Fade>
        </>
    );
}

LoadingFade.propTypes = {
    loading: PropTypes.bool.isRequired,
    progress: PropTypes.node.isRequired,
    children: PropTypes.node,
};

LoadingFade.defaultProps = {
    children: null,
};

export default LoadingFade;
