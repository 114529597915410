import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import Metadata from '../components/layout/Metadata';
import Login from '../features/auth/components/Login';
import { selectUser } from '../features/auth/selectors';
import { useDialog } from '../features/dialogs/components/DialogControl';
import { registerPath } from '../routes/paths';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),

        [theme.breakpoints.up('sm')]: {
            marginTop: '10vh',
        },
    },
}));

function LoginPage() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { state } = useLocation();
    const { openSetPasswordDialog } = useDialog();
    const { token } = useParams();
    const user = useSelector(selectUser);
    const { pathname } = useLocation();

    useEffect(() => {
        if (token) {
            openSetPasswordDialog({ resetPasswordToken: token });
        }
    }, [token, openSetPasswordDialog]);

    if (user !== null) {
        const to = state ? state.from : '/';
        return <Redirect to={to} />;
    }

    return (
        <>
            <Metadata title={t(pathname === registerPath ? 'nav.register' : 'nav.login')} />

            <Header />

            <Container maxWidth="md" className={classes.root}>
                <Login register={pathname === registerPath} />
            </Container>

            <Footer />
        </>
    );
}

export default LoginPage;
