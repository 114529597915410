import PersonIcon from '@material-ui/icons/Person';
import SecurityIcon from '@material-ui/icons/Security';
import { ADMIN_ROLE } from '@workaholic/config/users/roles';
import { UserPropType } from '../proptypes';

function UserAvatar({ user }) {
    return user.roles.includes(ADMIN_ROLE) ? (
        <SecurityIcon color="inherit" />
    ) : (
        <PersonIcon color="inherit" />
    );
}

UserAvatar.propTypes = {
    user: UserPropType.isRequired,
};

export default UserAvatar;
