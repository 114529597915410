import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { getEmptyYear } from '@workaholic/config/years';
import { isBefore, startOfYear } from 'date-fns';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SummaryItem from '../../../components/summary/SummaryItem';
import { useDialog } from '../../dialogs/components/DialogControl';
import { YEAR_DIALOG } from '../../dialogs/dialogs';
import { getYear } from '../actions';
import { selectYearByYear, selectYearLoading } from '../selectors';
import { parseYear } from '../utils';

function YearSummary({ year }) {
    const { t } = useTranslation();
    const { openDialog } = useDialog();
    const yearDate = parseYear(year);
    const isPast = isBefore(yearDate, startOfYear(new Date()));
    const loadedData = useSelector((state) => selectYearByYear(state, year));

    const isLoading = useSelector((state) => selectYearLoading(state, year));
    const dispatch = useDispatch();

    useEffect(() => {
        if (!loadedData && !isLoading) {
            dispatch(getYear(year));
        }
    }, [dispatch, loadedData, isLoading, year]);

    const handleClick = () => openDialog(YEAR_DIALOG, { year });

    const yearData = loadedData || getEmptyYear(year);

    return (
        <Container maxWidth="md">
            <Grid container spacing={1}>
                <Grid item xs={3}>
                    <SummaryItem label={t('summary.leaveTaken')} value={yearData.leaveTaken} />
                </Grid>

                <Grid item xs={3}>
                    <SummaryItem
                        label={t('summary.leaveDays')}
                        value={Math.min(yearData.total, yearData.leaveDays)}
                        positive={yearData.total > 0 && isPast}
                        negative={yearData.total < 0 && isPast}
                        extra={yearData.outstanding > 0 ? yearData.outstanding : null}
                    />
                </Grid>

                <Grid item xs={3}>
                    <SummaryItem label={t('tags.sick')} value={yearData.sick} />
                </Grid>

                <Grid item xs={3}>
                    <SummaryItem
                        label={t('tags.education')}
                        value={yearData.education}
                        duration
                        dense
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button onClick={handleClick}>
                        {t(yearData.ethereal ? 'form.Year.create' : 'form.Log.edit')}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
}

YearSummary.propTypes = {
    year: PropTypes.string.isRequired,
};

export default YearSummary;
