export const pendingAction = (endpoint, action, params) => ({
    type: `${endpoint}/${action}Pending`,
    meta: {
        key: endpoint,
        action,
        params,
    },
});

export const successAction = (endpoint, action, params, payload) => ({
    type: `${endpoint}/${action}Success`,
    payload,
    meta: {
        key: endpoint,
        action,
        params,
    },
});

export const errorAction = (endpoint, action, params, error) => ({
    type: `${endpoint}/${action}Error`,
    payload: error,
    error: true,
    meta: {
        key: endpoint,
        action,
        params,
    },
});
