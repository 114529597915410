import { useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import LogoutButton from '../../auth/components/LogoutButton';
import Dialog from '../../dialogs/components/Dialog';
import OnboardingForm from './OnboardingForm';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '60vh',
        minHeight: 300,
    },

    logout: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        zIndex: 1,
    },
}));

function OnboardingDialog({ open, onClose, onExited }) {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onExited={onExited}
            fullScreen={fullScreen}
            maxWidth="sm"
            fullWidth
            disableClose
            PaperProps={{
                className: classNames({
                    [classes.root]: !fullScreen,
                }),
            }}
            data-cy="OnboardingDialog"
        >
            <LogoutButton className={classes.logout} onLogout={onClose} icon />
            <OnboardingForm onDone={onClose} />
        </Dialog>
    );
}

OnboardingDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
};

export default OnboardingDialog;
