/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { loginSuccess, logoutSuccess } from '../auth/authSlice';

const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        current: null,
    },
    reducers: {
        patchSuccess: (state, action) => {
            state.current = action.payload;
        },
    },
    extraReducers: {
        [loginSuccess]: (state, action) => {
            state.current = action.payload.settings;
        },
        [logoutSuccess]: (state) => {
            state.current = null;
        },
    },
});

export default settingsSlice.reducer;
