import { useMediaQuery, useTheme } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '../../dialogs/components/Dialog';
import { useCallbackRef } from '../../hooks';
import ComplianceForm from './ComplianceForm';

function ComplianceDialog({ open, onClose, onExited }) {
    const { t } = useTranslation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const [current, ref] = useCallbackRef();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onExited={onExited}
            fullScreen={fullScreen}
            maxWidth="sm"
            fullWidth
            disableClose
            data-cy="ComplianceDialog"
        >
            <DialogTitle>{t('dialogs.Compliance.title')}</DialogTitle>
            <DialogContent>
                <ComplianceForm submitContainer={current} onDone={onClose} />
            </DialogContent>
            <DialogActions>
                <div ref={ref} />
            </DialogActions>
        </Dialog>
    );
}

ComplianceDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
};

export default ComplianceDialog;
