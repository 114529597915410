import { RESET_PASSWORD_ACTION, SET_PASSWORD_ACTION } from '@workaholic/config/auth/passwords';
import { loading, loadingError } from '../loader/loadingSlice';
import { selectIsLoading } from '../loader/selectors';
import { createUser } from '../users/actions';
import { loginSuccess, logoutSuccess } from './authSlice';
import { selectUser } from './selectors';

/*
 * extra action creators
 */
export const register = (user, key) => (dispatch, getState, services) => {
    dispatch(loading(true, key));

    return dispatch(createUser(user))
        .then(() =>
            services.authenticate({ ...user, strategy: 'local' }).then((response) => {
                dispatch(loginSuccess(response.user));
                dispatch(loading(false, key));
            })
        )
        .catch((error) => {
            dispatch(loadingError(error, key));
        });
};

export const login =
    (user, key = 'app') =>
    (dispatch, getState, services) => {
        if (selectIsLoading(getState(), key)) {
            return Promise.resolve();
        }

        dispatch(loading(true, key));

        return services
            .authenticate(user)
            .then((response) => {
                dispatch(loginSuccess(response.user));
                dispatch(loading(false, key));
            })
            .catch((error) => {
                dispatch(loadingError(error, key));
            });
    };

export const logout = () => (dispatch, getState, services) =>
    services.logout().then(() => dispatch(logoutSuccess()));

export const forgotPassword = (values) => (dispatch, getState, services) =>
    services.passwords.create({ ...values, action: RESET_PASSWORD_ACTION });

export const setPassword = (values) => (dispatch, getState, services) =>
    services.passwords.create({ ...values, action: SET_PASSWORD_ACTION });

export const checkRemovedUser = (user) => (dispatch, getState, services) => {
    const current = selectUser(getState());

    if (current._id === user._id) {
        services.logout().catch(() => {
            /**
             * For some reason this explodes if the user does not exist anymore.
             * But it removes the socket connection anyway and that is what we wanted...
             */
            dispatch(logoutSuccess());
        });
    }
};
