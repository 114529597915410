import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Dialog from '../../dialogs/components/Dialog';
import ConfirmationButton from '../../form/components/ConfirmationButton';
import { useCallbackRef } from '../../hooks';
import { IdPropType } from '../../proptypes';
import { removeLogEntry } from '../actions';
import LogEntryForm from './LogEntryForm';
import StartTrackingButton from './StartTrackingButton';

function LogEntryDialog({ open, onClose, onExited, id, tillNow }) {
    const { t } = useTranslation();
    const [current, ref] = useCallbackRef();
    const theme = useTheme();
    const fullWidth = useMediaQuery(theme.breakpoints.down('xs'));
    const dispatch = useDispatch();

    const handleDelete = () => dispatch(removeLogEntry(id)).then(onClose);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onExited={onExited}
            fullWidth={fullWidth}
            data-cy="LogEntryDialog"
        >
            <DialogTitle>
                {t(id ? 'dialogs.LogEntry.title.edit' : 'dialogs.LogEntry.title.create')}
            </DialogTitle>
            <DialogContent>
                <LogEntryForm
                    submitContainer={current}
                    id={id}
                    tillNow={tillNow}
                    onDone={onClose}
                />
            </DialogContent>
            <DialogActions>
                {id ? (
                    <ConfirmationButton label={t('dialogs.delete')} onConfirm={handleDelete}>
                        <div ref={ref} />
                    </ConfirmationButton>
                ) : (
                    <Grid container spacing={1} justifyContent="space-between">
                        <Grid item>
                            <StartTrackingButton onDone={onClose} />
                        </Grid>
                        <Grid item>
                            <div ref={ref} />
                        </Grid>
                    </Grid>
                )}
            </DialogActions>
        </Dialog>
    );
}

LogEntryDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
    id: IdPropType,
    tillNow: PropTypes.bool,
};

LogEntryDialog.defaultProps = {
    id: null,
    tillNow: false,
};

export default LogEntryDialog;
