import { formatISO } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from '../../form/components/Button';
import { createLogEntry } from '../actions';
import { scrollToEntry } from '../utils';

function StartTrackingButton({ onDone }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleClick = () => {
        const preppedValues = {
            from: formatISO(new Date()),
            till: null,
            pause: 0,
            tags: [],
            premium: 0,
        };

        dispatch(createLogEntry(preppedValues)).then((newEntry) => {
            onDone();
            scrollToEntry(newEntry);
        });
    };

    return (
        <Button
            label={t('dialogs.LogEntry.tracking.start')}
            onClick={handleClick}
            data-cy="StartTrackingButton"
        />
    );
}

StartTrackingButton.propTypes = {
    onDone: PropTypes.func,
};

StartTrackingButton.defaultProps = {
    onDone: () => null,
};

export default StartTrackingButton;
