import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as PropTypes from 'prop-types';
import LoadingFade from './LoadingFade';

function LoadingContainer({ loading, children, progress }) {
    return (
        <LoadingFade
            loading={loading}
            progress={
                <Box m={6} mt={8}>
                    <LinearProgress
                        variant={progress !== null ? 'determinate' : 'indeterminate'}
                        value={progress}
                    />
                </Box>
            }
        >
            <Container maxWidth="md">{!loading && children}</Container>
        </LoadingFade>
    );
}

LoadingContainer.propTypes = {
    loading: PropTypes.bool.isRequired,
    children: PropTypes.node,
    progress: PropTypes.number,
};

LoadingContainer.defaultProps = {
    children: null,
    progress: null,
};

export default LoadingContainer;
