// eslint-disable-next-line import/no-import-module-exports
import ReactDOM from 'react-dom';
// eslint-disable-next-line import/no-import-module-exports
import App from './components/App';
// eslint-disable-next-line import/no-import-module-exports
import './i18n';
// eslint-disable-next-line import/no-import-module-exports
import './styles/index.css';

const renderApp = () => {
    ReactDOM.render(<App />, document.getElementById('root'));
};

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./components/App', renderApp);
}

renderApp();
