import { Grid, makeStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { bool, node } from 'prop-types';

const useStyles = makeStyles({
    loading: {
        minHeight: '80vh',
    },
});

function LoadingPage({ isLoading, children }) {
    const classes = useStyles();

    return isLoading ? (
        <Grid container justifyContent="center" alignItems="center" className={classes.loading}>
            <Grid item xs={6} sm={4}>
                <LinearProgress />
            </Grid>
        </Grid>
    ) : (
        children
    );
}

LoadingPage.propTypes = {
    isLoading: bool.isRequired,
    children: node.isRequired,
};

export default LoadingPage;
