import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ResponsiveLine } from '@nivo/line';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import OvertimeTooltip from './OvertimeTooltip';

const useStyles = makeStyles({
    root: {
        height: 200,
    },
});

function Overtime({ logs }) {
    const classes = useStyles();
    const theme = useTheme();

    const [data, setData] = useState(null);
    const [range, setRange] = useState(null);

    useEffect(() => {
        const stepped = logs.reduce((carry, log) => {
            const previous = carry[carry.length - 1];

            if (previous) {
                carry.push({
                    step: true,
                    x: `${previous.x}.step`,
                    y:
                        Math.sign(previous.y) !== Math.sign(-log.summary.outstanding)
                            ? 0
                            : previous.y + (-log.summary.outstanding - previous.y) / 2,
                });
            }

            carry.push({
                x: log.month,
                y: -log.summary.outstanding,
            });
            return carry;
        }, []);

        const combined = stepped.map((entry, index) => ({
            ...entry,
            previous: stepped[index - 1],
            next: stepped[index + 1],
        }));

        const positive = combined.map((entry, index) => {
            const previous = combined[index - 1];
            const next = combined[index + 1];

            return entry.y > 0 || (previous && previous.y > 0) || (next && next.y > 0)
                ? entry
                : {
                      ...entry,
                      y: null,
                  };
        });

        const negative = combined.map((entry, index) => {
            const previous = combined[index - 1];
            const next = combined[index + 1];

            return entry.y < 0 || (previous && previous.y < 0) || (next && next.y < 0)
                ? entry
                : { ...entry, y: null };
        });

        const neutral = combined.map((entry, index) => {
            const previous = combined[index - 1];
            const next = combined[index + 1];

            return entry.y === 0 && ((previous && previous.y === 0) || (next && next.y === 0))
                ? entry
                : { ...entry, y: null };
        });

        if (combined.length > 0) {
            setRange(
                combined.reduce(
                    ({ min, max }, { y }) => ({
                        min: Math.min(min, y),
                        max: Math.max(max, y),
                    }),
                    { min: -1, max: 1 }
                )
            );
        }

        setData([
            {
                id: 'neutral',
                data: neutral.map((entry) => ({ ...entry, y: entry.y !== null ? 0 : null })),
            },
            {
                id: 'positive',
                data: positive.map((entry) => ({ ...entry, y: entry.y !== null ? 0 : null })),
            },
            {
                id: 'negative',
                data: negative.map((entry) => ({ ...entry, y: entry.y !== null ? 0 : null })),
            },
        ]);

        setTimeout(() => {
            setData(
                [
                    { id: 'neutral', data: neutral },
                    { id: 'positive', data: positive },
                    { id: 'negative', data: negative },
                ],
                100
            );
        });
    }, [logs, setData, setRange]);

    return (
        <Box className={classes.root} data-cy="Overtime">
            {range && data ? (
                <ResponsiveLine
                    margin={{
                        top: theme.spacing(1),
                        bottom: theme.spacing(1),
                        left: 0,
                        right: 0,
                    }}
                    data={data}
                    curve="monotoneX"
                    enablePoints={false}
                    colors={[
                        theme.palette.graph.neutral,
                        theme.palette.graph.positive,
                        theme.palette.graph.negative,
                    ]}
                    enableArea
                    areaOpacity={0.07}
                    gridXValues={[]}
                    gridYValues={1}
                    enableSlices="x"
                    sliceTooltip={OvertimeTooltip}
                    axisLeft={null}
                    axisBottom={null}
                    yScale={{
                        type: 'linear',
                        stacked: false,
                        ...range,
                    }}
                />
            ) : null}
        </Box>
    );
}

Overtime.propTypes = {
    logs: PropTypes.arrayOf(
        PropTypes.shape({
            month: PropTypes.string.isRequired,
            summary: PropTypes.shape({
                outstanding: PropTypes.number.isRequired,
            }),
        })
    ).isRequired,
};

export default Overtime;
