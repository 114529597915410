import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { format, isBefore, startOfMonth } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';
import { bindKeyboard, virtualize } from 'react-swipeable-views-utils';
import SummaryHeader from '../../../components/summary/SummaryHeader';
import { useFabs } from '../../fabs/components/FabControl';
import { ADD_LOG_ENTRY_FAB } from '../../fabs/types';
import CrossFade from '../../transitions/CrossFade';
import { selectLogByMonth } from '../selectors';
import { getNextMonth } from '../utils';

import Log from './Log';
import LogSummary from './LogSummary';
import LogTitle from './LogTitle';

const VirtualizeSwipeableViews = bindKeyboard(virtualize(SwipeableViews));

const useStyles = makeStyles({
    content: {
        overflow: 'hidden',
    },
});

function Logs() {
    const classes = useStyles();
    const initialDate = useMemo(() => new Date(), []);
    const [index, setIndex] = useState(0);
    const [headerIndex, setHeaderIndex] = useState(0);
    const { showFab } = useFabs();
    const [expanded, setExpanded] = useState(false);

    const monthDate = getNextMonth(initialDate, headerIndex, true);
    const isPast = isBefore(monthDate, startOfMonth(new Date()));
    const log = useSelector((state) => selectLogByMonth(state, format(monthDate, 'yyyy-MM')));

    const renderer = useCallback(
        ({ index: i, key }) => <Log key={key} month={getNextMonth(initialDate, i)} />,
        [initialDate]
    );

    useEffect(() => {
        showFab(ADD_LOG_ENTRY_FAB);
        return () => showFab(null);
    }, [showFab]);

    return (
        <Grid container direction="column" className="fullHeight" data-cy="Logs">
            <Grid item>
                <CrossFade index={headerIndex}>
                    <SummaryHeader
                        primary={<LogTitle summary={log ? log.summary : null} isPast={isPast} />}
                        secondary={format(monthDate, 'LLLL yyyy')}
                        onNext={() => {
                            setIndex(index + 1);
                            setHeaderIndex(index + 1);
                        }}
                        onPrevious={() => {
                            setIndex(index - 1);
                            setHeaderIndex(index - 1);
                        }}
                        expanded={expanded}
                        onExpand={setExpanded}
                    >
                        {log && <LogSummary log={log} isPast={isPast} />}
                    </SummaryHeader>
                </CrossFade>
            </Grid>
            <Grid item xs className={classes.content}>
                <VirtualizeSwipeableViews
                    index={index}
                    overscanSlideAfter={1}
                    overscanSlideBefore={1}
                    slideRenderer={renderer}
                    onChangeIndex={(to) => {
                        setIndex(to);
                    }}
                    slideStyle={{ height: '100%' }}
                    containerStyle={{ height: '100%' }}
                    style={{ height: '100%' }}
                    onSwitching={(progress) => {
                        const next = Math.round(progress - 1) + index;
                        if (next !== headerIndex) {
                            setHeaderIndex(next);
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default Logs;
