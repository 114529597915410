import { arrayOf, shape, string } from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { selectSettings } from '../../features/settings/selectors';

const siteMetadata = {
    title: 'Workaholic',
    description: 'Description',
    author: 'Thiemo',
};

function Metadata({ description, lang, meta, keywords, title }) {
    const metaDescription = description || siteMetadata.description;
    const settings = useSelector(selectSettings);

    // TODO: translation required?

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${settings?.name || siteMetadata.title}`}
            meta={[
                {
                    name: 'viewport',
                    content:
                        'minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no',
                },
                {
                    name: 'description',
                    content: metaDescription,
                },
                {
                    property: 'og:title',
                    content: title,
                },
                {
                    property: 'og:description',
                    content: metaDescription,
                },
                {
                    property: 'og:type',
                    content: 'website',
                },
                {
                    name: 'twitter:card',
                    content: 'summary',
                },
                {
                    name: 'twitter:creator',
                    content: siteMetadata.author,
                },
                {
                    name: 'twitter:title',
                    content: title,
                },
                {
                    name: 'twitter:description',
                    content: metaDescription,
                },
            ]
                .concat(
                    keywords.length > 0
                        ? {
                              name: 'keywords',
                              content: keywords.join(', '),
                          }
                        : []
                )
                .concat(meta)}
        />
    );
}

Metadata.defaultProps = {
    lang: 'en',
    meta: [],
    keywords: [],
    description: '',
};

Metadata.propTypes = {
    description: string,
    lang: string,
    meta: arrayOf(shape()),
    keywords: arrayOf(string),
    title: string.isRequired,
};

export default Metadata;
