import Grid from '@material-ui/core/Grid';
import { getOverlappingDuration } from '@workaholic/config/dates';

import { onlyDurationNeeded } from '@workaholic/config/tags';
import { differenceInMinutes, parseISO } from 'date-fns';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DateInput from '../../form/components/DateInput';
import DateTagInput from '../../form/components/DateTagInput';
import DurationInput from '../../form/components/DurationInput';
import TimeInput from '../../form/components/TimeInput';
import { useFieldFast } from '../../form/hooks';
import { SettingsPropType } from '../../settings/proptypes';
import { LogEntryPropType } from '../proptypes';

function LogEntryFormBody({ entry, settings }) {
    const { t } = useTranslation();
    const [{ value: date }] = useFieldFast('date');
    const [{ value: tags }] = useFieldFast('tags');
    const [{ value: from }] = useFieldFast('from');
    const [{ value: till }] = useFieldFast('till');
    const [, , { setValue: setPremium }] = useFieldFast('premium');
    const [, , { setValue: setDuration }] = useFieldFast('duration');

    useEffect(() => {
        if (from && till) {
            setDuration(differenceInMinutes(till, from));
        }
    }, [from, till, setDuration]);

    useEffect(() => {
        if (settings.premiumFrom && settings.premiumTill && from && till) {
            setPremium(
                getOverlappingDuration(
                    from,
                    till,
                    parseISO(settings.premiumFrom),
                    parseISO(settings.premiumTill)
                )
            );
        }
    }, [from, till, setPremium, settings.premiumFrom, settings.premiumTill]);

    return (
        <Grid spacing={1} container>
            <Grid item xs={12} sm={12}>
                <DateInput label={t('form.LogEntry.date')} name="date" fullWidth />
            </Grid>
            {onlyDurationNeeded(tags) ? (
                <Grid item xs={12} sm={8}>
                    <DurationInput
                        label={t('form.LogEntry.duration')}
                        name="duration"
                        fullWidth
                        minutesStep={5}
                        positive
                    />
                </Grid>
            ) : (
                <>
                    <Grid item xs={6} sm={4}>
                        <TimeInput
                            label={t('form.LogEntry.from')}
                            name="from"
                            fullWidth
                            minutesStep={5}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <TimeInput
                            label={t('form.LogEntry.till')}
                            name="till"
                            fullWidth
                            minutesStep={5}
                            clearable
                        />
                    </Grid>
                </>
            )}
            <Grid item xs={6}>
                <DurationInput
                    label={t('form.LogEntry.pause')}
                    name="pause"
                    fullWidth
                    minutesStep={5}
                    positive
                />
            </Grid>
            <Grid item xs={6}>
                <DurationInput
                    label={t('form.LogEntry.premium')}
                    name="premium"
                    fullWidth
                    minutesStep={5}
                    positive
                />
            </Grid>
            <Grid item xs={12}>
                <DateTagInput
                    name="tags"
                    date={date}
                    setProposed={!entry}
                    saturdayIsWeekend={settings.saturdayIsWeekend}
                    countyCode={settings.countyCode}
                />
            </Grid>
        </Grid>
    );
}

LogEntryFormBody.propTypes = {
    entry: LogEntryPropType,
    settings: SettingsPropType.isRequired,
};

LogEntryFormBody.defaultProps = {
    entry: null,
};

export default LogEntryFormBody;
