import { useSnackbar } from 'notistack';
import { useMemo } from 'react';

export const useDismissibleSnackbar = () => {
    const { enqueueSnackbar, closeSnackbar, ...other } = useSnackbar();

    const wrappedEnqueueSnackbar = useMemo(
        () =>
            (msg, config = {}) => {
                const key = enqueueSnackbar(msg, {
                    ...config,
                    onClick: (event) => {
                        if (config.onClick) {
                            config.onClick(event);
                        }
                        closeSnackbar(key);
                    },
                });
            },
        [enqueueSnackbar, closeSnackbar]
    );

    return {
        enqueueSnackbar: wrappedEnqueueSnackbar,
        closeSnackbar,
        ...other,
    };
};
