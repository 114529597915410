import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';

import { selectUser } from '../features/auth/selectors';
import PermissionDeniedPage from '../pages/PermissionDeniedPage';
import UserInactivePage from '../pages/UserInactivePage';
import { loginPath } from './paths';

function ProtectedRoutes({ component: Component, paths, ...rest }) {
    const user = useSelector(selectUser);

    return (
        <Route
            {...rest}
            path={Object.keys(paths)}
            render={({ location, ...props }) => {
                if (user === null) {
                    return <Redirect to={{ pathname: loginPath, state: { from: location } }} />;
                }

                if (!user.active) {
                    return <UserInactivePage />;
                }

                if (
                    paths[location.pathname].reduce(
                        (carry, role) => carry || user.roles.includes(role),
                        false
                    )
                ) {
                    return <Component location={location} {...props} />;
                }

                return <PermissionDeniedPage location={location} {...props} />;
            }}
        />
    );
}

ProtectedRoutes.propTypes = {
    component: PropTypes.func.isRequired,
    paths: PropTypes.shape({}).isRequired,
};

export default withRouter(ProtectedRoutes);
