import { useMediaQuery, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { makeStyles } from '@material-ui/styles';
import { TAGS } from '@workaholic/config/tags';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SummaryItem from '../../../components/summary/SummaryItem';
import { useDialog } from '../../dialogs/components/DialogControl';
import { LOG_DIALOG } from '../../dialogs/dialogs';
import { LogPropType } from '../proptypes';

const useStyles = makeStyles({
    print: {
        '@media print': {
            maxWidth: '16.66%',
            flexBasis: '16.66%',
        },
    },

    hideOnPrint: {
        '@media print': {
            display: 'none',
        },
    },
});

function LogSummary({ log, isPast }) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { openDialog } = useDialog();
    const theme = useTheme();
    const spacing = useMediaQuery(theme.breakpoints.down('xs'));

    const handleClick = () => openDialog(LOG_DIALOG, { month: log.month });

    return log.summary.ethereal ? (
        <Box mt={2} data-cy="LogSummary.ethereal">
            <Button variant="contained" color="primary" onClick={handleClick}>
                {t('form.Log.create')}
            </Button>
        </Box>
    ) : (
        <Container maxWidth="md" data-cy="LogSummary">
            <Grid container spacing={1}>
                <Grid item xs={4} sm={3} className={classes.print}>
                    <SummaryItem
                        label={t('summary.worked')}
                        value={log.summary.worked}
                        duration
                        showEmpty
                    />
                </Grid>

                <Grid item xs={4} sm={3} className={classes.print}>
                    <SummaryItem
                        label={t('summary.remaining')}
                        value={log.summary.remaining}
                        duration
                        showEmpty
                        negative={isPast}
                    />
                </Grid>

                <Grid item xs={4} sm={3} className={classes.print}>
                    <SummaryItem
                        label={t('summary.overtime')}
                        value={log.summary.overtime}
                        duration
                        positive
                        showEmpty
                    />
                </Grid>

                <Grid item xs={4} sm={3} className={classes.print}>
                    <SummaryItem
                        label={t('summary.scheduled')}
                        value={log.summary.scheduled}
                        duration
                        showEmpty
                    />
                </Grid>
                <Grid item xs={4} sm={3} className={classes.print}>
                    <SummaryItem label={t('summary.businessDays')} value={log.businessDays} />
                </Grid>
                <Grid item xs={4} sm={3} className={classes.print}>
                    <SummaryItem
                        label={t('summary.workingHoursPerDay')}
                        value={log.workingHoursPerDay || 0}
                    />
                </Grid>

                <Grid item xs={4} sm={3} className={classes.print}>
                    <SummaryItem label={t('summary.normal')} value={log.summary.normal} duration />
                </Grid>

                {TAGS.map((tag) => (
                    <Grid item key={tag} xs={4} sm={3} className={classes.print}>
                        <SummaryItem label={t(`tags.${tag}`)} value={log.summary[tag]} duration />
                    </Grid>
                ))}

                <Grid item xs={4} sm={3} className={classes.print}>
                    <SummaryItem label={t('summary.pauses')} value={log.summary.pause} duration />
                </Grid>

                <Grid item xs={4} sm={3} className={classes.print}>
                    <SummaryItem
                        label={t('summary.premium')}
                        value={log.summary.premium || 0}
                        duration
                    />
                </Grid>

                <Grid item xs={4} sm={3} className={classes.print}>
                    <SummaryItem label={t('summary.paidOut')} value={log.paidOut || 0} duration />
                </Grid>

                {spacing && <Grid item xs={2} className={classes.hideOnPrint} />}

                <Grid item xs={4} sm={3} className={classes.print}>
                    <SummaryItem
                        label={t('summary.previousMonth')}
                        value={Math.abs(log.summary.outstanding)}
                        duration
                        positive={log.summary.outstanding < 0}
                        negative={log.summary.outstanding > 0}
                    />
                </Grid>

                <Grid item xs={4} sm={12} className={classes.print}>
                    <SummaryItem
                        label={t('summary.total')}
                        value={Math.abs(log.summary.total)}
                        duration
                        showEmpty
                        positive={log.summary.total < 0}
                        negative={log.summary.total > 0 && isPast}
                    />
                </Grid>

                <Grid item xs={12} className={classes.hideOnPrint}>
                    <Button onClick={handleClick}>{t('form.Log.edit')}</Button>
                </Grid>
            </Grid>
        </Container>
    );
}

LogSummary.propTypes = {
    log: LogPropType.isRequired,
    isPast: PropTypes.bool.isRequired,
};

export default LogSummary;
