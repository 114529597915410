import { CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { node } from 'prop-types';
import { useSelector } from 'react-redux';
import 'typeface-roboto';
import LoadingPage from '../../features/loader/components/LoadingPage';

import { selectIsInitialized } from '../../features/loader/selectors';

const useStyles = makeStyles({
    '@global': {
        'html, body, #root, .fullHeight': {
            height: '100%',
        },
    },
});

function Layout({ children }) {
    useStyles();
    const isInitialized = useSelector(selectIsInitialized);

    return (
        <>
            <CssBaseline />

            <LoadingPage isLoading={!isInitialized}>{children}</LoadingPage>
        </>
    );
}

Layout.propTypes = {
    children: node.isRequired,
};

export default Layout;
