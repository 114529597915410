import { useLocation } from 'react-router-dom';

export const useQuery = () => {
    const { search } = useLocation();

    try {
        return new URLSearchParams(search);
    } catch {
        return null;
    }
};
