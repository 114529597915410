import FormHelperText from '@material-ui/core/FormHelperText';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { useFieldError } from '../hooks';

function FieldError({ name }) {
    const [, meta] = useField(name);
    const { isError, translatedError } = useFieldError(meta);

    return isError ? <FormHelperText error>{translatedError}</FormHelperText> : null;
}

FieldError.propTypes = {
    name: PropTypes.string.isRequired,
};

export default FieldError;
