import * as PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { formatTimeDifference } from '../utils';

function Timer({ fromDate, pause }) {
    const [till, setTill] = useState(new Date());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTill(new Date());
        }, 1000);

        return () => clearTimeout(timer);
    }, [till]);

    if (!fromDate) {
        return null;
    }

    return formatTimeDifference(fromDate, till, pause);
}

Timer.propTypes = {
    fromDate: PropTypes.instanceOf(Date),
    pause: PropTypes.number,
};

Timer.defaultProps = {
    fromDate: null,
    pause: 0,
};

export default Timer;
