import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useCallbackRef = () => {
    const [current, setCurrent] = useState(null);
    const ref = useCallback(
        (node) => {
            setCurrent(node);
        },
        [setCurrent]
    );
    return [current, ref];
};

export const useDebounce = (value, delay = 500) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);

    return debouncedValue;
};

export const useScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
};
