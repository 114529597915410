import { Route, Switch } from 'react-router-dom';
import AppPage from '../pages/AppPage';
import Error404Page from '../pages/Error404Page';
import GDPRPage from '../pages/GDPRPage';
import ImprintPage from '../pages/ImprintPage';
import LoginPage from '../pages/LoginPage';
import ToSPage from '../pages/ToSPage';
import { gdprPath, imprintPath, loginPath, protectedPaths, registerPath, tosPath } from './paths';
import ProtectedRoutes from './ProtectedRoutes';

function Routes() {
    return (
        <Switch>
            <Route exact path={loginPath} component={LoginPage} />
            <Route path={`${loginPath}/:token`} component={LoginPage} />
            <Route exact path={registerPath} component={LoginPage} />
            <Route exact path={imprintPath} component={ImprintPage} />
            <Route exact path={tosPath} component={ToSPage} />
            <Route exact path={gdprPath} component={GDPRPage} />
            <ProtectedRoutes exact paths={protectedPaths} component={AppPage} />
            <Route component={Error404Page} />
        </Switch>
    );
}

export default Routes;
