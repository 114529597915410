import { useTheme } from '@material-ui/core';
import { TAGS } from '@workaholic/config/tags';
import PropTypes from 'prop-types';

import TagIcon from '../../../components/icons/TagIcon';
import ToggleButtons from './ToggleButtons';

function TagInput({ colorized, ...other }) {
    const theme = useTheme();

    return (
        <ToggleButtons
            options={TAGS.map((tag) => ({
                value: tag,
                label: <TagIcon type={tag} />,
                color: colorized ? theme.palette.tags[tag].main : null,
            }))}
            exclusive
            {...other}
        />
    );
}

TagInput.propTypes = {
    colorized: PropTypes.bool,
};

TagInput.defaultProps = {
    colorized: false,
};

export default TagInput;
