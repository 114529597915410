import { ADMIN_ROLE, USER_ROLE } from '@workaholic/config/users/roles';

export const loginPath = '/login';
export const registerPath = '/register';

export const logsPath = '/';
export const statisticsPath = '/statistics';
export const yearsPath = '/years';
export const settingsPath = '/settings';
export const usersPath = '/users';

export const imprintPath = '/imprint';
export const tosPath = '/tos';
export const gdprPath = '/gdpr';

export const playgroundPath = '/playground';

export const homePath = logsPath;

export const protectedPaths = {
    [logsPath]: [USER_ROLE],
    [yearsPath]: [USER_ROLE],
    [statisticsPath]: [USER_ROLE],
    [settingsPath]: [USER_ROLE],

    [usersPath]: [ADMIN_ROLE],
    [playgroundPath]: [ADMIN_ROLE],
};
