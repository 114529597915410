import { useField } from 'formik';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getFieldError, getTranslatedError } from './tools';

export const useFieldError = (meta) => {
    const { t } = useTranslation();
    const error = getFieldError(meta);
    return {
        isError: !!error,
        error,
        translatedError: getTranslatedError(error, t),
    };
};
/**
 * necessary until this gets fixed:
 * https://github.com/jaredpalmer/formik/issues/2268
 */
export const useFieldFast = (props) => {
    const [field, meta, helpers] = useField(props);

    const latestRef = useRef({});

    // On every render save newest helpers to latestRef
    latestRef.current.setValue = helpers.setValue;
    latestRef.current.setTouched = helpers.setTouched;
    latestRef.current.setError = helpers.setError;

    // On the first render create new function which will never change
    // but call newest helper function
    if (!latestRef.current.helpers) {
        latestRef.current.helpers = {
            setValue: (...args) => latestRef.current.setValue(...args),
            setTouched: (...args) => latestRef.current.setTouched(...args),
            setError: (...args) => latestRef.current.setError(...args),
        };
    }

    return [field, meta, latestRef.current.helpers];
};
