/* eslint-disable no-param-reassign */
import authentication from '@feathersjs/authentication-client';
import { parseError } from './utils';

export const attachAuthentication = (services) => {
    const { api } = services;

    api.configure(authentication());

    services.authenticate = (user) =>
        api.authenticate(user).catch((error) => {
            throw parseError(error);
        });

    services.logout = api.logout;
};
