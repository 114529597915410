import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../../../components/layout/Footer';

import AuthForm from '../../auth/components/AuthForm';
import SettingsForm from './SettingsForm';
import VersionDisplay from './VersionDisplay';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        overflow: 'auto',
        height: '100%',
    },

    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

function Settings() {
    const classes = useStyles();

    return (
        <Container maxWidth="md" className={classes.root} data-cy="Settings">
            <VersionDisplay />

            <Divider className={classes.divider} light />

            <AuthForm />

            <Divider className={classes.divider} light />

            <SettingsForm />

            <Footer />
        </Container>
    );
}

Settings.propTypes = {};

export default Settings;
