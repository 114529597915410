/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { loginSuccess } from '../auth/authSlice';

import { compareUsers } from './helpers';

const insertUser = (state, user) => {
    const stale = state.byId[user._id];

    if (!stale) {
        state.allIds.push(user._id);
    }

    state.byId[user._id] = user;

    state.allIds.sort((u1, u2) => compareUsers(state.byId[u1], state.byId[u2]));
};

const usersSlice = createSlice({
    name: 'users',
    initialState: {
        byId: {},
        allIds: [],
        isLoading: false,
        isInitialized: false,
    },
    reducers: {
        findPending: (state) => {
            state.isLoading = true;
        },
        findSuccess: (state, action) => {
            state.isLoading = false;
            state.isInitialized = true;

            action.payload.data.forEach((user) => {
                insertUser(state, user);
            });
        },
        createSuccess: (state, action) => {
            insertUser(state, action.payload);
        },
        patchSuccess: (state, action) => {
            insertUser(state, action.payload);
        },
        removeSuccess: (state, action) => {
            const index = state.allIds.findIndex((id) => id === action.payload._id);
            if (index) {
                delete state.byId[action.payload._id];
                state.allIds.splice(index, 1);
            }
        },
    },
    extraReducers: {
        [loginSuccess]: (state, action) => {
            insertUser(state, action.payload);
        },
    },
});

export const { findPending, findSuccess, createSuccess, patchSuccess, removeSuccess } =
    usersSlice.actions;

export default usersSlice.reducer;
