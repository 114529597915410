import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';

const ITEM_HEIGHT = 30;
const ITEMS_VISIBLE = 5;

const EXTRA_ITEMS_VISIBLE = (ITEMS_VISIBLE - 1) / 2;

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        // backgroundColor: 'red',
        height: ITEMS_VISIBLE * ITEM_HEIGHT,
        overflow: 'hidden',
        display: 'inline-block',
        textAlign: 'center',
        userSelect: 'none',
    },

    before: ({ gradient }) => ({
        width: '100%',
        height: EXTRA_ITEMS_VISIBLE * ITEM_HEIGHT,
        backgroundImage: `linear-gradient(to top, rgba(0,0,0,0), ${
            gradient || theme.palette.background.paper
        })`,
        position: 'absolute',
        top: 0,
        pointerEvents: 'none',
    }),

    after: ({ gradient }) => ({
        width: '100%',
        height: EXTRA_ITEMS_VISIBLE * ITEM_HEIGHT,
        backgroundImage: `linear-gradient(to bottom, rgba(0,0,0,0), ${
            gradient || theme.palette.background.paper
        })`,
        position: 'absolute',
        bottom: 0,
        pointerEvents: 'none',
    }),

    fullWidth: {
        width: '100%',
    },

    mask: {
        position: 'absolute',
        pointerEvents: 'none',
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        top: EXTRA_ITEMS_VISIBLE * ITEM_HEIGHT,
        height: ITEM_HEIGHT,
        left: theme.spacing(1),
        right: theme.spacing(1),
        marginLeft: 'auto',
        marginRight: 'auto',
    },

    selection: {
        marginTop: EXTRA_ITEMS_VISIBLE * ITEM_HEIGHT,
        height: ITEM_HEIGHT,
        lineHeight: `${ITEM_HEIGHT}px`, // line-height interprets numbers differently
        display: 'inline-block',
        width: '100%',
    },

    slider: {
        // backgroundColor: 'purple',
        display: 'inline-block',
        position: 'relative',
        paddingTop: EXTRA_ITEMS_VISIBLE * ITEM_HEIGHT,
        paddingBottom: EXTRA_ITEMS_VISIBLE * ITEM_HEIGHT,
        width: '100%',
    },

    list: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        width: '100%',
    },

    option: {
        height: ITEM_HEIGHT,
        width: '100%',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}));

const calculateMskSize = (size) => (size === 'small' ? { width: 34 } : null);

const getIndexPos = (options, value) => {
    const index = options.findIndex((option) => option.value === value);
    return (
        -((index === -1 ? Math.floor(options.length / 2) : index) + EXTRA_ITEMS_VISIBLE) *
        ITEM_HEIGHT
    );
};

function Picker({ value, options, onChange, fullWidth, gradient, maskSize }) {
    const classes = useStyles({ gradient });

    const [initial, setInitial] = useState(getIndexPos(options, value));
    const [{ pos }, setPos] = useSpring(() => ({ pos: initial }));

    useEffect(() => {
        const newPos = getIndexPos(options, value);
        setPos({ pos: newPos });
        setInitial(newPos);
    }, [value, options, setPos, setInitial]);

    const bind = useDrag(
        ({ down, movement: [, offset] }) => {
            if (down) {
                setPos({ pos: offset });
            } else {
                const index = Math.round(-offset / ITEM_HEIGHT);
                setPos({ pos: -index * ITEM_HEIGHT });
                onChange(null, options[index - EXTRA_ITEMS_VISIBLE].value);
            }
        },
        {
            initial: () => [0, pos.value],
            axis: 'y',
            bounds: {
                top: -ITEM_HEIGHT * (options.length + EXTRA_ITEMS_VISIBLE - 1),
                bottom: -ITEM_HEIGHT * EXTRA_ITEMS_VISIBLE,
            },
            rubberband: true,
        }
    );

    // Bind it to a component
    return (
        <Box
            className={classNames({
                [classes.root]: true,
                [classes.fullWidth]: fullWidth,
            })}
        >
            <Box className={classes.selection}>
                <animated.div
                    {...bind()}
                    className={classes.slider}
                    style={{
                        top: pos,
                    }}
                >
                    <ul className={classes.list}>
                        {options.map(({ label, value: val }) => (
                            <li key={val} className={classes.option}>
                                {label !== undefined ? label : val}
                            </li>
                        ))}
                    </ul>
                </animated.div>
            </Box>
            <Box className={classes.mask} style={calculateMskSize(maskSize)} />
            <Box className={classes.before} />
            <Box className={classes.after} />
        </Box>
    );
}

Picker.propTypes = {
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
            value: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string])
                .isRequired,
        })
    ).isRequired,
    onChange: PropTypes.func.isRequired,
    fullWidth: PropTypes.bool,
    gradient: PropTypes.string,
    maskSize: PropTypes.string,
};

Picker.defaultProps = {
    value: null,
    fullWidth: false,
    gradient: null,
    maskSize: 'auto',
};

export default Picker;
