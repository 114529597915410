import { useFormikContext } from 'formik';
import { func, instanceOf, shape } from 'prop-types';
import { useEffect } from 'react';

function Prefiller({ onFill, focus }) {
    const { setFieldValue } = useFormikContext();

    useEffect(() => {
        const downHandler = (event) => {
            if (event.ctrlKey && event.code === 'KeyA') {
                onFill(setFieldValue);
                if (focus) {
                    focus.current.focus();
                }
            }
        };

        // Add event listeners
        window.addEventListener('keydown', downHandler);

        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
        };
    }, [onFill, focus, setFieldValue]);

    return null;
}

function FormPrefiller(props) {
    return process.env.REACT_APP_ENV !== 'production' ? <Prefiller {...props} /> : null;
}

FormPrefiller.propTypes = {
    onFill: func.isRequired,
    focus: shape({ current: instanceOf(Element) }),
};

FormPrefiller.defaultProps = {
    focus: null,
};

export default FormPrefiller;
