import * as PropTypes from 'prop-types';
import { useMemo } from 'react';
import Calendar from '../../calendar/components/Calendar';
import LogRangeLoader from '../../logs/components/LogRangeLoader';
import { getPreviousMonths } from '../../logs/utils';
import { parseYear } from '../utils';

function Year({ year }) {
    const range = useMemo(() => getPreviousMonths(parseYear(year, 11), 12), [year]);

    return (
        <LogRangeLoader range={range}>
            {({ logs }) => <Calendar year={year} logs={logs} />}
        </LogRangeLoader>
    );
}

Year.propTypes = {
    year: PropTypes.string.isRequired,
};

export default Year;
