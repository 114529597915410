import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '../../../dialogs/components/Dialog';
import { useCallbackRef } from '../../../hooks';
import ForgotPasswordForm from './ForgotPasswordForm';

function ForgotPasswordDialog({ open, onClose, onExited }) {
    const { t } = useTranslation();
    const [current, ref] = useCallbackRef();

    return (
        <Dialog open={open} onClose={onClose} onExited={onExited}>
            <DialogTitle>{t('dialogs.ForgotPassword.title')}</DialogTitle>
            <DialogContent>
                <ForgotPasswordForm submitContainer={current} onDone={onClose} />
            </DialogContent>
            <DialogActions>
                <div ref={ref} />
            </DialogActions>
        </Dialog>
    );
}

ForgotPasswordDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
};

export default ForgotPasswordDialog;
