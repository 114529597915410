import { COUNTY_CODES } from '@workaholic/config/holidays/data';
import * as Yup from 'yup';

export const settingsSchema = Yup.object().shape({
    name: Yup.string(),
    workingHoursPerDay: Yup.number().min(0).required('Required'),
    initialOvertime: Yup.number().required('Required'),
    initialLeavePaid: Yup.number().required('Required'),
    showPayout: Yup.boolean().required('Required'),
    saturdayIsWeekend: Yup.boolean().required('Required'),
    logHeader: Yup.string().required('Required'),
    countyCode: Yup.string().oneOf(COUNTY_CODES),
    premiumFrom: Yup.date().nullable(),
    premiumTill: Yup.date().nullable(),
});

export const onboardingSettingsSchema = Yup.object().shape({
    workingHoursPerDay: Yup.number().min(0).required('Required'),
    initialOvertime: Yup.number().required('Required'),
    initialLeavePaid: Yup.number().required('Required'),
    showPayout: Yup.boolean().required('Required'),
    saturdayIsWeekend: Yup.boolean().required('Required'),
    countyCode: Yup.string().oneOf(COUNTY_CODES),
    premiumFrom: Yup.date().nullable(),
    premiumTill: Yup.date().nullable(),
});
