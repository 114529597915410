// eslint-disable-next-line import/no-import-module-exports
import { configureStore as RTConfigureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

// eslint-disable-next-line import/no-import-module-exports
import createReducers from './createReducers';

const middlewares = [];
if (process.env.NODE_ENV === 'development') {
    const { createLogger } = require('redux-logger'); // eslint-disable-line global-require, import/no-extraneous-dependencies
    middlewares.push(
        createLogger({
            collapsed: true,
        })
    );
}

const configureStore = (services, preloadedState) => {
    const rootReducer = createReducers();

    const store = RTConfigureStore({
        reducer: rootReducer,
        middleware: [
            ...getDefaultMiddleware({
                thunk: {
                    extraArgument: services,
                },
            }),
            ...middlewares,
        ],
        preloadedState,
    });

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('./createReducers', () => store.replaceReducer(rootReducer));
    }

    return store;
};

export default configureStore;
