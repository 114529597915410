import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import packageJson from '../../../package.json';
import { useDialog } from '../dialogs/components/DialogControl';
import { useQuery } from '../router/hooks';
import { selectSettings } from '../settings/selectors';

export const useWhatsNew = () => {
    const settings = useSelector(selectSettings);
    const [opened, setOpened] = useState(false);
    const { openWhatsNewDialog } = useDialog();
    const query = useQuery();

    const forceShow = query && query.get('whatsnew');

    useEffect(() => {
        if (
            !opened &&
            settings &&
            ((!settings.disableVersionHint &&
                (!settings.versionRead || settings.versionRead < packageJson.version)) ||
                forceShow)
        ) {
            openWhatsNewDialog(null, true);
            setOpened(true);
        }
    }, [settings, openWhatsNewDialog, forceShow, opened, setOpened]);
};
