import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';

function CrossFadeItem({ style, children, classes, className }) {
    return (
        <Box
            style={{ ...style }}
            className={`${style.opacity === 0 ? classes.exiting : classes.entering || ''} ${
                className || ''
            }`}
        >
            {children}
        </Box>
    );
}

CrossFadeItem.propTypes = {
    /* style is provided by Fade Component */
    style: PropTypes.shape({
        opacity: PropTypes.number.isRequired,
    }),
    children: PropTypes.node.isRequired,
    classes: PropTypes.shape({
        exiting: PropTypes.string,
        entering: PropTypes.string,
    }),
    className: PropTypes.string,
};

CrossFadeItem.defaultProps = {
    style: null,
    classes: {},
    className: null,
};

export default CrossFadeItem;
