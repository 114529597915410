import { getDaysInMonth, getISODay, parse, parseISO, setDay, format } from 'date-fns';

export const parseMonth = (month) => parse(month, 'yyyy-MM', setDay(new Date(), 1));

export const getMonthFromDate = (date) => format(date, 'yyyy-MM');

export const isHoliday = (holiday, countyCode) =>
    holiday && (!holiday.counties || holiday.counties.includes(countyCode));

export const getHolidayDays = (holidays = [], countyCode = null) =>
    holidays.filter(
        (holiday) =>
            isHoliday(holiday, countyCode) && ![6, 7].includes(getISODay(parseISO(holiday.date)))
    ).length;

export const getBusinessDays = (month, holidays, countyCode) => {
    const date = parseMonth(month);
    const days = getDaysInMonth(date);
    let businessDays = days - 8;

    const dayOfWeekStart = getISODay(date);
    const dayOfWeekEnd = dayOfWeekStart + days - 28 - 1;

    if (dayOfWeekStart <= 6 && dayOfWeekEnd >= 6) {
        businessDays -= 1; // Saturday within remaining days
    }
    if (dayOfWeekStart <= 7 && dayOfWeekEnd >= 7) {
        businessDays -= 1; // Sunday within remaining days
    }

    businessDays -= getHolidayDays(holidays, countyCode);

    return businessDays;
};
