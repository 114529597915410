import Box from '@material-ui/core/Box';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import CrossFadeItem from './CrossFadeItem';

const useStyles = makeStyles({
    exiting: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
    },
});

function CrossFade({ index, children, timeout, style, className, containerClassName }) {
    const classes = useStyles();

    return (
        <Box style={{ position: 'relative', ...style }} className={className}>
            <TransitionGroup component={null}>
                <Fade key={index} timeout={timeout}>
                    <CrossFadeItem
                        classes={{
                            exiting: classes.exiting,
                        }}
                        className={containerClassName}
                    >
                        {children}
                    </CrossFadeItem>
                </Fade>
            </TransitionGroup>
        </Box>
    );
}

CrossFade.propTypes = {
    index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    children: PropTypes.node.isRequired,
    timeout: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.shape({
            appear: PropTypes.number,
            enter: PropTypes.number,
            exit: PropTypes.number,
        }),
    ]),
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    style: PropTypes.shape({}),
};

CrossFade.defaultProps = {
    timeout: 400,
    className: null,
    containerClassName: null,
    style: null,
};

export default CrossFade;
