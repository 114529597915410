import Grid from '@material-ui/core/Grid';
import Portal from '@material-ui/core/Portal';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Form from '../../../form/components/Form';
import SubmitButton from '../../../form/components/SubmitButton';
import TextInput from '../../../form/components/TextInput';
import { IdPropType } from '../../../proptypes';
import { useDismissibleSnackbar } from '../../../snackbar/hooks';
import { patchUser } from '../../../users/actions';
import { selectUserById } from '../../../users/selectors';
import { changeEmailSchema } from '../../schema';

function ChangeEmailForm({ userId, submitContainer, onDone }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useDismissibleSnackbar();
    const user = useSelector((state) => selectUserById(state, userId));

    const handleSubmit = (values) =>
        dispatch(patchUser(userId, values)).then(() => {
            onDone();
            enqueueSnackbar(t('form.ChangeEmail.successful'));
        });

    return (
        <Form
            initialValues={{
                email: user.email || '',
            }}
            validationSchema={changeEmailSchema}
            onSubmit={handleSubmit}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextInput label={t('form.ChangeEmail.email')} name="email" fullWidth />
                </Grid>
            </Grid>

            <Portal container={submitContainer}>
                <SubmitButton outsideForm />
            </Portal>
        </Form>
    );
}

ChangeEmailForm.propTypes = {
    submitContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    onDone: PropTypes.func.isRequired,
    userId: IdPropType.isRequired,
};

ChangeEmailForm.defaultProps = {
    submitContainer: null,
};

export default ChangeEmailForm;
