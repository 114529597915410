import PropTypes from 'prop-types';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { DIALOG_MAPPING } from '../dialogs';

const DialogContext = createContext(null);

const capitalizeType = (type) =>
    type
        .split('_')
        .map((part) => `${part.charAt(0).toUpperCase()}${part.slice(1).toLowerCase()}`)
        .join('');

export const useDialog = () => useContext(DialogContext);

function DialogControl({ children }) {
    const [dialog, setDialog] = useState(null);
    const [dialogProps, setDialogProps] = useState(null);
    const [queue, setQueue] = useState([]);

    useEffect(() => {
        if (queue.length > 0 && !dialog) {
            const [next, ...remaining] = queue;
            setDialog(next.type);
            setDialogProps(next);
            setQueue(remaining);
        }
    }, [queue, dialog, setQueue, setDialog]);

    const control = useMemo(
        () =>
            Object.keys(DIALOG_MAPPING).reduce(
                (dialogs, type) => ({
                    ...dialogs,
                    [`open${capitalizeType(type)}`]: (props, enqueue = false) =>
                        dialogs.openDialog(type, props, enqueue),
                }),
                {
                    openDialog: (type, props, enqueue = false) => {
                        const next = { type, props };

                        if (enqueue) {
                            setQueue((prev) => [...prev, next]);
                        } else {
                            setQueue([next]);
                        }
                    },
                    closeDialog: () => setDialog(null),
                }
            ),
        [setDialog, setQueue]
    );

    const handleClose = useCallback(() => {
        setDialog(null);
    }, [setDialog]);

    const handleExited = (exited) => {
        setDialogProps((prev) => (prev.type === exited ? null : prev));
    };

    return (
        <DialogContext.Provider value={control}>
            {children}
            {Object.entries(DIALOG_MAPPING).map(
                ([type, Dialog]) =>
                    dialogProps &&
                    dialogProps.type === type && (
                        <Dialog
                            key={type}
                            data-cy={`Dialog.${type}`}
                            onClose={handleClose}
                            open={dialog === type}
                            onExited={() => handleExited(dialog)}
                            {...dialogProps.props}
                        />
                    )
            )}
        </DialogContext.Provider>
    );
}

DialogControl.propTypes = {
    children: PropTypes.node.isRequired,
};

export default DialogControl;
