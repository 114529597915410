import Box from '@material-ui/core/Box';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import NewReleasesSharpIcon from '@material-ui/icons/NewReleasesSharp';
import { useTranslation } from 'react-i18next';
import packageJson from '../../../../package.json';
import { useDialog } from '../../dialogs/components/DialogControl';
import { WHATS_NEW_DIALOG } from '../../dialogs/dialogs';

function VersionDisplay() {
    const { t } = useTranslation();
    const { openDialog } = useDialog();

    const handleClick = () => openDialog(WHATS_NEW_DIALOG);

    return (
        <Box data-cy="VersionDisplay">
            <Typography variant="caption" color="textSecondary">
                {t('meta.version')}
            </Typography>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs>
                    <Typography>{packageJson.version}</Typography>
                </Grid>
                <Grid item>
                    <Button
                        startIcon={<NewReleasesSharpIcon />}
                        onClick={handleClick}
                        size="small"
                        color="primary"
                        data-cy="VersionDisplay.whatsNew"
                    >
                        {t('meta.whatsNew')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

VersionDisplay.propTypes = {};

export default VersionDisplay;
