import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
    },

    icon: {
        position: 'absolute',
        top: 10,
        right: 10,
        opacity: 0.1,
    },

    header: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },

    copy: {
        textAlign: 'center',
    },

    secondary: {
        marginTop: theme.spacing(2),
    },

    content: {
        marginBottom: theme.spacing(3),
    },
}));

function OnboardingStep({ title, primary, secondary, icon, children, ...other }) {
    const classes = useStyles();

    return (
        <>
            {title && (
                <Grid item>
                    <Typography variant="h6" className={classes.header}>
                        {title}
                    </Typography>
                </Grid>
            )}
            <Grid
                container
                direction="column"
                justifyContent="space-evenly"
                alignContent="center"
                className={classes.root}
                {...other}
            >
                {icon && <Box className={classes.icon}>{icon}</Box>}

                {(primary || secondary) && (
                    <Grid item style={{ flexGrow: 1 }}>
                        <Grid container alignContent="center" className="fullHeight">
                            <Grid item xs className={classes.copy}>
                                {primary && <Typography>{primary}</Typography>}
                                {secondary && (
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        className={classes.secondary}
                                    >
                                        {secondary}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                <Grid item style={{ flexGrow: 2 }}>
                    {children &&
                        React.Children.map(children, (child) => (
                            <Box className={classes.content}>{child}</Box>
                        ))}
                </Grid>
            </Grid>
        </>
    );
}

OnboardingStep.propTypes = {
    title: PropTypes.string,
    primary: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    icon: PropTypes.node,
    children: PropTypes.node,
};

OnboardingStep.defaultProps = {
    title: null,
    primary: null,
    secondary: null,
    icon: null,
    children: null,
};

export default OnboardingStep;
