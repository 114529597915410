import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { getWorked } from '@workaholic/config/logs';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { formatTimeDuration } from '../../logentries/utils';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.grey[300],
        borderRadius: theme.shape.borderRadius,
    },

    title: {
        fontWeight: '600',
    },

    subtitle: {
        display: 'block',
        marginTop: -5,
    },

    body: {
        textAlign: 'center',
    },
}));

function CalendarTooltip({ date, data: { entry } }) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.root}>
            <Typography className={classes.title}>{format(date, 'dd.MM.yyyy')}</Typography>
            <Typography variant="caption" color="textSecondary" className={classes.subtitle}>
                {entry.tags.length > 0
                    ? entry.tags.map((tag) => t(`tags.${tag}`)).join(', ')
                    : t('summary.worked')}
            </Typography>
            <Typography className={classes.body}>{formatTimeDuration(getWorked(entry))}</Typography>
        </div>
    );
}

CalendarTooltip.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    data: PropTypes.shape({
        entry: PropTypes.shape({
            tags: PropTypes.arrayOf(PropTypes.string).isRequired,
        }).isRequired,
    }).isRequired,
};

export default CalendarTooltip;
