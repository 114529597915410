import { Box, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import Metadata from '../components/layout/Metadata';
import { useScrollToTop } from '../features/hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    },

    header: {
        marginBottom: theme.spacing(3),
    },

    title: {
        marginBottom: theme.spacing(1),
    },
}));

function CompliancePage({ type, version }) {
    const classes = useStyles();
    const { t } = useTranslation();
    useScrollToTop();

    const sections = t(`compliance.${type}.sections`, { returnObjects: true });

    return (
        <>
            <Metadata title={t(`nav.${type}`)} />

            <Header />

            <Container maxWidth="md" className={classes.root} data-cy="CompliancePage">
                <Box className={classes.header}>
                    <Typography variant="h2">{t(`compliance.${type}.title`)}</Typography>
                    {version && (
                        <Typography variant="subtitle1" color="textSecondary">
                            {version}
                        </Typography>
                    )}
                </Box>

                {sections.map(({ title, section }) => (
                    <Box mt={2} key={title}>
                        <Typography variant="h3" className={classes.title}>
                            {title}
                        </Typography>
                        <Typography color="textSecondary">{section}</Typography>
                    </Box>
                ))}
            </Container>

            <Footer />
        </>
    );
}

CompliancePage.propTypes = {
    type: PropTypes.string.isRequired,
    version: PropTypes.string,
};

CompliancePage.defaultProps = {
    version: null,
};

export default CompliancePage;
