import Grid from '@material-ui/core/Grid';
import Portal from '@material-ui/core/Portal';

import { getBusinessDays } from '@workaholic/config/holidays';
import * as PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DurationInput from '../../form/components/DurationInput';
import Form from '../../form/components/Form';
import SubmitButton from '../../form/components/SubmitButton';
import TextInput from '../../form/components/TextInput';
import { selectHolidayByMonth } from '../../holidays/selectors';
import { selectSettings } from '../../settings/selectors';
import { createLog, patchLog } from '../actions';
import { logSchema } from '../schema';
import { selectLogByMonth } from '../selectors';

function LogForm({ month, submitContainer, onDone }) {
    const { t } = useTranslation();
    const log = useSelector((state) => selectLogByMonth(state, month));
    const settings = useSelector(selectSettings);
    const holidays = useSelector((state) => selectHolidayByMonth(state, month));
    const dispatch = useDispatch();

    const initialValues = useMemo(
        () =>
            log && {
                workingHoursPerDay: log.workingHoursPerDay || settings.workingHoursPerDay,
                businessDays: log.ethereal
                    ? getBusinessDays(month, holidays, settings.countyCode)
                    : log.businessDays,
                paidOut: log.paidOut,
            },
        [log, holidays, month, settings.countyCode, settings.workingHoursPerDay]
    );

    return (
        <Form
            initialTouched={{
                workingHoursPerDay: true,
            }}
            initialValues={initialValues}
            validationSchema={logSchema}
            onSubmit={(values) => {
                const preppedValues = {
                    ...values,
                    month,
                };
                dispatch(
                    log._id ? patchLog(log._id, preppedValues) : createLog(preppedValues)
                ).then(onDone);
            }}
        >
            <Grid spacing={1} container>
                <Grid item xs={12} sm={settings.showPayout ? 4 : 6}>
                    <TextInput
                        label={t('form.Log.workingHoursPerDay')}
                        name="workingHoursPerDay"
                        fullWidth
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} sm={settings.showPayout ? 4 : 6}>
                    <TextInput label={t('form.Log.businessDays')} name="businessDays" fullWidth />
                </Grid>
                {settings.showPayout && (
                    <Grid item xs={12} sm={4}>
                        <DurationInput
                            label={t('form.Log.paidOut')}
                            name="paidOut"
                            fullWidth
                            minutesStep={5}
                        />
                    </Grid>
                )}
            </Grid>

            <Portal container={submitContainer}>
                <SubmitButton outsideForm ignoreDirty={log && log.summary.ethereal} />
            </Portal>
        </Form>
    );
}

LogForm.propTypes = {
    month: PropTypes.string,
    submitContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    onDone: PropTypes.func.isRequired,
};

LogForm.defaultProps = {
    month: null,
    submitContainer: null,
};

export default LogForm;
