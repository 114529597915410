import Grid from '@material-ui/core/Grid';
import Portal from '@material-ui/core/Portal';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Form from '../../form/components/Form';
import SubmitButton from '../../form/components/SubmitButton';
import TextInput from '../../form/components/TextInput';
import { createYear, patchYear } from '../actions';
import { yearSchema } from '../schema';
import { selectYearByYear } from '../selectors';

function YearForm({ year, submitContainer, onDone }) {
    const { t } = useTranslation();
    const yearData = useSelector((state) => selectYearByYear(state, year));
    const dispatch = useDispatch();

    return (
        <Form
            initialValues={{
                leaveDays: yearData.leaveDays || '',
            }}
            validationSchema={yearSchema}
            onSubmit={(values) => {
                const preppedValues = {
                    ...values,
                    year,
                };
                dispatch(
                    yearData._id
                        ? patchYear(yearData._id, preppedValues)
                        : createYear(preppedValues)
                ).then(onDone);
            }}
        >
            <Grid spacing={1} container>
                <Grid item xs={12}>
                    <TextInput
                        label={t('form.Year.leaveDays')}
                        name="leaveDays"
                        type="number"
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Portal container={submitContainer}>
                <SubmitButton outsideForm ignoreDirty={yearData.ethereal} />
            </Portal>
        </Form>
    );
}

YearForm.propTypes = {
    year: PropTypes.string.isRequired,
    submitContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    onDone: PropTypes.func.isRequired,
};

YearForm.defaultProps = {
    submitContainer: null,
};

export default YearForm;
