import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';
import Metadata from '../components/layout/Metadata';
import { selectUser } from '../features/auth/selectors';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(2),
    },

    header: {},

    message: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
}));

function UserInactivePage() {
    const classes = useStyles();
    const { t } = useTranslation();
    const user = useSelector(selectUser);

    return (
        <>
            <Metadata title={t('nav.inactive')} />

            <Header />

            <Container maxWidth="md" className={classes.root} data-cy="UserInactivePage">
                <Typography className={classes.message} component="h2" variant="h4">
                    {t('auth.inactive.header')}
                </Typography>
                <Typography className={classes.message} color="textSecondary">
                    {t('auth.inactive.subheader')}
                </Typography>
                <Typography className={classes.message} color="textSecondary">
                    {t('auth.inactive.action')}
                </Typography>
                <Typography color="textSecondary">{t('auth.loggedInAs')}</Typography>
                <Typography>{user.email}</Typography>
            </Container>

            <Footer />
        </>
    );
}

UserInactivePage.propTypes = {};

export default UserInactivePage;
