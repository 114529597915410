import {
    formatISO,
    parseISO,
    set,
    isBefore,
    isAfter,
    max,
    min,
    differenceInMinutes,
    startOfDay,
    endOfDay,
} from 'date-fns';

export const normalizeTime = (date) =>
    set(date, { year: 1970, month: 0, date: 1, seconds: 0, milliseconds: 0 });

export const parseTimeNormalized = (date) => normalizeTime(parseISO(date));

export const normalizeTimeString = (date) => formatISO(parseTimeNormalized(date));

export const getOverlappingDuration = (from, till, timeFrameFrom, timeFrameTill) => {
    const normalized = {
        from: normalizeTime(from),
        till: normalizeTime(till),
        timeFrameFrom: normalizeTime(timeFrameFrom),
        timeFrameTill: normalizeTime(timeFrameTill),
    };

    const timeFrames = isBefore(normalized.timeFrameTill, normalized.timeFrameFrom)
        ? [
              /* time frame spans midnight */
              {
                  from: startOfDay(normalized.timeFrameTill),
                  till: normalized.timeFrameTill,
              },
              {
                  from: normalized.timeFrameFrom,
                  till: endOfDay(normalized.timeFrameFrom),
              },
          ]
        : [
              {
                  from: normalized.timeFrameFrom,
                  till: normalized.timeFrameTill,
              },
          ];

    return timeFrames.reduce(
        (overlap, timeFrame) =>
            !isBefore(normalized.till, timeFrame.from) && !isAfter(normalized.from, timeFrame.till)
                ? overlap +
                  differenceInMinutes(
                      min([normalized.till, timeFrame.till]),
                      max([normalized.from, timeFrame.from])
                  )
                : overlap,
        0
    );
};
