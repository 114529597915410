import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grow from '@material-ui/core/Grow';
import PropTypes from 'prop-types';
import LoginForm from './LoginForm';

function Login({ register }) {
    const theme = useTheme();

    return (
        <Grow in timeout={theme.transitions.duration.enteringScreen}>
            <Box data-cy="Login">
                <LoginForm register={register} />
            </Box>
        </Grow>
    );
}

Login.propTypes = {
    register: PropTypes.bool,
};

Login.defaultProps = {
    register: false,
};

export default Login;
