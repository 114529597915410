import { useMediaQuery, useTheme } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Dialog from '../../dialogs/components/Dialog';
import ConfirmationButton from '../../form/components/ConfirmationButton';
import { useCallbackRef } from '../../hooks';
import { IdPropType } from '../../proptypes';
import { removeUser } from '../actions';
import UserForm from './UserForm';

function UserDialog({ open, onClose, onExited, userId }) {
    const { t } = useTranslation();
    const [current, ref] = useCallbackRef();
    const theme = useTheme();
    const fullWidth = useMediaQuery(theme.breakpoints.down('xs'));
    const dispatch = useDispatch();

    const handleDelete = () => dispatch(removeUser(userId)).then(onClose);

    return (
        <Dialog open={open} onClose={onClose} onExited={onExited} fullWidth={fullWidth}>
            <DialogTitle>{t('dialogs.User.title')}</DialogTitle>
            <DialogContent>
                <UserForm userId={userId} onDone={onClose} submitContainer={current} />
            </DialogContent>
            <DialogActions>
                <ConfirmationButton label={t('dialogs.delete')} onConfirm={handleDelete}>
                    <div ref={ref} />
                </ConfirmationButton>
            </DialogActions>
        </Dialog>
    );
}

UserDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
    userId: IdPropType,
};

UserDialog.defaultProps = {
    userId: null,
};

export default UserDialog;
