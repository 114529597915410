import Grid from '@material-ui/core/Grid';
import PostAddIcon from '@material-ui/icons/PostAdd';
import { useTranslation } from 'react-i18next';
import TimeInput from '../../../form/components/TimeInput';
import OnboardingStep from './OnboardingStep';

function PremiumStep() {
    const { t } = useTranslation();

    return (
        <OnboardingStep
            icon={<PostAddIcon style={{ fontSize: 100 }} />}
            title={t('dialogs.Onboarding.steps.Premium.title')}
            primary={t('dialogs.Onboarding.steps.Premium.copy')}
            data-cy="PremiumStep"
        >
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TimeInput
                        label={t('dialogs.Onboarding.steps.Premium.from')}
                        name="premiumFrom"
                        minutesStep={5}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TimeInput
                        label={t('dialogs.Onboarding.steps.Premium.till')}
                        name="premiumTill"
                        minutesStep={5}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </OnboardingStep>
    );
}

PremiumStep.propTypes = {};

export default PremiumStep;
