import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '../../../dialogs/components/Dialog';
import { useCallbackRef } from '../../../hooks';
import { IdPropType } from '../../../proptypes';
import ChangeEmailForm from './ChangeEmailForm';

function ChangeEmailDialog({ open, onClose, onExited, userId }) {
    const { t } = useTranslation();
    const [current, ref] = useCallbackRef();

    return (
        <Dialog open={open} onClose={onClose} onExited={onExited} data-cy="ChangeEmailDialog">
            <DialogTitle>{t('dialogs.ChangeEmail.title')}</DialogTitle>
            <DialogContent>
                {userId && (
                    <ChangeEmailForm userId={userId} submitContainer={current} onDone={onClose} />
                )}
            </DialogContent>
            <DialogActions>
                <div ref={ref} />
            </DialogActions>
        </Dialog>
    );
}

ChangeEmailDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
    userId: IdPropType,
};

ChangeEmailDialog.defaultProps = {
    userId: null,
};

export default ChangeEmailDialog;
