import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import { formatISO, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../form/components/Checkbox';
import DurationInput from '../../form/components/DurationInput';
import Form from '../../form/components/Form';
import Select from '../../form/components/Select';
import CountySelect from '../../form/components/specialized/CountySelect';
import SubmitButton from '../../form/components/SubmitButton';
import TextInput from '../../form/components/TextInput';
import TimeInput from '../../form/components/TimeInput';
import { useDismissibleSnackbar } from '../../snackbar/hooks';
import { patchSettings } from '../actions';
import { settingsSchema } from '../schema';
import { selectSettings } from '../selectors';

const useStyles = makeStyles({
    submit: {
        textAlign: 'right',
    },
});

function SettingsForm() {
    const classes = useStyles();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useDismissibleSnackbar();
    const settings = useSelector(selectSettings);
    const dispatch = useDispatch();

    const initialValues = {
        logHeader: 'remaining',
        initialOvertime: 0,
        name: '',

        ...settings,

        premiumFrom: settings.premiumFrom ? parseISO(settings.premiumFrom) : null,
        premiumTill: settings.premiumTill ? parseISO(settings.premiumTill) : null,
    };

    const handleSubmit = (values) => {
        const prepped = {
            ...values,
            premiumFrom: values.premiumFrom ? formatISO(values.premiumFrom) : null,
            premiumTill: values.premiumTill ? formatISO(values.premiumTill) : null,
        };

        return dispatch(patchSettings(settings._id, prepped)).then(() => {
            enqueueSnackbar(t('form.Settings.successful'));
        });
    };

    return (
        <Form
            initialValues={initialValues}
            enableReinitialize
            validationSchema={settingsSchema}
            onSubmit={handleSubmit}
        >
            <Grid spacing={1} container>
                <Grid item xs={12}>
                    <TextInput label={t('form.Settings.name')} name="name" fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextInput
                        label={t('form.Settings.workingHoursPerDay')}
                        name="workingHoursPerDay"
                        type="number"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <DurationInput
                        label={t('form.Settings.initialOvertime')}
                        name="initialOvertime"
                        fullWidth
                        minutesStep={5}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextInput
                        label={t('form.Settings.initialLeavePaid')}
                        name="initialLeavePaid"
                        type="number"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Checkbox label={t('form.Settings.showPayout')} name="showPayout" />
                </Grid>
                <Grid item xs={12}>
                    <Checkbox
                        label={t('form.Settings.saturdayIsWeekend')}
                        name="saturdayIsWeekend"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TimeInput
                        name="premiumFrom"
                        label={t('form.Settings.premiumFrom')}
                        fullWidth
                        minutesStep={5}
                        clearable
                    />
                </Grid>
                <Grid item xs={6}>
                    <TimeInput
                        name="premiumTill"
                        label={t('form.Settings.premiumTill')}
                        fullWidth
                        minutesStep={5}
                        clearable
                    />
                </Grid>
                <Grid item xs={12}>
                    <CountySelect
                        label={t('form.Settings.countyCode')}
                        name="countyCode"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Select
                        label={t('form.Settings.logHeader.label')}
                        choices={[
                            { label: t('form.Settings.logHeader.worked'), value: 'worked' },
                            { label: t('form.Settings.logHeader.remaining'), value: 'remaining' },
                            { label: t('form.Settings.logHeader.scheduled'), value: 'scheduled' },
                            { label: t('form.Settings.logHeader.total'), value: 'total' },
                        ]}
                        name="logHeader"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} className={classes.submit}>
                    <SubmitButton variant="outlined" />
                </Grid>
            </Grid>
        </Form>
    );
}

SettingsForm.propTypes = {};

export default SettingsForm;
