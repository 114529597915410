export const COUNTY_CODES = [
    'DE-BW',
    'DE-BY',
    'DE-BE',
    'DE-BB',
    'DE-HB',
    'DE-HH',
    'DE-HE',
    'DE-MV',
    'DE-NI',
    'DE-NW',
    'DE-RP',
    'DE-SL',
    'DE-SN',
    'DE-ST',
    'DE-SH',
    'DE-TH',
];
