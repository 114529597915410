import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { compareTags } from '@workaholic/config/tags';
import { format } from 'date-fns';
import * as PropTypes from 'prop-types';

import TagIcon from './TagIcon';

const iconSize = 16;

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
    },

    header: {
        color: theme.palette.primary.main,
        fontSize: '1.7em',
        marginBottom: -8,
        paddingBottom: 0,

        '@media print': {
            fontSize: '1.1em',
        },
    },

    subheader: {
        marginTop: 0,
        paddingTop: 0,
        fontSize: '0.9em',
        letterSpacing: 2,
        position: 'relative',
        left: 1,

        '@media print': {
            fontSize: '0.8em',
        },
    },

    tags: {
        marginLeft: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(3),
        position: 'relative',
        top: 6,
        width: iconSize,
    },

    tag: {
        lineHeight: 0,
    },

    icon: {
        fontSize: iconSize,
    },
}));

function DateIcon({ date, tags }) {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item>
                <Box className={classes.root}>
                    <Typography className={classes.header}>{format(date, 'dd')}</Typography>
                    <Typography className={classes.subheader}>{format(date, 'EEEEEE')}</Typography>
                </Box>
            </Grid>
            <Grid item>
                <Grid container direction="column" className={classes.tags}>
                    {tags &&
                        [...tags].sort(compareTags).map((tag) => (
                            <Grid item key={tag}>
                                <Typography className={classes.tag} color="textSecondary">
                                    <TagIcon type={tag} className={classes.icon} />
                                </Typography>
                            </Grid>
                        ))}
                </Grid>
            </Grid>
        </Grid>
    );
}

DateIcon.propTypes = {
    date: PropTypes.objectOf(Date).isRequired,
    tags: PropTypes.arrayOf(PropTypes.string),
};

DateIcon.defaultProps = {
    tags: null,
};

export default DateIcon;
