import * as PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingContainer from '../../loader/components/LoadingContainer';
import LogEntries from '../../logentries/components/LogEntries';
import { getLog } from '../actions';

import { selectLogByMonth, selectMonthLoading } from '../selectors';

function Log({ month }) {
    const log = useSelector((state) => selectLogByMonth(state, month));
    const isLoading = useSelector((state) => selectMonthLoading(state, month));
    const dispatch = useDispatch();

    useEffect(() => {
        if (!log && !isLoading) {
            dispatch(getLog(month));
        }
    }, [dispatch, log, isLoading, month]);

    return (
        <LoadingContainer loading={!log}>
            {log && <LogEntries ids={log.entries} />}
        </LoadingContainer>
    );
}

Log.propTypes = {
    month: PropTypes.string.isRequired,
};

export default Log;
