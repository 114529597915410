import { GDPR_PROPERTY, TOS_PROPERTY } from '@workaholic/config/users/compliance';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../auth/selectors';
import { useDialog } from '../dialogs/components/DialogControl';
import { useQuery } from '../router/hooks';

export const useCompliance = () => {
    const user = useSelector(selectUser);
    const [opened, setOpened] = useState(false);
    const { openComplianceDialog } = useDialog();
    const query = useQuery();

    const forceShow = query && query.get('compliance');

    useEffect(() => {
        if (!opened && user && (!user[TOS_PROPERTY] || !user[GDPR_PROPERTY] || forceShow)) {
            openComplianceDialog(null, true);
            setOpened(true);
        }
    }, [user, openComplianceDialog, forceShow, opened, setOpened]);
};
