import Box from '@material-ui/core/Box';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import classNames from 'classnames';
import { useField } from 'formik';
import * as PropTypes from 'prop-types';

import { useFieldError } from '../hooks';

const useStyles = makeStyles((theme) => ({
    iconButton: {
        color: theme.palette.text.hint,
        padding: theme.spacing(1),
    },

    selected: {
        color: theme.palette.primary.main,
    },
}));

function ToggleButtons({ name, options, exclusive, required, size, onChange, variant }) {
    const classes = useStyles();
    const [field, meta, helpers] = useField(name);
    const { isError, translatedError } = useFieldError(meta);

    const handleChange = (event, newValue) => {
        helpers.setTouched(true);

        if (!required || newValue) {
            let preppedValue = newValue;
            if (exclusive) {
                preppedValue = newValue.length ? [newValue] : [];
            }
            helpers.setValue(preppedValue);
            if (onChange) {
                onChange(name, preppedValue);
            }
        }
    };

    return (
        <Box>
            <Box>
                {variant === 'outlined' ? (
                    <ToggleButtonGroup
                        {...field}
                        value={field.value}
                        onChange={handleChange}
                        onBlur={() => {
                            // catch blur event
                        }}
                        exclusive={exclusive}
                        size={size}
                    >
                        {options.map(({ label, value }) => (
                            <ToggleButton key={value} value={value}>
                                {label}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                ) : (
                    <>
                        {options.map((option) => {
                            const selected = field.value && field.value.includes(option.value);

                            return (
                                <IconButton
                                    key={option.value}
                                    size="medium"
                                    style={{
                                        color: selected && option.color ? option.color : null,
                                    }}
                                    className={classNames({
                                        // [classes.iconButton]: true,
                                        [classes.selected]: !option.color && selected,
                                    })}
                                    classes={{
                                        root: classes.iconButton,
                                    }}
                                    onClick={(event) => {
                                        let newValue;
                                        if (field.value && field.value.includes(option.value)) {
                                            newValue = field.value.filter(
                                                (value) => value !== option.value
                                            );
                                        } else if (exclusive) {
                                            newValue = option.value;
                                        } else {
                                            newValue = [...field.value, option.value];
                                        }
                                        handleChange(event, newValue);
                                    }}
                                >
                                    {option.label}
                                </IconButton>
                            );
                        })}
                    </>
                )}
            </Box>
            {isError && (
                <Box>
                    <FormHelperText error>{translatedError}</FormHelperText>
                </Box>
            )}
        </Box>
    );
}

ToggleButtons.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            color: PropTypes.string,
        })
    ).isRequired,
    onChange: PropTypes.func,
    exclusive: PropTypes.bool,
    required: PropTypes.bool,
    size: PropTypes.string,
    variant: PropTypes.string,
};

ToggleButtons.defaultProps = {
    exclusive: false,
    required: false,
    size: 'small',
    onChange: null,
    variant: 'text',
};

export default ToggleButtons;
