import Tooltip from '@material-ui/core/Tooltip';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import CakeIcon from '@material-ui/icons/Cake';
import HealingIcon from '@material-ui/icons/Healing';
import SchoolIcon from '@material-ui/icons/School';
import WeekendIcon from '@material-ui/icons/Weekend';
import { EDUCATION, HOLIDAY, SICK, VACATION, WEEKEND } from '@workaholic/config/tags';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const icons = {
    [WEEKEND]: WeekendIcon,
    [VACATION]: BeachAccessIcon,
    [HOLIDAY]: CakeIcon,
    [EDUCATION]: SchoolIcon,
    [SICK]: HealingIcon,
};

function TagIcon({ type, size, ...other }) {
    const { t } = useTranslation();
    const Icon = icons[type];

    return (
        <Tooltip title={t(`tags.${type}`)}>
            <Icon size={size} {...other} />
        </Tooltip>
    );
}

TagIcon.propTypes = {
    type: PropTypes.string.isRequired,
    size: PropTypes.string,
};

TagIcon.defaultProps = {
    size: 'small',
};

export default TagIcon;
