import { combineReducers } from 'redux';

import auth, { logoutSuccess } from '../auth/authSlice';
import holidays from '../holidays/holidaysSlice';
import loader from '../loader/loadingSlice';
import logentries from '../logentries/logentriesSlice';
import logs from '../logs/logsSlice';
import settings from '../settings/settingsSlice';
import users from '../users/usersSlice';
import years from '../years/yearsSlice';

const createReducers = () => {
    const appReducer = combineReducers({
        auth,
        holidays,
        loader,
        logs,
        logentries,
        settings,
        users,
        years,
    });

    return (state, action) =>
        appReducer(action.type === logoutSuccess.type ? undefined : state, action);
};

export default createReducers;
