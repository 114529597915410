import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Dialog from '../../../dialogs/components/Dialog';
import { useCallbackRef } from '../../../hooks';
import SetPasswordForm from './SetPasswordForm';

function SetPasswordDialog({ resetPasswordToken, open, onClose, onExited }) {
    const { t } = useTranslation();
    const [current, ref] = useCallbackRef();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onExited={onExited}
            fullWidth
            disableBackdropClick
            disableEscapeKeyDown
        >
            <DialogTitle>{t('dialogs.SetPassword.title')}</DialogTitle>
            <DialogContent>
                <SetPasswordForm
                    submitContainer={current}
                    onDone={onClose}
                    resetPasswordToken={resetPasswordToken}
                />
            </DialogContent>
            <DialogActions>
                <div ref={ref} />
            </DialogActions>
        </Dialog>
    );
}

SetPasswordDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
    resetPasswordToken: PropTypes.string,
};

SetPasswordDialog.defaultProps = {
    resetPasswordToken: null,
};

export default SetPasswordDialog;
