import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { format } from 'date-fns';
import * as PropTypes from 'prop-types';

const useStyles = makeStyles({
    hours: {
        fontSize: '1.2em',
    },

    minutes: {
        fontSize: '0.7em',
        position: 'relative',
        top: -4,
    },
});

function TimeIcon({ time }) {
    const classes = useStyles();

    return (
        <Typography component="span" className={classes.hours}>
            {format(time, 'HH')}
            <Typography component="span" className={classes.minutes}>
                {format(time, 'mm')}
            </Typography>
        </Typography>
    );
}

TimeIcon.propTypes = {
    time: PropTypes.objectOf(Date).isRequired,
};

export default TimeIcon;
