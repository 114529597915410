export const WEEKEND = 'weekend';

export const VACATION = 'vacation';

export const HOLIDAY = 'holiday';

export const SICK = 'sick';

export const EDUCATION = 'education';

export const PREMIUM = 'premium';

export const TAGS = [WEEKEND, VACATION, HOLIDAY, SICK, EDUCATION];

export const TAG_PRIORITIES = [SICK, VACATION, EDUCATION, HOLIDAY, WEEKEND];

export const TAG_DURATION_ONLY = [SICK, VACATION];

export const compareTags = (t1, t2) => TAGS.indexOf(t1) - TAGS.indexOf(t2);

export const onlyDurationNeeded = selected =>
    selected && TAG_DURATION_ONLY.reduce((carry, tag) => carry || selected.includes(tag), false);
