import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { gdprPath, imprintPath, tosPath } from '../../routes/paths';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },

    link: {
        color: theme.palette.text.hint,
    },
}));

function Footer() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <footer className={classes.root}>
            <Grid container spacing={2} alignItems="center" justifyContent="center">
                <Grid item>
                    <Typography variant="body2" className={classes.link}>
                        <Link
                            component={RouterLink}
                            to={imprintPath}
                            color="inherit"
                            underline="none"
                        >
                            {t('nav.imprint')}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" className={classes.link}>
                        <Link component={RouterLink} to={tosPath} color="inherit" underline="none">
                            {t('nav.tos')}
                        </Link>
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" className={classes.link}>
                        <Link component={RouterLink} to={gdprPath} color="inherit" underline="none">
                            {t('nav.gdpr')}
                        </Link>
                    </Typography>
                </Grid>
            </Grid>
        </footer>
    );
}

export default Footer;
