import { useMediaQuery, useTheme } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch, useSelector } from 'react-redux';
import Dialog from '../../dialogs/components/Dialog';
import { findHolidaysForMonth } from '../../holidays/actions';
import { selectHolidayByMonth } from '../../holidays/selectors';
import { useCallbackRef } from '../../hooks';
import LoadingContainer from '../../loader/components/LoadingContainer';
import LogForm from './LogForm';

function LogDialog({ open, onClose, onExited, month }) {
    const { t } = useTranslation();
    const [current, ref] = useCallbackRef();
    const theme = useTheme();
    const fullWidth = useMediaQuery(theme.breakpoints.down('xs'));
    const dispatch = useDispatch();
    const holidays = useSelector((state) => selectHolidayByMonth(state, month));

    useEffect(() => {
        if (!holidays) {
            dispatch(findHolidaysForMonth(month));
        }
    }, [month, holidays, dispatch]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            onExited={onExited}
            fullWidth={fullWidth}
            data-cy="LogDialog"
        >
            <DialogTitle>{t('dialogs.Log.title')}</DialogTitle>
            <DialogContent>
                <LoadingContainer loading={!holidays}>
                    <LogForm submitContainer={current} month={month} onDone={onClose} />
                </LoadingContainer>
            </DialogContent>
            <DialogActions>
                <div ref={ref} />
            </DialogActions>
        </Dialog>
    );
}

LogDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onExited: PropTypes.func.isRequired,
    month: PropTypes.string,
};

LogDialog.defaultProps = {
    month: null,
};

export default LogDialog;
