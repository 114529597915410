/*
 * extra action creators
 */

const findYears = (query) => (dispatch, getState, services) => services.years.find({ query });

export const getYear = (year) => findYears({ year });

export const createYear = (year) => (dispatch, getState, services) => services.years.create(year);

export const patchYear = (id, year) => (dispatch, getState, services) =>
    services.years.patch(id, year);
