import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Required'),
    password: Yup.string().required('Required'),
});

export const changeEmailSchema = Yup.object().shape({
    email: Yup.string().required('Required'),
});

export const changePasswordSchema = Yup.object().shape({
    old: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
});

export const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Required'),
});

export const setPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Required'),
});

export const registerSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email').required('Required'),
    password: Yup.string().required('Required'),
    tos: Yup.boolean().required('Required').oneOf([true], 'Required'),
    gdpr: Yup.boolean().required('Required').oneOf([true], 'Required'),
});

export const complianceSchema = Yup.object().shape({
    tos: Yup.boolean().required('Required').oneOf([true], 'Required'),
    gdpr: Yup.boolean().required('Required').oneOf([true], 'Required'),
});
