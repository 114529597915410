import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingContainer from '../../loader/components/LoadingContainer';
import { findUsers } from '../actions';
import { selectAllUserIds, selectUsersIsInitialized, selectUsersIsLoading } from '../selectors';
import UserCard from './UserCard';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        overflow: 'auto',
    },
}));

function Users() {
    const classes = useStyles();
    const userIds = useSelector(selectAllUserIds);
    const isLoading = useSelector(selectUsersIsLoading);
    const isInizialized = useSelector(selectUsersIsInitialized);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!isInizialized && !isLoading) {
            dispatch(findUsers());
        }
    }, [dispatch, isLoading, isInizialized]);

    return (
        <Box className={classes.root} data-cy="Users">
            <LoadingContainer loading={isLoading}>
                <Grid container spacing={2} className={classes.root}>
                    {userIds.map((id) => (
                        <Grid item key={id} xs={12} sm={6} md={4}>
                            <UserCard userId={id} />
                        </Grid>
                    ))}
                </Grid>
            </LoadingContainer>
        </Box>
    );
}

Users.propTypes = {};

export default Users;
