import { Card, CardActions, CardContent, useMediaQuery, useTheme } from '@material-ui/core';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { loginPath, registerPath } from '../../../routes/paths';
import { useDialog } from '../../dialogs/components/DialogControl';
import Form from '../../form/components/Form';
import FormPrefiller from '../../form/components/FormPrefiller';
import SubmitButton from '../../form/components/SubmitButton';
import ErrorMessage from '../../loader/components/ErrorMessage';
import { selectLoadingError } from '../../loader/selectors';
import CrossFade from '../../transitions/CrossFade';
import { login, register as doRegister } from '../actions';
import { loginSchema, registerSchema } from '../schema';
import LoginFormFields from './LoginFormFields';

const REGISTER_ACTION = 'register';
const LOGIN_ACTION = 'login';

const LOADING_KEY = 'LoginForm';

function LoginForm({ register }) {
    const submitButton = useRef(null);
    const { t } = useTranslation();
    const { openForgotPasswordDialog } = useDialog();
    const error = useSelector((state) => selectLoadingError(state, LOADING_KEY));
    const dispatch = useDispatch();
    const [action, setAction] = useState(register ? REGISTER_ACTION : LOGIN_ACTION);
    const theme = useTheme();
    const extraSpace = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        setAction(register ? REGISTER_ACTION : LOGIN_ACTION);
    }, [register]);

    const handleSubmit = (values) =>
        dispatch(
            action === REGISTER_ACTION
                ? doRegister(values, LOADING_KEY)
                : login({ ...values, strategy: 'local' }, LOADING_KEY)
        );

    return (
        <Form
            initialValues={{
                email: '',
                password: '',
                tos: false,
                gdpr: false,
            }}
            validationSchema={action === REGISTER_ACTION ? registerSchema : loginSchema}
            onSubmit={handleSubmit}
        >
            <FormPrefiller
                onFill={(setFieldValue) => {
                    setFieldValue('email', process.env.REACT_APP_MOCK_USERNAME);
                    setFieldValue('password', process.env.REACT_APP_MOCK_PASSWORD);
                }}
                focus={submitButton}
            />
            <Grid container justifyContent="center" alignItems="center">
                <Grid item sm={8}>
                    <Card>
                        <CardContent>
                            <LoginFormFields register={action === REGISTER_ACTION} />
                            <Box mt={1}>
                                <ErrorMessage error={error} />
                            </Box>
                        </CardContent>
                        <CardActions>
                            <Grid
                                container
                                direction="row-reverse"
                                justifyContent="space-between"
                                spacing={1}
                                alignItems="center"
                            >
                                <Grid item>
                                    <SubmitButton
                                        data-cy="login"
                                        innerRef={submitButton}
                                        label={
                                            action === REGISTER_ACTION
                                                ? t('form.Login.register')
                                                : t('form.Login.login')
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </CardActions>
                    </Card>
                    <Box mt={1}>
                        <CrossFade index={action}>
                            {action === LOGIN_ACTION && (
                                <Grid
                                    container
                                    spacing={extraSpace ? 3 : 1}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Link component={RouterLink} to={registerPath}>
                                            {t('form.Login.nav.register')}
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <Link
                                            component="button"
                                            variant="body2"
                                            type="button"
                                            onClick={() => openForgotPasswordDialog()}
                                            // somehow necessary to horizontally align the two links
                                            style={{ height: 21 }}
                                        >
                                            {t('auth.password.forgotten')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            )}
                            {action === REGISTER_ACTION && (
                                <Grid
                                    container
                                    spacing={1}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Link component={RouterLink} to={loginPath}>
                                            {t('form.Login.nav.login')}
                                        </Link>
                                    </Grid>
                                </Grid>
                            )}
                        </CrossFade>
                    </Box>
                </Grid>
            </Grid>
        </Form>
    );
}

LoginForm.propTypes = {
    register: PropTypes.bool,
};

LoginForm.defaultProps = {
    register: false,
};

export default LoginForm;
