import { TAGS } from '@workaholic/config/tags';
import PropTypes from 'prop-types';
import { IdPropType } from '../proptypes';

export const SummaryPropType = PropTypes.shape({
    ethereal: PropTypes.bool,
    outstanding: PropTypes.number.isRequired,
    pause: PropTypes.number.isRequired,
    normal: PropTypes.number.isRequired,
    ...TAGS.reduce((carry, tag) => {
        // eslint-disable-next-line no-param-reassign
        carry[tag] = PropTypes.number.isRequired;
        return carry;
    }, {}),
});

export const LogPropType = PropTypes.shape({
    month: PropTypes.string.isRequired,
    entries: PropTypes.arrayOf(IdPropType).isRequired,
    summary: SummaryPropType.isRequired,
    businessDays: PropTypes.number.isRequired,
    workingHoursPerDay: PropTypes.number,
    paidOut: PropTypes.number,
});
