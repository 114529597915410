import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loginPath } from '../../../routes/paths';
import { logout } from '../actions';

function LogoutButton({ className, onLogout, icon }) {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const handleLogout = useCallback(
        () =>
            dispatch(logout()).then(() => {
                history.push(loginPath);
                if (onLogout) {
                    onLogout();
                }
            }),
        [dispatch, history, onLogout]
    );

    return icon ? (
        <IconButton
            aria-label="Logout"
            data-cy="logout"
            color="inherit"
            onClick={handleLogout}
            className={className}
        >
            <LogoutIcon fontSize="small" />
        </IconButton>
    ) : (
        <Button
            data-cy="logout"
            color="primary"
            size="small"
            startIcon={<LogoutIcon />}
            onClick={handleLogout}
            className={className}
        >
            {t('auth.logout')}
        </Button>
    );
}

LogoutButton.propTypes = {
    className: PropTypes.string,
    onLogout: PropTypes.func,
    icon: PropTypes.bool,
};

LogoutButton.defaultProps = {
    className: null,
    onLogout: null,
    icon: false,
};

export default LogoutButton;
