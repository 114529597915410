import { Checkbox as MuiCheckbox, FormHelperText } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useField } from 'formik';
import * as PropTypes from 'prop-types';
import { useFieldError } from '../hooks';

function Checkbox({ name, label, color }) {
    const [field, meta, helper] = useField(name);
    const { isError, translatedError } = useFieldError(meta);

    return (
        <FormControl error={isError}>
            <FormControlLabel
                control={
                    <MuiCheckbox
                        checked={field.value}
                        onChange={(event, newValue) => {
                            helper.setValue(newValue);
                        }}
                        name={name}
                        color={color}
                    />
                }
                label={label}
            />
            {isError && <FormHelperText>{translatedError}</FormHelperText>}
        </FormControl>
    );
}

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    color: PropTypes.string,
};

Checkbox.defaultProps = {
    color: 'primary',
};

export default Checkbox;
