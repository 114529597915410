/*
 * extra action creators
 */

import { getEmptyLog } from '@workaholic/config/logs';
import { getPending, getSuccess } from './logsSlice';

const findLogs = (query) => (dispatch, getState, services) => services.logs.find({ query });

export const getLog = (month) => async (dispatch) => {
    await dispatch(getPending(month));
    const response = await dispatch(findLogs({ month }));

    if (response.payload.total === 0) {
        return dispatch(getSuccess(getEmptyLog(month)));
    }

    return response;
};

export const createLog = (log) => (dispatch, getState, services) => services.logs.create(log);

export const patchLog = (id, log) => (dispatch, getState, services) => services.logs.patch(id, log);
