import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../loader/components/LoadingButton';

const useStyles = makeStyles((theme) => ({
    delete: {
        position: 'absolute',
        left: theme.spacing(1),
    },

    deleteLabel: {
        position: 'absolute',
        left: theme.spacing(2),
    },
}));

function ConfirmationButton({
    children,
    label,
    confirmInfo,
    confirmLabel,
    abortLabel,
    onConfirm,
    onAbort,
}) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        setConfirm(false);
    }, [setConfirm]);

    const handleConfirm = () => {
        onConfirm();
    };

    const handleAbort = () => {
        setConfirm(false);
        if (onAbort) {
            onAbort();
        }
    };

    return confirm ? (
        <>
            <Typography className={classes.deleteLabel}>
                {confirmInfo || t('dialogs.confirmation.label')}
            </Typography>
            <Button onClick={handleAbort} size="small" data-cy="ConfirmationButton.abort">
                {abortLabel || t('dialogs.confirmation.abort')}
            </Button>
            <LoadingButton
                danger
                onClick={handleConfirm}
                size="small"
                data-cy="ConfirmationButton.confirm"
            >
                {confirmLabel || t('dialogs.confirmation.confirm')}
            </LoadingButton>
        </>
    ) : (
        <>
            <LoadingButton
                className={classes.delete}
                onClick={() => setConfirm(true)}
                size="small"
                data-cy="ConfirmationButton.initiate"
            >
                {label}
            </LoadingButton>
            {children}
        </>
    );
}

ConfirmationButton.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string.isRequired,
    confirmInfo: PropTypes.string,
    confirmLabel: PropTypes.string,
    abortLabel: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onAbort: PropTypes.func,
};

ConfirmationButton.defaultProps = {
    children: null,
    confirmInfo: null,
    confirmLabel: null,
    abortLabel: null,
    onAbort: null,
};

export default ConfirmationButton;
