import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { useLocation } from 'react-router-dom';
import Footer from '../components/layout/Footer';
import Header from '../components/layout/Header';

import Metadata from '../components/layout/Metadata';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: 'center',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));

function Error404Page() {
    const classes = useStyles();
    const { pathname } = useLocation();

    return (
        <>
            <Metadata title="404" />

            <Header />

            <Container maxWidth="md" className={classes.root}>
                <ErrorIcon fontSize="large" />
                <h3>
                    <code>{pathname} not found :&apos;(</code>
                </h3>
            </Container>

            <Footer />
        </>
    );
}

export default Error404Page;
