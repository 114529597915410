/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getEmptyYear } from '@workaholic/config/years';

const yearsSlice = createSlice({
    name: 'years',
    initialState: {
        byYear: {},
        allYears: [],
        isLoading: false,
        yearsLoading: {},
    },
    reducers: {
        findPending: (state) => {
            state.isLoading = true;
        },
        findSuccess: (state, action) => {
            state.isLoading = false;

            /* handle special case where find is used to get single year */
            // TODO: handle case for year range
            const [params] = action.meta.params;
            if (
                action.payload.total === 0 &&
                params.query &&
                params.query.year &&
                typeof params.query.year === 'string'
            ) {
                action.payload.data = [getEmptyYear(params.query.year)];
            }

            action.payload.data.forEach((year) => {
                if (!state.byYear[year.year]) {
                    state.allYears.push(year.year);
                }
                state.byYear[year.year] = year;
                state.yearsLoading[year.year] = false;
            });
        },

        getPending: (state, action) => {
            state.yearsLoading[action.meta.params.year] = true;
        },

        getSuccess: (state, action) => {
            if (!state.byYear[action.payload.year]) {
                state.allYears.push(action.payload.year);
            }
            state.byYear[action.payload.year] = action.payload;
            state.yearsLoading[action.payload.year] = false;
        },

        createSuccess: (state, action) => {
            state.byYear[action.payload.year] = action.payload;
            state.allYears.push(action.payload.year);
        },

        patchSuccess: (state, action) => {
            state.byYear[action.payload.year] = action.payload;
        },

        removeSuccess: (state, action) => {
            const index = state.allYears.findIndex((id) => id === action.payload.year);
            if (index) {
                delete state.byYear[action.payload.year];
                delete state.yearsLoading[action.payload.year];
                state.allYears.splice(index, 1);
            }
        },
    },
});

export const { findSuccess, createSuccess, patchSuccess } = yearsSlice.actions;

export default yearsSlice.reducer;
