import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import deLocale from 'date-fns/locale/de';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import configureServices from '../features/api/configureServices';
import DialogControl from '../features/dialogs/components/DialogControl';
import FabControl from '../features/fabs/components/FabControl';
import configureStore from '../features/store/configureStore';
import Routes from '../routes/Routes';

import '../styles/App.css';
import theme from '../styles/theme';
import AppInitializer from './AppInitializer';
import Layout from './layout/Layout';
import SnackbarProvider from './SnackbarProvider';

const services = configureServices();
const store = configureStore(services);
services.setDispatch(store.dispatch);

function App() {
    return (
        <HelmetProvider>
            <Provider store={store}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                    <ThemeProvider theme={theme}>
                        <SnackbarProvider>
                            <AppInitializer>
                                <Router>
                                    <DialogControl>
                                        <FabControl>
                                            <Layout>
                                                <Routes />
                                            </Layout>
                                        </FabControl>
                                    </DialogControl>
                                </Router>
                            </AppInitializer>
                        </SnackbarProvider>
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
            </Provider>
        </HelmetProvider>
    );
}

export default App;
