import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import LogRangeLoader from '../../logs/components/LogRangeLoader';
import { getPreviousMonths } from '../../logs/utils';
import Overtime from './overtime/Overtime';
import StatisticsCard from './StatisticsCard';
import Workdays from './workdays/Workdays';

const useStyles = makeStyles((theme) => ({
    root: {
        overflowX: 'hidden',
        height: '100%',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        width: '100%',
    },
}));

function Statistics() {
    const classes = useStyles();
    const { t } = useTranslation();

    const range = useMemo(() => getPreviousMonths(new Date(), 6), []);

    return (
        <Box className={classes.root} data-cy="Statistics">
            <LogRangeLoader range={range}>
                {({ logs }) => (
                    <Grid container spacing={2} className="fullHeight">
                        <StatisticsCard label={t('statistics.overtime.title')}>
                            <Overtime logs={logs} />
                        </StatisticsCard>

                        <StatisticsCard label={t('statistics.workdays.title')}>
                            <Workdays logs={logs} />
                        </StatisticsCard>
                    </Grid>
                )}
            </LogRangeLoader>
        </Box>
    );
}

Statistics.propTypes = {};

export default Statistics;
