export const findHolidaysForMonth = (month) => (dispatch, getState, services) =>
    services.holidays.find(
        {
            query: {
                date: {
                    $gte: `${month}-01`,
                    $lte: `${month}-32`,
                },
            },
        },
        month
    );
