import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingContainer from '../../loader/components/LoadingContainer';
import { getLog } from '../actions';
import { selectLogsByMonth, selectMonthsLoading } from '../selectors';

function LogRangeLoader({ range, children }) {
    const [initialized, setInitialized] = useState(false);
    const [progress, setProgress] = useState(0);
    const [logs, setLogs] = useState([]);
    const monthsLoading = useSelector(selectMonthsLoading);
    const months = useSelector(selectLogsByMonth);
    const dispatch = useDispatch();

    useEffect(() => {
        setInitialized(false);
    }, [range]);

    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
            setLogs([]);
            range.forEach((month) => {
                if (!months[month] && !monthsLoading[month]) {
                    dispatch(getLog(month));
                }
            });
        }
    }, [range, months, monthsLoading, dispatch, initialized]);

    useEffect(() => {
        const _logs = range.map((month) => months[month]);
        const loaded = _logs.filter((month) => month);
        if (_logs.length === loaded.length) {
            setLogs(_logs);
        }
        setProgress((loaded.length * 100) / range.length);
    }, [
        range,
        months,
        initialized, // check logs after reinitialization
    ]);

    return (
        <LoadingContainer loading={progress < 100} progress={progress}>
            {logs.length > 0 ? children({ logs }) : <Box />}
        </LoadingContainer>
    );
}

LogRangeLoader.propTypes = {
    range: PropTypes.arrayOf(PropTypes.string).isRequired,
    children: PropTypes.func.isRequired,
};

export default LogRangeLoader;
