import { COUNTY_CODES } from '@workaholic/config/holidays/data';
import * as PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Select from '../Select';

function CountySelect({ name, label, displayEmpty, emptyLabel, autoWidth, fullWidth, onChange }) {
    const { t } = useTranslation();
    const choices = useMemo(
        () =>
            COUNTY_CODES.map((countyCode) => ({
                value: countyCode,
                label: t(`counties.${countyCode}`),
            })),
        [t]
    );
    return (
        <Select
            name={name}
            label={label}
            choices={choices}
            displayEmpty={displayEmpty}
            emptyLabel={emptyLabel}
            autoWidth={autoWidth}
            fullWidth={fullWidth}
            onChange={onChange}
        />
    );
}

CountySelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    displayEmpty: PropTypes.bool,
    emptyLabel: PropTypes.string,
    autoWidth: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
};

CountySelect.defaultProps = {
    label: null,
    displayEmpty: false,
    emptyLabel: null,
    autoWidth: false,
    fullWidth: false,
    onChange: null,
};

export default CountySelect;
