import feathers from '@feathersjs/feathers';
import featherssocketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';

export const configureSocket = (protocol, domain, port) => {
    const socket = io(`${protocol}://${domain}:${port}`, {
        transports: ['websocket'],
        forceNew: true,
    });

    // TODO: remove socket event listener when api data is stable
    if (process.env.NODE_ENV === 'development') {
        let wasConnected = false;
        if (socket) {
            socket
                .on('connect', () => {
                    if (wasConnected) {
                        window.location.reload();
                    }
                })
                .on('disconnect', () => {
                    wasConnected = true;
                });
        }
    }
    // ------------- end remove -------------

    return feathers().configure(featherssocketio(socket));
};
