import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MuiTextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { useField } from 'formik';
import * as PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatTimeDuration } from '../../logentries/utils';
import { useFieldError } from '../hooks';

import Picker from './Picker';

const getOptions = (count, from = 0, step = 1) =>
    Array(count)
        .fill(null)
        .map((_, index) => ({
            label: (index + from) * step,
            value: (index + from) * step,
        }));

const useStyles = makeStyles((theme) => ({
    input: {
        margin: 0,
    },

    dialog: {
        width: '100%',
        margin: 4,
        maxWidth: 310,
        userSelect: 'none',
    },

    title: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        textAlign: 'center',
    },

    content: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },

    container: {
        textAlign: 'center',
    },

    picker: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

const hoursMinutesToDuration = (hours, minutes) =>
    (Math.sign(hours) || 1) * (Math.abs(hours) * 60 + minutes);

const durationToHours = (duration) =>
    (Math.sign(duration) || 1) * Math.trunc(Math.abs(duration) / 60);
const durationToMinutes = (duration) => Math.abs(duration) % 60;

function DurationInput({ label, name, fullWidth, minutesStep, positive }) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [field, meta, helpers] = useField(name);
    const { isError, translatedError } = useFieldError(meta);
    const [hours, setHours] = useState(durationToHours(field.value));
    const [minutes, setMinutes] = useState(durationToMinutes(field.value));

    useEffect(() => {
        setHours(durationToHours(field.value));
        setMinutes(durationToMinutes(field.value));
    }, [field.value, setHours, setMinutes]);

    const handleClose = () => setOpen(false);

    const [hourOptions, minOptions] = useMemo(
        () => [
            positive ? getOptions(100) : getOptions(199, -99),
            getOptions(60 / minutesStep, 0, minutesStep).map((option) => ({
                ...option,
                label: option.value < 10 ? `0${option.value}` : option.value,
            })),
        ],
        [minutesStep, positive]
    );

    return (
        <>
            <MuiTextField
                onClick={() => {
                    setOpen(true);
                }}
                value={formatTimeDuration(field.value)}
                label={label}
                margin="dense"
                className={classes.input}
                error={isError}
                helperText={translatedError}
                fullWidth={fullWidth}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                classes={{
                    paper: classes.dialog,
                }}
            >
                <DialogTitle className={classes.title}>
                    <Typography variant="h2" component="span">
                        {formatTimeDuration(hoursMinutesToDuration(hours, minutes))}
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    <Grid container>
                        <Grid item xs={6} className={classes.container}>
                            <Typography variant="caption" color="textSecondary">
                                {t('date.HoursLabel')}
                            </Typography>
                            <Box className={classes.picker}>
                                <Picker
                                    value={hours}
                                    options={hourOptions}
                                    onChange={(_, newValue) => {
                                        setHours(newValue);
                                    }}
                                    maskSize="small"
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} className={classes.container}>
                            <Typography variant="caption" color="textSecondary">
                                {t('date.MinutesLabel')}
                            </Typography>
                            <Box className={classes.picker}>
                                <Picker
                                    value={minutes}
                                    options={minOptions}
                                    onChange={(_, newValue) => {
                                        setMinutes(newValue);
                                    }}
                                    maskSize="small"
                                    fullWidth
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={handleClose}>
                        {t('dialogs.abort')}
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            helpers.setValue(hoursMinutesToDuration(hours, minutes));
                            handleClose();
                        }}
                    >
                        {t('dialogs.accept')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

DurationInput.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
    minutesStep: PropTypes.number,
    positive: PropTypes.bool,
};

DurationInput.defaultProps = {
    label: null,
    fullWidth: false,
    minutesStep: 1,
    positive: false,
};

export default DurationInput;
