import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import { useTranslation } from 'react-i18next';
import CountySelect from '../../../form/components/specialized/CountySelect';
import TextInput from '../../../form/components/TextInput';
import OnboardingStep from './OnboardingStep';

function VacationStep() {
    const { t } = useTranslation();

    return (
        <OnboardingStep
            icon={<BeachAccessIcon style={{ fontSize: 100 }} />}
            title={t('dialogs.Onboarding.steps.Vacation.title')}
            primary={t('dialogs.Onboarding.steps.Vacation.copy')}
            data-cy="VacationStep"
        >
            <TextInput label={t('form.Year.leaveDays')} name="leaveDays" type="number" fullWidth />

            <TextInput
                label={t('dialogs.Onboarding.steps.Vacation.leftOver')}
                name="initialLeavePaid"
                type="number"
                fullWidth
            />

            <CountySelect
                label={t('dialogs.Onboarding.steps.Vacation.county')}
                name="countyCode"
                fullWidth
            />
        </OnboardingStep>
    );
}

VacationStep.propTypes = {};

export default VacationStep;
