import { Form as FormikForm, Formik } from 'formik';
import PropTypes from 'prop-types';

function Form({ children, className, onSubmit, ...other }) {
    const handleSubmit = (values, context) =>
        Promise.resolve(onSubmit(values, context)).catch((error) => {
            const { setSubmitting, setErrors } = context;
            setSubmitting(false);

            if (error.code === 400 && error.errors) {
                setErrors(error.errors);
            } else {
                throw error;
            }
        });

    return (
        <Formik onSubmit={handleSubmit} {...other}>
            <FormikForm className={className}>{children}</FormikForm>
        </Formik>
    );
}

Form.propTypes = {
    children: PropTypes.node.isRequired,
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    className: PropTypes.string,
};

Form.defaultProps = {
    initialValues: {},
    onSubmit: () => null,
    className: null,
};

export default Form;
