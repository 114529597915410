/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { format, parseISO } from 'date-fns';

const holidaysSlice = createSlice({
    name: 'holidays',
    initialState: {
        byDate: {},
        byMonth: {},
    },
    reducers: {
        findSuccess: (state, action) => {
            const month = action.meta.params[1];

            state.byMonth[month] = [];

            action.payload.data.forEach((holiday) => {
                state.byDate[format(parseISO(holiday.date), 'yyyy-MM-dd')] = holiday;
                state.byMonth[month].push(holiday.date);
            });
        },
    },
});

export const { findSuccess } = holidaysSlice.actions;

export default holidaysSlice.reducer;
