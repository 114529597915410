import Portal from '@material-ui/core/Portal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { complianceSchema } from '../../auth/schema';
import { selectUser } from '../../auth/selectors';
import Form from '../../form/components/Form';
import SubmitButton from '../../form/components/SubmitButton';
import { patchUser } from '../../users/actions';
import ComplianceFormFields from './ComplianceFormFields';

function ComplianceForm({ onDone, submitContainer }) {
    const { t } = useTranslation();
    const user = useSelector(selectUser);
    const dispatch = useDispatch();

    const handleSubmit = (values, { setSubmitting }) =>
        dispatch(patchUser(user._id, values)).then((response) => {
            setSubmitting(false);
            if (onDone) {
                onDone(response);
            }
            return response;
        });

    return (
        <Form
            initialValues={{ tos: false, gdpr: false }}
            validationSchema={complianceSchema}
            onSubmit={handleSubmit}
        >
            <ComplianceFormFields />
            <Portal container={submitContainer}>
                <SubmitButton label={t('form.Compliance.submit')} outsideForm />
            </Portal>
        </Form>
    );
}

ComplianceForm.propTypes = {
    onDone: PropTypes.func,
    submitContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
};

ComplianceForm.defaultProps = {
    onDone: null,
    submitContainer: null,
};

export default ComplianceForm;
