import {
    HOLIDAYS_SERVICE_ID,
    LOG_ENTRIES_SERVICE_ID,
    LOGS_SERVICE_ID,
    PASSWORDS_SERVICE_ID,
    SETTINGS_SERVICE_ID,
    USERS_SERVICE_ID,
    YEARS_SERVICE_ID,
} from '@workaholic/config/services';
import { checkRemovedUser } from '../auth/actions';

const endpoints = [
    {
        name: 'passwords',
        endpoint: PASSWORDS_SERVICE_ID,
    },
    {
        name: 'logentries',
        endpoint: LOG_ENTRIES_SERVICE_ID,
    },
    {
        name: 'logs',
        endpoint: LOGS_SERVICE_ID,
    },
    {
        name: 'settings',
        endpoint: SETTINGS_SERVICE_ID,
    },
    {
        name: 'users',
        endpoint: USERS_SERVICE_ID,
        listeners: {
            removed: (services, user) => services.dispatch(checkRemovedUser(user)),
        },
    },
    {
        name: 'years',
        endpoint: YEARS_SERVICE_ID,
    },
    {
        name: 'holidays',
        endpoint: HOLIDAYS_SERVICE_ID,
    },
];

export default endpoints;
