import UpdateIcon from '@material-ui/icons/Update';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../../form/components/Checkbox';
import TextInput from '../../../form/components/TextInput';
import OnboardingStep from './OnboardingStep';

function WorkdayStep() {
    const { t } = useTranslation();

    return (
        <OnboardingStep
            icon={<UpdateIcon style={{ fontSize: 100 }} />}
            title={t('dialogs.Onboarding.steps.Workday.title')}
            primary={t('dialogs.Onboarding.steps.Workday.copy')}
            data-cy="WorkdayStep"
        >
            <TextInput
                label={t('form.Settings.workingHoursPerDay')}
                name="workingHoursPerDay"
                type="number"
                fullWidth
            />

            <Checkbox label={t('form.Settings.saturdayIsWeekend')} name="saturdayIsWeekend" />
        </OnboardingStep>
    );
}

WorkdayStep.propTypes = {};

export default WorkdayStep;
