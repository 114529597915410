/* eslint-disable react/jsx-props-no-spreading */
import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
} from '@material-ui/core';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useFieldError } from '../hooks';

function Select({
    name,
    label,
    choices,
    displayEmpty,
    emptyLabel,
    autoWidth,
    fullWidth,
    onChange,
    ...other
}) {
    const { t } = useTranslation();
    const [field, meta, helpers] = useField(name);
    const { isError, translatedError } = useFieldError(meta);

    return (
        <FormControl fullWidth={fullWidth}>
            {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
            <MuiSelect
                {...field}
                name={name}
                value={field.value || ''}
                onChange={(event) => {
                    const newValue = event.target.value;
                    helpers.setValue(newValue);

                    if (onChange) {
                        onChange(name, newValue);
                    }
                }}
                error={isError}
                displayEmpty={displayEmpty}
                autoWidth={autoWidth}
                {...other}
            >
                {displayEmpty && (
                    <MenuItem value="">
                        <em>{emptyLabel || t('form.Select.emptyLabel')}</em>
                    </MenuItem>
                )}
                {choices.map(({ label: choiceLabel, value }) => (
                    <MenuItem value={value} key={value}>
                        {choiceLabel}
                    </MenuItem>
                ))}
            </MuiSelect>
            {isError && <FormHelperText>{translatedError}</FormHelperText>}
        </FormControl>
    );
}

Select.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    choices: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        })
    ).isRequired,
    displayEmpty: PropTypes.bool,
    emptyLabel: PropTypes.string,
    autoWidth: PropTypes.bool,
    fullWidth: PropTypes.bool,
    onChange: PropTypes.func,
};

Select.defaultProps = {
    label: null,
    displayEmpty: false,
    emptyLabel: null,
    autoWidth: false,
    fullWidth: false,
    onChange: null,
};

export default Select;
