import { addMonths, format, subMonths } from 'date-fns';

export const compareByDate = (log1, log2) => log1.from.localeCompare(log2.from);

export const getPreviousMonth = (date, sub = 1) => format(subMonths(date, sub), 'yyyy-MM');

export const getNextMonth = (date, add = 1, asDate = false) => {
    const next = addMonths(date, add);
    return asDate ? next : format(next, 'yyyy-MM');
};

export const getPreviousMonths = (date, count) =>
    Array(count)
        .fill(null)
        .map((_, index) => getPreviousMonth(date, count - index - 1));
