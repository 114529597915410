/* eslint-disable react/jsx-props-no-spreading */
import { makeStyles } from '@material-ui/core';
import MuiTextField from '@material-ui/core/TextField';
import { useField } from 'formik';
import * as PropTypes from 'prop-types';
import { useFieldError } from '../hooks';

const useStyles = makeStyles({
    input: {
        margin: 0,
    },
});

function TextInput({ name, label, onChange, ...other }) {
    const classes = useStyles();
    const [field, meta, helpers] = useField(name);
    const { isError, translatedError } = useFieldError(meta);

    return (
        <MuiTextField
            {...field}
            label={label}
            margin="dense"
            onChange={(event) => {
                const newValue = event.target.value;
                helpers.setValue(newValue);

                if (onChange) {
                    onChange(name, newValue);
                }
            }}
            className={classes.input}
            error={isError}
            helperText={translatedError}
            {...other}
        />
    );
}

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
};

TextInput.defaultProps = {
    label: null,
    onChange: null,
};

export default TextInput;
