import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    header: {
        paddingBottom: 0,
    },
});

function StatisticsCard({ label, children }) {
    const classes = useStyles();

    return (
        <Grid item xs={12}>
            <Container>
                <Paper>
                    <CardHeader title={label} className={classes.header} />
                    <CardContent>{children}</CardContent>
                </Paper>
            </Container>
        </Grid>
    );
}

StatisticsCard.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default StatisticsCard;
