import { useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ResponsiveCalendar } from '@nivo/calendar';
import { PREMIUM, TAGS } from '@workaholic/config/tags';
import { format, parseISO } from 'date-fns';
import * as PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Form from '../../form/components/Form';
import TagInput from '../../form/components/TagInput';
import { selectLogEntriesByIds } from '../../logentries/selectors';

import { LogPropType } from '../../logs/proptypes';
import CalendarTooltip from './CalendarTooltip';

const useStyles = makeStyles((theme) => ({
    form: {
        textAlign: 'center',
        marginBottom: theme.spacing(1),
    },

    calendar: {
        height: 1800,
    },
}));

function Calendar({ year, logs }) {
    const classes = useStyles();
    const theme = useTheme();
    const logEntries = useSelector(selectLogEntriesByIds);
    const [tags, setTags] = useState(TAGS);

    const tagColors = useMemo(() => {
        const colors = Object.values(theme.palette.tags);
        const keys = Object.keys(theme.palette.tags);
        return {
            getValue: (tag) => {
                const value = keys.findIndex((key) => key === tag);
                return value < 0 ? keys.length : value;
            },
            colors: colors.map((color) => color.light),
        };
    }, [theme]);

    const data = useMemo(
        () =>
            logs.reduce((carry, log) => {
                log.entries.forEach((id) => {
                    const entry = logEntries[id];

                    if (!entry.till) {
                        // ignore entries that are currently running and have not ended yet
                        return;
                    }

                    const match =
                        entry.tags.reduce(
                            (_match, tag) => _match || (tags.includes(tag) && tag),
                            null
                        ) ||
                        (entry.premium > 0 && PREMIUM); // TODO: implement different texture for this
                    carry.push({
                        day: format(parseISO(entry.from), 'yyyy-MM-dd'),
                        value: tagColors.getValue(match),
                        entry,
                    });
                });
                return carry;
            }, []),
        [logs, logEntries, tags, tagColors]
    );

    return (
        <>
            <Form initialValues={{ tags: TAGS }}>
                <Box className={classes.form}>
                    <TagInput
                        exclusive={false}
                        name="tags"
                        onChange={(event, newTags) => {
                            setTags(newTags);
                        }}
                        colorized
                    />
                </Box>
            </Form>
            <Box className={classes.calendar} data-cy="Calendar">
                <ResponsiveCalendar
                    data={data}
                    from={`${year}-01-01`}
                    to={`${year}-12-31`}
                    direction="vertical"
                    minValue={0}
                    maxValue={tagColors.colors.length}
                    colors={tagColors.colors}
                    emptyColor="#eeeeee"
                    monthBorderColor="#fafafa"
                    monthBorderWidth={6}
                    dayBorderWidth={2}
                    dayBorderColor="#fafafa"
                    tooltip={CalendarTooltip}
                />
            </Box>
        </>
    );
}

Calendar.propTypes = {
    year: PropTypes.string.isRequired,
    logs: PropTypes.arrayOf(LogPropType).isRequired,
};

export default Calendar;
