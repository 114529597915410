import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SettingsIcon from '@material-ui/icons/Settings';
import { ADMIN_ROLE } from '@workaholic/config/users/roles';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import LogoutButton from '../../features/auth/components/LogoutButton';
import { selectUser } from '../../features/auth/selectors';
import { selectSettings } from '../../features/settings/selectors';
import { homePath, settingsPath, usersPath } from '../../routes/paths';

const useStyles = makeStyles({
    root: {
        '@media print': {
            boxShadow: 'none',
        },
    },
});

function Header() {
    const classes = useStyles();
    const history = useHistory();
    const user = useSelector(selectUser);
    const settings = useSelector(selectSettings);

    return (
        <AppBar position="relative" className={classes.root}>
            <Toolbar>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs>
                        <Typography variant="h5" color="inherit">
                            <Link
                                component={RouterLink}
                                to={homePath}
                                color="inherit"
                                underline="none"
                            >
                                {settings?.name || 'Workaholic'}
                            </Link>
                        </Typography>
                    </Grid>
                    {user &&
                        (user.active ? (
                            <>
                                {user.roles.includes(ADMIN_ROLE) && (
                                    <Grid item align="center">
                                        <IconButton
                                            aria-label="Users"
                                            color="inherit"
                                            onClick={() => history.push(usersPath)}
                                        >
                                            <PeopleAltIcon fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                )}
                                <Grid item align="center">
                                    <IconButton
                                        aria-label="Logout"
                                        color="inherit"
                                        onClick={() => history.push(settingsPath)}
                                    >
                                        <SettingsIcon fontSize="small" />
                                    </IconButton>
                                </Grid>
                            </>
                        ) : (
                            <Grid item align="center">
                                <LogoutButton icon />
                            </Grid>
                        ))}
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default Header;
