import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useMemo, useState } from 'react';
import SummaryStaticHeader from '../../../components/summary/SummaryStaticHeader';
import CrossFade from '../../transitions/CrossFade';
import { getNextYear } from '../utils';
import Year from './Year';
import YearSummary from './YearSummary';

const useStyles = makeStyles((theme) => ({
    summary: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        textAlign: 'center',
    },

    content: {
        overflow: 'auto',
    },
}));

function Years() {
    const classes = useStyles();
    const initialDate = useMemo(() => new Date(), []);
    const [index, setIndex] = useState(0);
    const [headerIndex, setHeaderIndex] = useState(0);

    const year = getNextYear(initialDate, headerIndex);

    return (
        <Grid container direction="column" className="fullHeight" data-cy="Years">
            <Grid item>
                <CrossFade index={headerIndex}>
                    <SummaryStaticHeader
                        title={year}
                        onNext={() => {
                            setIndex(index + 1);
                            setHeaderIndex(index + 1);
                        }}
                        onPrevious={() => {
                            setIndex(index - 1);
                            setHeaderIndex(index - 1);
                        }}
                    />
                </CrossFade>
            </Grid>
            <Grid item xs className={classes.content}>
                <Box className={classes.summary}>
                    <YearSummary year={year} />
                </Box>
                <Year year={getNextYear(initialDate, headerIndex)} />
            </Grid>
        </Grid>
    );
}

export default Years;
